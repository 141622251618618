import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TagManager from 'react-gtm-module'

const GoogleTagManagerView = ({ gtmId }) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId,
      dataLayerName: 'dataLayer',
    }

    TagManager.initialize(tagManagerArgs)
  }, [])

  return null
}

GoogleTagManagerView.propTypes = {
  gtmId: PropTypes.string.isRequired,
}

export default GoogleTagManagerView
