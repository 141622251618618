import { put, select, all } from 'redux-saga/effects'
import { stopSubmit, reset } from 'redux-form'
import { push } from 'react-router-redux'
import notify from 'sagas/notify'
import { saveTokens, removeTokens, bootstrapAuthFromLs } from 'utils/token'
import handleRequest from 'sagas/handleRequest'
import { takeEvery, takeLatest } from 'utils/effects'
import auth from 'services/auth'
import {
  authLogin,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  HO_AUTH_LOGIN_SOCIAL_NETWORK,
  closeAll,
  setRedirectPathname,
  CLIENT_INITIATED,
  setLoggedIn,
  NO_JOB_REDIRECT,
  SET_AUTH_TOKENS,
  doSetRefreshTokensAt,
  REFRESH_TOKEN_REQ,
  doSetRefreshTokenRequestLocked,
  getRefreshTokenReq,
  authLogout,
  doSetAuthTokens,
} from 'store/actions'
import { fromRouting, fromContext, fromAuth } from 'store/selectors'
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  REFRESH_TOKEN,
  REFRESH_TOKENS_AT,
} from 'constants/auth'
import { SAVE_FEATURE_FLAGS } from 'feature-flag-consumer-js-lib/lib/state/actions'

export function* handleAuthLoginRequest({
  formName,
  username,
  password,
  accessToken,
  grantType,
}) {
  try {
    const response = yield* handleRequest({
      requestActions: authLogin,
      promise: auth.login({
        username,
        password,
        accessToken,
        grantType,
        realm: 'home-owner',
      }),
      checkTokens: false,
    })

    yield saveTokens({
      [ACCESS_TOKEN]: response[ACCESS_TOKEN],
      [ID_TOKEN]: response[ID_TOKEN],
      [REFRESH_TOKEN]: response[REFRESH_TOKEN],
    })

    yield put(reset(formName))
  } catch (e) {
    // TODO add check for violations
    yield put(stopSubmit(formName, { _error: e.error_description }))
    throw e
  }
}

function* handleAuthSocialNetworkLogin(action) {
  try {
    const lang = yield select(fromContext.getLang)
    const country = yield select(fromContext.getCountry)
    const response = yield* handleRequest({
      requestActions: authLogin,
      promise: auth.loginSocialNetwork(action.payload, `${lang}-${country}`),
      checkTokens: false,
    })

    yield saveTokens({
      [ACCESS_TOKEN]: response[ACCESS_TOKEN],
      [ID_TOKEN]: response[ID_TOKEN],
      [REFRESH_TOKEN]: response[REFRESH_TOKEN],
    })
  } catch (e) {
    if (!e.code) {
      return
    }

    throw e
  }
}

function* handleAuthLogout() {
  yield put(closeAll())
  removeTokens()

  const pathName = yield select(fromRouting.getPathname)

  if (pathName !== '/') {
    yield put(push('/'))
  }
}

function* handleAuthLoginSuccess() {
  yield put(closeAll())
  yield put(setLoggedIn(true))
  yield* notify('', 'user.sign_in.success')

  const redirectPathname = yield select(fromRouting.getRedirectPathname)

  if (redirectPathname) {
    yield put(push(redirectPathname))
    yield put(setRedirectPathname(null))
  }
}

function handleAuthLoginFailed() {
  removeTokens()
}

function* handleClientInitiated() {
  const isLoggedIn = yield bootstrapAuthFromLs()
  yield put(setLoggedIn(isLoggedIn))
}

function* handleNoJobRedirect() {
  yield put(push('/'))
}

function* handleSetAuthTokens({
  payload: { idToken, accessToken, refreshToken },
}) {
  if (idToken && accessToken) {
    const idTokenExpAt = yield select(fromAuth.selectIdTokenExpAt)
    const now = new Date().getTime() / 1000
    const refreshTokensAt = (idTokenExpAt + now) / 2
    yield put(doSetRefreshTokensAt({ payload: refreshTokensAt }))
    localStorage.setItem(REFRESH_TOKENS_AT, refreshTokensAt.toString())

    localStorage.setItem(ID_TOKEN, idToken)
    localStorage.setItem(ACCESS_TOKEN, accessToken)

    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN, refreshToken)
    } else {
      localStorage.removeItem(REFRESH_TOKEN)
    }
    return
  }
  localStorage.removeItem(REFRESH_TOKEN)
}

function* handleRefreshTokenReq() {
  const isRefreshTokenReqLocked = yield select(
    fromAuth.selectRefreshTokenRequestLocked,
  )

  if (isRefreshTokenReqLocked) {
    return null
  }

  yield put(doSetRefreshTokenRequestLocked(true))

  const token = yield select(fromAuth.getRefreshToken)
  return yield handleRequest({
    requestActions: getRefreshTokenReq,
    promise: auth.refreshToken(token),
    checkTokens: false,
  })
}

function* handleRefreshTokenReqSuccess({
  payload: { access_token, refresh_token, id_token },
}) {
  yield put(
    doSetAuthTokens({
      payload: {
        idToken: id_token,
        accessToken: access_token,
        refreshToken: refresh_token,
      },
    }),
  )
  yield put(doSetRefreshTokenRequestLocked(false))
}

function* handleRefreshTokenReqFailure({ error }) {
  if (error?.response?.status === 403) {
    yield put(authLogout())
    yield* notify('', 'user.sign_in.token_expired', 'error')
  }
}

function* handleSaveFeatureFlags() {
  const isLoggedIn = yield select(fromAuth.isLoggedIn)
  const refreshToken = yield select(fromAuth.getRefreshToken)

  if (!isLoggedIn) {
    return
  }

  if (!refreshToken) {
    yield put(authLogout())
    yield* notify('', 'user.sign_in.token_expired', 'error')
    return
  }

  yield put(getRefreshTokenReq.request())
}

export default function* () {
  yield all([
    takeLatest(CLIENT_INITIATED, handleClientInitiated),
    takeLatest(AUTH_LOGIN.REQUEST, handleAuthLoginRequest),
    takeLatest(AUTH_LOGIN.SUCCESS, handleAuthLoginSuccess),
    takeLatest(AUTH_LOGIN.FAILURE, handleAuthLoginFailed),
    takeLatest(AUTH_LOGOUT, handleAuthLogout),
    takeLatest(HO_AUTH_LOGIN_SOCIAL_NETWORK, handleAuthSocialNetworkLogin),
    takeLatest(NO_JOB_REDIRECT, handleNoJobRedirect),
    takeLatest(SET_AUTH_TOKENS, handleSetAuthTokens),
    takeEvery(REFRESH_TOKEN_REQ.REQUEST, handleRefreshTokenReq),
    takeLatest(REFRESH_TOKEN_REQ.SUCCESS, handleRefreshTokenReqSuccess),
    takeLatest(REFRESH_TOKEN_REQ.FAILURE, handleRefreshTokenReqFailure),
    takeLatest(SAVE_FEATURE_FLAGS, handleSaveFeatureFlags),
  ])
}
