export default {
  hits: [
    {
      name: "Remplacement d'un ballon d'eau chaude électrique",
      slug: 'installation-ou-remplacement-d-un-ballon-d-eau-chaude-electrique',
      price: 34900,
      img_url_portrait:
        'https://res.cloudinary.com/quotatis/image/upload/c_crop,g_custom:face,q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-chaude.jpg',
      img_url_landscape:
        'https://res.cloudinary.com/quotatis/image/upload/q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-chaude.jpg',
      short_description:
        'Besoin de faire installer votre ballon d&#39;eau chaude ? Pas de panique ! Quotatis est l&agrave; pour vous simplifier la vie. Nous trouvons pour vous un plombier-chauffagiste professionnel qualifi&eacute; afin de remplacer votre ballon d&rsquo;eau chaude &eacute;lectrique ou gaz.',
      objectID: '11',
      _highlightResult: {
        name: {
          value: "Remplacement d'un ballon d'eau <em>chaud</em>e électrique",
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        slug: {
          value:
            'installation-ou-remplacement-d-un-ballon-d-eau-<em>chaud</em>e-electrique',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        img_url_portrait: {
          value:
            'https://res.cloudinary.com/quotatis/image/upload/c_crop,g_custom:face,q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-<em>chaud</em>e.jpg',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        img_url_landscape: {
          value:
            'https://res.cloudinary.com/quotatis/image/upload/q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-<em>chaud</em>e.jpg',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        short_description: {
          value:
            "Besoin de faire installer votre ballon d'eau <em>chaud</em>e ? Pas de panique ! Quotatis est là pour vous simplifier la vie. Nous trouvons pour vous un plombier-chauffagiste professionnel qualifié afin de remplacer votre ballon d&rsquo;eau <em>chaud</em>e électrique ou gaz.",
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
      },
    },
    {
      name: "Remplacement d'un ballon d'eau chaude électrique",
      slug: 'installation-ou-remplacement-d-un-ballon-d-eau-chaude-electrique',
      price: 34900,
      img_url_portrait:
        'https://res.cloudinary.com/quotatis/image/upload/c_crop,g_custom:face,q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-chaude.jpg',
      img_url_landscape:
        'https://res.cloudinary.com/quotatis/image/upload/q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-chaude.jpg',
      short_description:
        'Besoin de faire installer votre ballon d&#39;eau chaude ? Pas de panique ! Quotatis est l&agrave; pour vous simplifier la vie. Nous trouvons pour vous un plombier-chauffagiste professionnel qualifi&eacute; afin de remplacer votre ballon d&rsquo;eau chaude &eacute;lectrique ou gaz.',
      objectID: '11',
      _highlightResult: {
        name: {
          value: "Remplacement d'un ballon d'eau <em>chaud</em>e électrique",
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        slug: {
          value:
            'installation-ou-remplacement-d-un-ballon-d-eau-<em>chaud</em>e-electrique',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        img_url_portrait: {
          value:
            'https://res.cloudinary.com/quotatis/image/upload/c_crop,g_custom:face,q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-<em>chaud</em>e.jpg',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        img_url_landscape: {
          value:
            'https://res.cloudinary.com/quotatis/image/upload/q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-<em>chaud</em>e.jpg',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        short_description: {
          value:
            "Besoin de faire installer votre ballon d'eau <em>chaud</em>e ? Pas de panique ! Quotatis est là pour vous simplifier la vie. Nous trouvons pour vous un plombier-chauffagiste professionnel qualifié afin de remplacer votre ballon d&rsquo;eau <em>chaud</em>e électrique ou gaz.",
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
      },
    },
    {
      name: "Remplacement d'un ballon d'eau chaude électrique",
      slug: 'installation-ou-remplacement-d-un-ballon-d-eau-chaude-electrique',
      price: 34900,
      img_url_portrait:
        'https://res.cloudinary.com/quotatis/image/upload/c_crop,g_custom:face,q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-chaude.jpg',
      img_url_landscape:
        'https://res.cloudinary.com/quotatis/image/upload/q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-chaude.jpg',
      short_description:
        'Besoin de faire installer votre ballon d&#39;eau chaude ? Pas de panique ! Quotatis est l&agrave; pour vous simplifier la vie. Nous trouvons pour vous un plombier-chauffagiste professionnel qualifi&eacute; afin de remplacer votre ballon d&rsquo;eau chaude &eacute;lectrique ou gaz.',
      objectID: '11',
      _highlightResult: {
        name: {
          value: "Remplacement d'un ballon d'eau <em>chaud</em>e électrique",
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        slug: {
          value:
            'installation-ou-remplacement-d-un-ballon-d-eau-<em>chaud</em>e-electrique',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        img_url_portrait: {
          value:
            'https://res.cloudinary.com/quotatis/image/upload/c_crop,g_custom:face,q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-<em>chaud</em>e.jpg',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        img_url_landscape: {
          value:
            'https://res.cloudinary.com/quotatis/image/upload/q_30/v1528720160/FR/ProductImages/remplacement-ballon-d-eau-<em>chaud</em>e.jpg',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
        short_description: {
          value:
            "Besoin de faire installer votre ballon d'eau <em>chaud</em>e ? Pas de panique ! Quotatis est là pour vous simplifier la vie. Nous trouvons pour vous un plombier-chauffagiste professionnel qualifié afin de remplacer votre ballon d&rsquo;eau <em>chaud</em>e électrique ou gaz.",
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['chaud'],
        },
      },
    },
  ],
  nbHits: 3,
  page: 0,
  nbPages: 1,
  hitsPerPage: 1000,
  processingTimeMS: 1,
  exhaustiveNbHits: true,
  query: 'chaud',
  params: 'query=chaud&page=0&hitsPerPage=3',
}
