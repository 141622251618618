import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import notify from 'sagas/notify'
import each from 'lodash/each'
import {
  GET_SURVEY,
  GET_SURVEY_QUESTIONS,
  SUBMIT_SURVEY_FORM,
  submitSurveyForm,
  getSurvey,
  getSurveyQuestions,
} from 'store/actions'
import { fromSurvey } from 'store/selectors'
import handleRequest from 'sagas/handleRequest'
import client from 'services/httpClient/commonClient'
import {
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TYPE_TEXT,
  QUESTION_SCALE_UP_TO_5,
  QUESTION_SCALE_UP_TO_10,
} from 'constants/form'
import { SURVEY } from 'constants/services'
import { getDisplayableQuestions } from 'helpers/form'

const urlPrefix = '/api'

function* handleSurvey({ payload: surveyToken }) {
  try {
    yield* handleRequest({
      requestActions: getSurvey,
      promise: call(
        client(SURVEY, true).get,
        `${urlPrefix}/surveys/${surveyToken}`,
      ),
    })
  } catch (e) {
    yield put(getSurvey.failure())

    throw e
  }
}

function* handleSurveyQuestions() {
  try {
    yield* handleRequest({
      requestActions: getSurveyQuestions,
      promise: call(client(SURVEY, false).get, `${urlPrefix}/survey_questions`),
    })
  } catch (e) {
    yield put(getSurveyQuestions.failure())

    throw e
  }
}

function* handleSubmitSurveyForm({ data, props }) {
  const { surveyQuestions } = props

  const displayableQuestions = getDisplayableQuestions(
    surveyQuestions,
    props.formValues,
  )

  const surveyAnsweredQuestions = []

  try {
    displayableQuestions.map(surveyQuestion => {
      const { type } = surveyQuestion
      const value = data[surveyQuestion.systemKey] || null
      const surveyQuestionId = surveyQuestion['@id']

      switch (type) {
        case QUESTION_MULTIPLE_CHOICE:
          each(value, val => {
            surveyAnsweredQuestions.push({
              question: surveyQuestionId,
              answer: val ? `/api/survey_answers/${val}` : null,
            })
          })
          break
        case QUESTION_SINGLE_CHOICE:
          surveyAnsweredQuestions.push({
            question: surveyQuestionId,
            answer: value ? `/api/survey_answers/${value}` : null,
          })
          break
        case QUESTION_TYPE_TEXT:
          surveyAnsweredQuestions.push({
            question: surveyQuestionId,
            answerText: value,
          })
          break
        case QUESTION_SCALE_UP_TO_5:
        case QUESTION_SCALE_UP_TO_10:
          surveyAnsweredQuestions.push({
            question: surveyQuestionId,
            answerText: value,
          })
          break
        // eslint-disable-next-line no-fallthrough
        default:
          console.warn(`Nothing for ${surveyQuestionId}`)
          break
      }

      return surveyAnsweredQuestions
    })
  } catch (e) {
    yield put(getSurveyQuestions.failure())
  }

  const surveyToken = yield select(fromSurvey.getSurveyToken)

  try {
    yield* handleRequest({
      requestActions: submitSurveyForm,
      promise: call(
        client(SURVEY, true).put,
        `${urlPrefix}/surveys/${surveyToken}`,
        {
          surveyAnsweredQuestions,
        },
      ),
    })

    yield* notify(
      '',
      'pro.package_candidate_document.saveUploadFileSuccess',
      'success',
    )

    yield put(getSurvey.request({ payload: surveyToken }))
  } catch (e) {
    yield put(submitSurveyForm.failure())

    // throw e
  }
}

function* handleFailureSurveyForm() {
  yield* notify('', 'firm.save.failure.message', 'error')
}

export default function* () {
  yield all([
    takeLatest(GET_SURVEY.REQUEST, handleSurvey),
    takeLatest(GET_SURVEY_QUESTIONS.REQUEST, handleSurveyQuestions),
    takeLatest(SUBMIT_SURVEY_FORM.REQUEST, handleSubmitSurveyForm),
    takeLatest(SUBMIT_SURVEY_FORM.FAILURE, handleFailureSurveyForm),
  ])
}
