import { GET_SURVEY, GET_SURVEY_QUESTIONS, SUBMIT_SURVEY_FORM } from './actions'
import { initialState } from './selectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY.SUCCESS: {
      return {
        ...state,
        survey: action.payload,
      }
    }
    case GET_SURVEY_QUESTIONS.SUCCESS: {
      return {
        ...state,
        surveyQuestions: action.payload['hydra:member'] || [],
        surveyDone: action.payload.surveyDone || false,
      }
    }
    case SUBMIT_SURVEY_FORM.SUCCESS: {
      return {
        ...state,
      }
    }
    case SUBMIT_SURVEY_FORM.ERROR: {
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}
