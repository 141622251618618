export const formDataBodyBuilder = (
  files,
  expirationDate,
  issueDate,
  reference,
  certificate,
  certificateCategories,
  documentName,
) => {
  const formattedFormData = new FormData()

  if (files.length > 0) {
    files.map((single, index) =>
      formattedFormData.append(`file${index}`, single.file),
    )
    files.map(single => formattedFormData.append('name', single.name))
  }

  const formattedFiles = files.map((single, index) => ({
    index: `file${index}`,
    name: single.name,
  }))

  const data = {
    type: documentName,
    expirationDate,
    issueDate,
    reference,
  }

  if (formattedFiles.length > 0) {
    data.files = formattedFiles
  }

  if (certificate) {
    data.certificate = {
      certificate,
      certificateCategories,
    }
  }

  formattedFormData.append('data', JSON.stringify(data))

  return formattedFormData
}
