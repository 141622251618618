import { parse, addMonths, isBefore } from 'date-fns'
import { isBeforeFormattedDate, isValidDate, createDate } from 'utils/date'
import { DATE_FORMAT } from 'constants/dateFormat'

export const DATE_SHORT_FORMAT = 'MM/yy'

export const isLeapYear = year =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

export const daysInMonth = (month, year) => {
  switch (month) {
    case 2:
      return isLeapYear(year) ? 29 : 28
    case 4:
    case 6:
    case 9:
    case 11:
      return 30
    default:
      return 31
  }
}

export const validDate = dateFormat => date =>
  isNaN(parse(date, dateFormat, new Date())) && {
    id: 'validators.date.invalid',
    values: {},
  }
export const validShortDate = date =>
  (!DATE_FORMAT.SHORT_FORMAT_REGEXP.test(date) && {
    id: 'validators.date.invalid',
    values: {},
  }) ||
  validDate(DATE_FORMAT.SHORT_FORMAT)(date)

export default (_, { birthdateDay, birthdateMonth, birthdateYear }) => {
  const error = { id: 'validators.user.birthdate.invalid_date', values: {} }
  const empty = field => field === undefined

  if ([birthdateDay, birthdateMonth, birthdateYear].every(empty)) return false
  if ([birthdateDay, birthdateMonth, birthdateYear].some(empty)) return error

  const [day, month, year] = [birthdateDay, birthdateMonth, birthdateYear].map(
    d => parseInt(d, 10),
  )
  const isInvalid =
    month < 1 || month > 12 || day < 1 || day > daysInMonth(month, year)
  return isInvalid && error
}

/**
 *
 * @param date string example 16/12/2015
 * @returns {*}
 */
export const dateInPastOrToday = date => {
  if (!date) {
    return undefined
  }

  return !isBeforeFormattedDate(date, DATE_FORMAT.FR_LONG)
    ? { id: 'validators.date.not_in_past', values: {} }
    : undefined
}

/**
 *
 * @param value string example 16/12/2015
 * @returns {*}
 */
export const validDateFormat =
  (regex = DATE_FORMAT.LONG_FORMAT_REGEXP, format = DATE_FORMAT.FR_LONG) =>
  value => {
    if (!value) {
      return false
    }

    return isValidDate(value, regex, format)
      ? false
      : { id: 'validators.date.invalid_fr_format', values: {} }
  }

/**
 * return true if the date isn't after the number of months authorized
 *
 * @param {string} date
 * @param {number} numberOfMonthsAuthorized
 * @param {string} dateFormat
 * @param {object} dateRegex
 * @param {boolean} isAlreadyFormated
 *
 * @returns {boolean}
 */
export const isAuthorizedDate = (
  date,
  numberOfMonthsAuthorized,
  isAlreadyFormated = false,
  dateFormat = DATE_FORMAT.FR_LONG,
  dateRegex = DATE_FORMAT.LONG_FORMAT_REGEXP,
) => {
  if (
    (!isAlreadyFormated && !isValidDate(date, dateRegex, dateFormat)) ||
    !numberOfMonthsAuthorized
  ) {
    return false
  }

  const lastPossibleDate = addMonths(new Date(), numberOfMonthsAuthorized)
  const formatedDate =
    isAlreadyFormated === true ? date : createDate(date, dateFormat)

  return !!isBefore(formatedDate, lastPossibleDate)
}
