import get from 'lodash/get'
import { createSelector } from 'reselect'
import { defaultLocale } from 'config'
import {
  POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG,
  POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG,
  PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG,
} from 'constants/firm'
import { PRO_ACC_NAV_DISPLAY_FLAG } from 'constants/pro'
import { POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG } from 'constants/job'
import {
  getLocaleFromHostname,
  getLangFromLocale,
  getCountryFromLocale,
} from 'utils/locale'

const locale = global.window
  ? getLocaleFromHostname(window.location.hostname)
  : defaultLocale

export const initialState = {
  country: getCountryFromLocale(locale),
  lang: getLangFromLocale(locale),
  locale: 'fr-FR',
  mainNavigation: false,
  accountNavigation: false,
  signInPopin: false,
  signInHoPopin: false,
  signInProPopin: false,
  pathContext: 'default',
  pathNameOrigin: null,
  cookies: undefined,
  showDocumentPopin: false,
  initialQueryParams: {},
  searchModalIsOpen: false,
  cookiesBannerIsOpen: false,
  [POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG]: false,
  [POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG]: false,
  [PRO_ACC_NAV_DISPLAY_FLAG]: false,
  firmPicturesEditPopinDisplayed: false,
  [PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG]: false,
  [POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG]: false,
}

export const getLang = state => get(state, 'lang')
export const getMainNavigation = state => get(state, 'mainNavigation')
export const getAccountNavigation = state => get(state, 'accountNavigation')
export const getSignInPopin = state => get(state, 'signInPopin')
export const getSignInProPopin = state => get(state, 'signInProPopin')
export const getPathContext = state => get(state, 'pathContext')
export const getPathNameOrigin = state => get(state, 'pathNameOrigin')
export const getShowDocumentPopin = state => get(state, 'showDocumentPopin')
const getSearchModalIsOpen = state => get(state, 'searchModalIsOpen')
const getFirmCoverEditPopinDisplayed = state =>
  get(state, `[${POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG}]`)
const getFirmLogoEditPopinDisplayed = state =>
  get(state, `[${POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG}]`)
const getProAccNavDisplayed = state =>
  get(state, `[${PRO_ACC_NAV_DISPLAY_FLAG}]`)
const getProMyAccountNav2Displayed = state =>
  get(state, `[${PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG}]`)
const getProJobDeclineReasonPopinDisplayed = state =>
  get(state, `[${POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG}]`)
export const isFirmPicturesEditPopinDisplayed = state =>
  get(state, 'firmPicturesEditPopinDisplayed')
export const isPopin = createSelector(
  getMainNavigation,
  getAccountNavigation,
  getSignInPopin,
  getShowDocumentPopin,
  getSearchModalIsOpen,
  getFirmCoverEditPopinDisplayed,
  isFirmPicturesEditPopinDisplayed,
  getFirmLogoEditPopinDisplayed,
  getProAccNavDisplayed,
  getProMyAccountNav2Displayed,
  getProJobDeclineReasonPopinDisplayed,
  (
    mainNavigation,
    accountNavigation,
    signInPopin,
    showDocumentPopin,
    searchModalIsOpen,
    firmCoverEditPopinDisplayed,
    firmPicturesEditPopinDisplayed,
    firmLogoEditPopinDisplayed,
    proAccNavDisplayed,
    proMyAccountNav2Displayed,
    proJobDeclineReasonPopinDisplayed,
  ) =>
    [
      mainNavigation,
      accountNavigation,
      signInPopin,
      showDocumentPopin,
      searchModalIsOpen,
      firmCoverEditPopinDisplayed,
      firmPicturesEditPopinDisplayed,
      firmLogoEditPopinDisplayed,
      proAccNavDisplayed,
      proMyAccountNav2Displayed,
      proJobDeclineReasonPopinDisplayed,
    ].some(value => value),
)
export const getCountry = state => get(state, 'country')
export const getLocale = createSelector(
  getLang,
  getCountry,
  (lang, country) => `${lang}-${country}`,
)
export const getInitialQueryParams = state => get(state, 'initialQueryParams')
export const getPartner = state => get(state, 'partner')
export const searchModalIsOpen = state => get(state, 'searchModalIsOpen')
export const cookiesBannerIsOpen = state => get(state, 'cookiesBannerIsOpen')
export const ratingsAndReviewsEnabled = createSelector(
  getLang,
  lang => lang === 'es',
)
export const isPopinDisplayed = (state, popInDisplayedFlag) =>
  get(state, `[${popInDisplayedFlag}]`)
export const getCookies = state => get(state, 'cookies')
