import seoFriendlyName from 'utils/seoFriendlyName'
import {
  ADMINISTRATIVE_AREA_LEVEL_1,
  ADMINISTRATIVE_AREA_LEVEL_2,
  CITY,
  POSTAL_CODE,
  GOOGLE_MAPS_COMPONENT_TYPES,
} from 'constants/google-maps-component-types'

const getAddressComponents = place =>
  place ? place.address_components || [] : []

const getPlaceComponentByTypeName = (place, componentTypeName) =>
  getAddressComponents(place).find(component =>
    component.types.includes(componentTypeName),
  )

const getPlaceComponentByType = (place, componentType) =>
  getPlaceComponentByTypeName(
    place,
    componentType.variants.find(t => getPlaceComponentByTypeName(place, t)),
  )

const getPlaceComponentTypes = place =>
  GOOGLE_MAPS_COMPONENT_TYPES.reduce((typesMap, componentType) => {
    const value = getPlaceComponentByType(place, componentType)
    if (value) {
      typesMap.set(componentType, value)
    }
    return typesMap
  }, new Map())

const getPlaceComponentNames = place =>
  [...getPlaceComponentTypes(place).values()].map(component =>
    component ? component.short_name : '',
  )

export const extractMostSpecificTypeFromPlace = place => {
  const firstFourTypes = [...getPlaceComponentTypes(place).keys()].splice(0, 4)
  const [last] = firstFourTypes.slice(-1)
  return last ? last.name : null
}

export const extractMostSpecificNameFromPlace = place => {
  const componentNames = getPlaceComponentNames(place)
  const [administrativeArea1, administrativeArea2, city, postalCode] =
    componentNames
  if (postalCode) {
    return `${city} (${postalCode})`
  }
  return city || administrativeArea2 || administrativeArea1
}

const extractAndTrimComponentNames = place => {
  const pathComponents = [
    ADMINISTRATIVE_AREA_LEVEL_1,
    ADMINISTRATIVE_AREA_LEVEL_2,
    CITY,
    POSTAL_CODE,
  ]
  const components = pathComponents
    .map(type => getPlaceComponentByType(place, type))
    .map(component => (component ? component.short_name : ''))

  const lastComponentIndex = components.reduce(
    (max, element, index) => (element ? index : max),
    0,
  )
  return components.slice(0, lastComponentIndex + 1)
}

export const formatPlaceForAPI = place =>
  extractAndTrimComponentNames(place).join('|').replace(/\|+$/, '')

export const formatPlaceAsPath = place =>
  extractAndTrimComponentNames(place)
    .map(name => seoFriendlyName(name))
    .join('/')
    .replace(/^/, '/')
    .replace(/\/+$/, '')
    .replace(/\/\//g, '/-/')

export const placePathFromProDirectoryUrl = pathname =>
  pathname.replace(/^((:?\/beta)?\/[^/]+)(\/[^/]+)?/, '')

export const routeLocationFromProDirectoryUrl = pathname =>
  pathname.replace(/^((:?\/beta)?\/[^/]+).*/, '$1')
