import { GOOGLE_PLACE_COORDS } from './actions'
import { initialState } from './selectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case GOOGLE_PLACE_COORDS.SUCCESS: {
      return {
        ...state,
        centerMap: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
