import get from 'lodash/get'

export const initialState = {
  fileToUpload: {},
  currentActionInfos: null,
  isUploading: false,
}
export const getCurrentActionInfos = state => get(state, 'currentActionInfos')
export const getIsUploading = state => get(state, 'isUploading')
export const getUploadedFiles = (state, uploadConfig) =>
  get(state, `[${uploadConfig}]`)
