import loadGoogleMapsAPI from 'utils/loadGoogleMapsAPI'

class GoogleMapsGeocoder {
  async initialize(address) {
    await loadGoogleMapsAPI()
    this.geocoder = new window.google.maps.Geocoder()
    const response = await this.geocoder
      .geocode({
        address,
      })
      .catch(err => false)

    return (
      response.results &&
      response.results.some(currentAddress =>
        this.isValidAddress(address, currentAddress.address_components),
      )
    )
  }

  isValidAddress = (address, address_components) => {
    const requiredComponents = address_components.filter(
      element =>
        element.types.includes('street_number') ||
        element.types.includes('route') ||
        element.types.includes('postal_code') ||
        element.types.includes('locality'),
    )

    if (requiredComponents.length !== 4) {
      return false
    }

    let buildAddress = ''
    requiredComponents.forEach(element => {
      if (
        element.types.includes('street_number') ||
        element.types.includes('route')
      ) {
        buildAddress = buildAddress.concat(
          buildAddress ? ' ' : '',
          element.long_name,
        )
      }

      if (element.types.includes('locality')) {
        buildAddress = buildAddress.concat(', ', element.long_name)
      }
    })

    return address.toUpperCase() === buildAddress.toUpperCase()
  }
}

export default GoogleMapsGeocoder
