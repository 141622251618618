import client from 'services/httpClient/commonClient'
import { takeLatest } from 'utils/effects'
import notify from 'sagas/notify'
import { all, select, put, call } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import uuidv4 from 'uuid/v4'

import handleRequest from 'sagas/handleRequest'
import {
  UPLOAD_DOCUMENT_FILE,
  SAVE_DOCUMENT_FILE_ON_FIRM,
  GET_FIRM_CERTIFICATES,
  uploadDocumentFile,
  getFirmCertificates,
  getFile,
  OPEN_FILE,
  GET_FILE,
  firmDetails,
} from 'store/actions'
import { fromPro, fromNotifications } from '../selectors'
import { formDataBodyBuilder } from 'utils/documents'
import { DOCUMENTS, PRO_API } from 'constants/services'

const uploadDocumentFileRequest = function* ({
  data,
  expirationDate,
  issueDate,
  reference,
  documentName,
  certificateCategory,
  certificateId,
  documentIri,
}) {
  const firmId = yield select(fromPro.getFirmId)
  const filteredFiles = data.filter(single => !single.alreadyUploaded)
  const alreadyFilteredFiles = data.filter(single => single.alreadyUploaded)

  const formattedFormData = formDataBodyBuilder(
    filteredFiles,
    expirationDate,
    issueDate,
    reference,
    certificateId,
    certificateCategory,
    documentName,
  )

  if (filteredFiles.length > 0) {
    const notifId = uuidv4()
    yield* notify(
      `pro.account.pro-documents.${documentName}.title`,
      'pro.account.pro-documents.dowloading',
      'loading',
      {},
      {},
      false,
      notifId,
    )
    yield* handleRequest({
      requestActions: uploadDocumentFile,
      promise: call(
        client(PRO_API, true).post,
        `${firmId}/document`,
        formattedFormData,
      ),
      actionParams: { notifId },
    })
  }

  if (alreadyFilteredFiles.length === 0) {
    return
  }

  const documentId = documentIri.split('/').pop()

  yield* handleRequest({
    requestActions: uploadDocumentFile,
    promise: call(
      client(PRO_API, true).post,
      `${firmId}/document/${documentId}`,
      formattedFormData,
    ),
    actionParams: { displayNotifications: true, documentName },
  })
}

const uploadDocumentsFileSuccess = function* ({ actionParams }) {
  try {
    const firmId = yield select(fromPro.getFirmId)
    yield put(firmDetails.request({ id: firmId }))

    if (actionParams?.notifId) {
      yield* dismissNotifications(actionParams.notifId)

      return yield* notify(
        `pro.account.pro-documents.${actionParams.documentName}.title`,
        'pro.account.pro-documents.downloaded',
        'success',
      )
    }

    if (actionParams?.displayNotifications) {
      return yield* notify(
        `pro.account.pro-documents.${actionParams.documentName}.title`,
        'pro.account.pro-documents.updated',
        'success',
      )
    }
  } catch (err) {
    yield* notify('', 'firm.save.failure.message', 'error')
  }
}

const dismissNotifications = function* (notifId) {
  const toastId = yield select(
    fromNotifications.getToastIdByIdNotification,
    notifId,
  )
  toast.dismiss(toastId)
}

const uploadDocumentsFileFailure = function* ({ actionParams }) {
  yield* dismissNotifications(actionParams.notifId)
  yield* notify(
    `pro.account.pro-documents.${actionParams.documentName}.title`,
    'firm.save.failure.message',
    'error',
  )
}

const getFirmCertificatesRequest = function* () {
  yield* handleRequest({
    requestActions: getFirmCertificates,
    promise: call(client(PRO_API, true).get, '/api/certificates'),
  })
}

const openFileRequest = function* ({ fileId }) {
  const newWindow = window.open('document', 'blank')

  yield* handleRequest({
    requestActions: getFile,
    promise: call(client(DOCUMENTS, true).get, `${fileId}/download`),
    actionParams: {
      newWindow,
    },
  })
}

function getFileSuccess({ payload, actionParams: { newWindow } }) {
  const extention = payload.name.split('.').pop()
  const type = extention === 'pdf' ? 'application/pdf' : `image/${extention}`
  const style = extention === 'pdf' ? 'width="100%" height="100%"' : ''
  const fileURL = `data:${type};base64,${payload.content}`
  newWindow.document.write(`<object data="${fileURL}" ${style}></object>`)
  newWindow.document.body.style.border = 'none'
  newWindow.document.body.style.margin = '0'
  newWindow.document.body.style.padding = '0'
  newWindow.document.body.style.overflow = 'hidden'
  newWindow.document.close()
}

export default function* () {
  yield all([
    takeLatest(UPLOAD_DOCUMENT_FILE.REQUEST, uploadDocumentFileRequest),
    takeLatest(UPLOAD_DOCUMENT_FILE.SUCCESS, uploadDocumentsFileSuccess),
    takeLatest(UPLOAD_DOCUMENT_FILE.FAILURE, uploadDocumentsFileFailure),
    takeLatest(SAVE_DOCUMENT_FILE_ON_FIRM.FAILURE, uploadDocumentsFileFailure),
    takeLatest(GET_FIRM_CERTIFICATES.REQUEST, getFirmCertificatesRequest),
    takeLatest(OPEN_FILE, openFileRequest),
    takeLatest(GET_FILE.SUCCESS, getFileSuccess),
  ])
}
