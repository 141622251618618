import get from 'lodash/get'
import { createSelector } from 'reselect'

export const initialState = {
  list: [],
}

const getDepartmentsSelector = state => get(state, 'departments')
const getDepartments = createSelector(getDepartmentsSelector, departments =>
  get(departments, 'list'),
)

export const hasDepartmentsSelector = createSelector(
  getDepartments,
  departments => departments && departments.length > 0,
)
