import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  SUBSCRIBE_TO_PACKAGE_OFFER,
  SUBSCRIBE_TO_PACKAGE,
  GET_SUBSCRIPTION_BY_FIRM,
  SAVE_INTERVENTION_AREA,
  subscribeToProductOffer,
  getSubscriptionOfferByFirm as getSubscriptionOfferByFirmActions,
  saveInterventionArea,
} from 'store/actions'
import client from 'services/httpClient/commonClient'
import handleRequest from 'sagas/handleRequest'
import { fromPro, fromProductSubscription } from 'store/selectors'
import lastIdFromIRI from 'utils/lastIdFromIRI'
import { PRO_API } from 'constants/services'

// TODO: interventionArea and package subscription be should be submitted in the same call LATER
function* handleProductSubscriptionOffer() {
  try {
    const firmId = yield select(fromPro.getFirmId)
    yield handleRequest({
      requestActions: subscribeToProductOffer,
      promise: call(
        client(PRO_API, true).post,
        '/api/firm_product_subscriptions',
        {
          firm: firmId,
        },
      ),
    })
  } catch (e) {
    yield put(subscribeToProductOffer.failure())

    throw e
  }
}

function* handleProductSubscription(action) {
  try {
    const { productCode } = action
    const firmProductSubscription = yield select(
      fromProductSubscription.getFirmProductSubscription,
    )
    yield handleRequest({
      requestActions: subscribeToPackage,
      promise: call(client(PRO_API, true).post, '/api/product_subscriptions', {
        productCode,
        firmProductSubscription: firmProductSubscription['@id'],
      }),
    })
  } catch (e) {
    yield put(subscribeToPackage.failure())
  }
}

function* getSubscriptionByFirm() {
  try {
    const firmIri = yield select(fromPro.getFirmId)
    const response = yield client(PRO_API, true).get(
      '/api/firm_product_subscriptions',
      {
        params: { firm: lastIdFromIRI(firmIri) },
      },
    )
    const firmProductSubscriptions = response.data['hydra:member'] || null

    if (!firmProductSubscriptions && response['hydra:totalItems'] === 0) {
      yield null
    }

    yield put(
      getSubscriptionOfferByFirmActions.success(
        firmProductSubscriptions.shift(),
      ),
    )
  } catch (e) {
    yield put(getSubscriptionOfferByFirmActions.failure())
  }
}

// TODO: interventionArea and package subscription be should be submitted in the same call LATER
function* handleInterventionArea(action) {
  try {
    const postcodes = []
    const departments = action.values.departments || {}
    Object.keys(departments).map(key => {
      if (departments[key] === true) {
        postcodes.push(key.replace('dept_', ''))
      }

      return key
    })

    const firmProductSubscription = yield select(
      fromProductSubscription.getFirmProductSubscription,
    )
    yield handleRequest({
      requestActions: saveInterventionArea,
      promise: call(client(PRO_API, true).put, firmProductSubscription['@id'], {
        postcodes,
      }),
    })
  } catch (e) {
    yield put(saveInterventionArea.failure())
  }
}

export default function* () {
  yield all([
    takeLatest(
      SUBSCRIBE_TO_PACKAGE_OFFER.REQUEST,
      handleProductSubscriptionOffer,
    ),
    takeLatest(SUBSCRIBE_TO_PACKAGE.REQUEST, handleProductSubscription),
    takeLatest(GET_SUBSCRIPTION_BY_FIRM.REQUEST, getSubscriptionByFirm),
    takeLatest(SAVE_INTERVENTION_AREA.REQUEST, handleInterventionArea),
  ])
}
