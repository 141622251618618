import { generateRequestActions, createRequestTypes } from 'utils/actionHelpers'

export const SAVE_INTERVENTION_AREA = createRequestTypes(
  'SAVE_INTERVENTION_AREA',
)
export const SUBSCRIBE_TO_PACKAGE_OFFER = createRequestTypes(
  'SUBSCRIBE_TO_PACKAGE_OFFER',
)
export const SUBSCRIBE_TO_PACKAGE = createRequestTypes('SUBSCRIBE_TO_PACKAGE')
export const GET_SUBSCRIPTION_BY_FIRM = createRequestTypes(
  'GET_SUBSCRIPTION_BY_FIRM',
)

export const saveInterventionArea = generateRequestActions(
  SAVE_INTERVENTION_AREA,
)
export const subscribeToProductOffer = generateRequestActions(
  SUBSCRIBE_TO_PACKAGE_OFFER,
)
export const subscribeToProduct = generateRequestActions(SUBSCRIBE_TO_PACKAGE)
export const getSubscriptionOfferByFirm = generateRequestActions(
  GET_SUBSCRIPTION_BY_FIRM,
)
