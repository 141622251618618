import { connect } from 'react-redux'
import { compose } from 'redux'
import { setCookiesConsentContext } from 'store/actions'
import { fromContext } from 'store/selectors'
import { QUERY_TRACKING } from 'constants/routes'
import AxeptioManager from './AxeptioManager'

const mapStateToProps = state => ({
  cookies: fromContext.getCookies(state),
  useTracking: localStorage.getItem(QUERY_TRACKING),
})

const mapDispatchToProps = dispatch => ({
  setCookiesConsent: () => dispatch(setCookiesConsentContext()),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AxeptioManager,
)
