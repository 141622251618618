import { generateSimpleAction } from 'utils/actionHelpers'

export const SEARCH_ENGINE_SEARCH = 'SEARCH_ENGINE_SEARCH'
export const SEARCH_ENGINE_SET_RESULTS = 'SEARCH_ENGINE_SET_RESULTS'
export const SEARCH_ENGINE_SET_CATEGORY = 'SEARCH_ENGINE_SET_CATEGORY'
export const SEARCH_CATEGORIES = 'SEARCH_CATEGORIES'

export const searchEngineSearch = generateSimpleAction(SEARCH_ENGINE_SEARCH)
export const searchEngineSetResults = generateSimpleAction(
  SEARCH_ENGINE_SET_RESULTS,
)
export const setSearchCategory = generateSimpleAction(
  SEARCH_ENGINE_SET_CATEGORY,
)
export const searchCategories = generateSimpleAction(SEARCH_CATEGORIES)
