import { initialState } from './selectors'
import {
  DIRECTORY_PLACE_AUTO_COMPLETE_LOCATION,
  DIRECTORY_PLACE_CLEAR_AUTO_COMPLETE_LOCATION,
  FIRM_LIST,
  SET_FIRM_QUERY_MATCHING_TRADE,
  SET_CURRENT_SEARCH_PLACE,
  CLEAR_CURRENT_SEARCH_PLACE,
} from './actions'

export default (state = initialState, action) => {
  switch (action.type) {
    case FIRM_LIST.REQUEST: {
      const resultsFragment = action.reset
        ? {}
        : { results: state.search.results }
      const { seoFriendlyTradeName, nextPage } = action
      return {
        ...state,
        search: {
          query: {
            seoFriendlyTradeName,
            nextPage,
          },
          ...resultsFragment,
        },
      }
    }
    case FIRM_LIST.SUCCESS: {
      const firmsFromPreviousPages =
        (state.search.results && state.search.results.firms) || []
      return {
        ...state,
        search: {
          ...state.search,
          results: {
            totalCount: action.payload.nbHits,
            firms: [...firmsFromPreviousPages, ...action.payload.hits],
            nextPage: action.payload.nextPage,
          },
        },
      }
    }
    case SET_FIRM_QUERY_MATCHING_TRADE:
      return {
        ...state,
        search: {
          ...state.search,
          matchingTrade: action.trade.name,
        },
      }
    case DIRECTORY_PLACE_AUTO_COMPLETE_LOCATION:
      return {
        ...state,
        autoCompleteLocation: action.payload,
      }
    case DIRECTORY_PLACE_CLEAR_AUTO_COMPLETE_LOCATION: {
      const { autoCompleteLocation, ...rest } = state
      return {
        ...rest,
      }
    }
    case SET_CURRENT_SEARCH_PLACE: {
      return {
        ...state,
        currentSearchPlace: action.payload,
      }
    }
    case CLEAR_CURRENT_SEARCH_PLACE: {
      const { currentSearchPlace, ...rest } = state
      return {
        ...rest,
      }
    }
    default: {
      return state
    }
  }
}
