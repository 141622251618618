import { put, select } from 'redux-saga/effects'
import { fromAuth } from 'store/selectors'
import { getRefreshTokenReq } from 'store/auth/actions'
import { isMocks } from 'services/isMocks'

export default function* handleRequest({
  requestActions,
  promise,
  actionParams,
  checkTokens = true,
}) {
  try {
    if (checkTokens && !isMocks) {
      const idTokenExpAt = yield select(fromAuth.selectIdTokenExpAt)
      const now = new Date().getTime() / 1000

      if (idTokenExpAt !== null && now >= idTokenExpAt) {
        yield put(getRefreshTokenReq.request())
        throw new Error('Token expired before call api begin')
      }
    }

    const response = yield promise
    yield put(requestActions.success(response.data, actionParams))

    if (checkTokens && !isMocks) {
      const refreshTokensAt = yield select(fromAuth.selectRefreshTokensAt)
      const now = new Date().getTime() / 1000

      if (refreshTokensAt !== null && now >= refreshTokensAt) {
        yield put(getRefreshTokenReq.request())
      }
    }

    return response
  } catch (e) {
    if (
      checkTokens &&
      e?.response?.data?.detail === 'Expired token' &&
      !isMocks
    ) {
      yield put(getRefreshTokenReq.request())
    }

    yield put(requestActions.failure(e, actionParams))

    throw e
  }
}
