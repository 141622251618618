export const URLS = {
  QUOTATIS: {
    APP_METADATA: 'http://quotatis.fr/app_metadata',
    USER_AUTHORIZATION: 'http://quotatis.fr/user_authorization',
  },
  ROUTES: {
    PRO_LOGIN: '/login',
    PRO_ACCOUNT: '/my-account/page',
    PRO_DASHBOARD: '/my-account/dashboard',
    PRO_PARTNER_REGISTRATION: '/inscription-artisan-partenaire',
    PRO_REGISTRATION: '/inscription-artisan-poseur',
    CANDIDATE_PACKAGE: '/my-account/candidate-packages',
    CANDIDATE_PACKAGE_DOCS: '/my-account/candidate-packages/documents',
    CANDIDATE_PACKAGE_STUDY: '/my-account/candidate-packages/study',
    CANDIDATE_PACKAGE_CONFIGURATION:
      '/my-account/candidate-packages/configuration',
  },
}
