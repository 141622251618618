import React, { memo } from 'react'
import { intlShape } from 'react-intl'
import i18n from 'i18n'
import hoistStatics from 'hoist-non-react-statics'

import labelWithColon from './labelWithColon'

export const translateForIntl = intl => (id, values) =>
  intl.formatMessage({ id }, values)

export const injectTranslateImpl = translateForIntlImpl => Component => {
  const InjectTranslate = (props, { intl }) => (
    <Component
      translate={translateForIntlImpl(intl)}
      rawtranslate={key => i18n[intl.locale][key]}
      {...props}
    />
  )

  InjectTranslate.contextTypes = {
    intl: intlShape,
  }

  const componentName = Component.displayName || Component.name
  InjectTranslate.displayName = `InjectTranslate(${componentName})`
  InjectTranslate.WrappedComponent = Component

  hoistStatics(InjectTranslate, Component)

  const PureInjectTranslate = memo(InjectTranslate)
  hoistStatics(PureInjectTranslate, InjectTranslate)

  return PureInjectTranslate
}

const injectTranslate = injectTranslateImpl(translateForIntl)

export default injectTranslate
