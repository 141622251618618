import React from 'react'
import translations from 'i18n'
import anonymousOnly from 'hoc/security/anonymousOnly'
import adminOnly from 'hoc/security/adminOnly'
import requiresAuth from 'hoc/security/requiresAuth'
import requiresProAuth from 'hoc/security/requiresProAuth'
import MaintenancePage from 'pages/MaintenancePage'
import translateFactory from 'utils/translateFactory'
import { ROLES } from './constants/roles'
import { URLS } from './constants/urls'
import { UNIVERSES, UNIVERSES_SUB_DOMAIN } from 'constants/routes'
import { getInfosFromHostname } from 'utils/url'

const HomePage = React.lazy(() => import('pages/HomePage/next'))
const VerifyEmailPage = React.lazy(() => import('pages/VerifyEmailPage'))
const ProFormPage = React.lazy(() => import('pages/ProFormPage'))
const FirmPage = React.lazy(() => import('pages/FirmPage'))
const ForgotPasswordPage = React.lazy(() => import('pages/ForgotPasswordPage'))
const ProForgotPasswordPage = React.lazy(() =>
  import('pages/ForgotPasswordPage/pro'),
)
const LoginPage = React.lazy(() => import('pages/LoginPage/ho'))
const ProLoginPage = React.lazy(() => import('pages/LoginPage/pro'))
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'))
const ProContractSignedPage = React.lazy(() =>
  import('pages/ProContractSignedPage'),
)
const ProHomePage = React.lazy(() => import('pages/ProHomePage/next'))
const AboutUsPage = React.lazy(() => import('pages/AboutUsPage'))
const StyleGuidePage = React.lazy(() => import('pages/StyleGuidePage'))
const DirectoryHomePage = React.lazy(() => import('pages/DirectoryHomePage'))
const DirectorySearchResultPage = React.lazy(() =>
  import('pages/DirectorySearchResultPage'),
)
const SetPasswordPage = React.lazy(() => import('pages/SetPasswordPage'))
const ResetPasswordConfirmationPage = React.lazy(() =>
  import('pages/SetPasswordConfirmationPage'),
)
const SignupPage = React.lazy(() => import('pages/SignupPage'))
const SignupLegacyPage = React.lazy(() => import('pages/SignupLegacyPage'))
const SurveyPage = React.lazy(() => import('pages/SurveyPage'))
const VersionPage = React.lazy(() => import('pages/VersionPage'))
const ProSignupPage = React.lazy(() => import('pages/ProSignupPage'))
const ContactProPage = React.lazy(() => import('pages/ContactProPage'))
const ProMemberSpace = React.lazy(() => import('pages/ProMemberSpace'))
const ProAdminEditPage = React.lazy(() => import('pages/ProAdminEditPage'))
const ProductSubscriptionPage = React.lazy(() =>
  import('pages/ProductSubscriptionPage'),
)
const ProRegistration = React.lazy(() => import('pages/ProRegistrationPage'))
const ProRegistrationConfirmation = React.lazy(() =>
  import('pages/ProRegistrationConfirmationPage'),
)
const ProValidationEmailPage = React.lazy(() =>
  import('pages/ProValidationEmailPage'),
)
const ProSignUpPackage = React.lazy(() => import('pages/ProSignUpPackagePage'))
const ProSignUpPackageConfirmation = React.lazy(() =>
  import('pages/ProSignUpPackageConfirmationPage'),
)
const ProPartnerRegistration = React.lazy(() =>
  import('pages/ProPartnerRegistrationPage'),
)

const getHomeOwnerRoutes = () => [
  { path: '/maintenance', component: MaintenancePage },
  { path: '/version', component: VersionPage },
  {
    path: '/forgot-password',
    component: anonymousOnly(ForgotPasswordPage, ROLES.HO),
  },
  { path: '/login', component: anonymousOnly(LoginPage, ROLES.HO) },
  { path: '/verify-email', component: requiresAuth(VerifyEmailPage) },
  { path: '/reset-password', component: SetPasswordPage },
  { path: '/pro-form', component: ProFormPage },
  {
    path: '/signup',
    component: anonymousOnly(SignupPage, ROLES.HO),
    exact: true,
  },
  {
    path: '/signup/:token',
    component: anonymousOnly(SignupLegacyPage, ROLES.HO),
    exact: true,
  },
  {
    path: '/survey/:token',
    component: anonymousOnly(SurveyPage, ROLES.HO),
    exact: true,
  },
]

export const getFreeniumSignUpConfirmationPath = lang =>
  translations[lang]['new_url.routes.freemium_registration_confirmation']
export const getProSignUpPackageConfirmationPath = lang =>
  translations[lang][
    'new_url.routes.freemium_package_registration_confirmation'
  ]

export const getProRoutes = (translate, lang) => {
  const proSignUpPath = translate('new_url.routes.pro.sign_up')
  const freeniumSignUpPath = translate('new_url.routes.freemium_registration')
  const freeniumSignUpConfirmationPath = getFreeniumSignUpConfirmationPath(lang)
  const proSignUpPackagePagePath = translate(
    'new_url.routes.freemium_package_registration',
  )
  const proSignUpPackageConfirmationPath =
    getProSignUpPackageConfirmationPath(lang)

  const routes = [
    { path: '/', component: ProHomePage, exact: true },
    { path: '/qui-sommes-nous', component: AboutUsPage, exact: true },
    { path: proSignUpPath, component: ProSignupPage },
    { path: '/my-account', component: requiresProAuth(ProMemberSpace) },
    {
      path: '/forgot-password',
      component: anonymousOnly(ProForgotPasswordPage, ROLES.PRO),
    },
    {
      path: '/reset-password-confirmation',
      component: ResetPasswordConfirmationPage,
    },
    { path: '/reset-password', component: SetPasswordPage },
    {
      path: URLS.ROUTES.PRO_LOGIN,
      component: anonymousOnly(ProLoginPage, ROLES.PRO),
    },
    {
      path: '/firms/:firmId/edit',
      component: adminOnly(ProAdminEditPage),
    },
    {
      path: '/product-subscription',
      component: requiresProAuth(ProductSubscriptionPage),
    },
    {
      path: '/contract_yousign',
      component: requiresProAuth(ProContractSignedPage),
    },
    {
      path: freeniumSignUpConfirmationPath,
      component: ProRegistrationConfirmation,
    },
    {
      path: freeniumSignUpPath,
      component: ProRegistration,
    },
    { path: '/validate-email', component: ProValidationEmailPage },
    {
      path: proSignUpPackageConfirmationPath,
      component: ProSignUpPackageConfirmation,
    },
    {
      path: proSignUpPackagePagePath,
      component: ProSignUpPackage,
    },
    {
      path: '/inscription-artisan-partenaire',
      component: ProPartnerRegistration,
      exact: true,
    },
    {
      path: '/styleguide',
      component: StyleGuidePage,
      exact: true,
    },
  ]

  return routes
}

const getDirectoryRoutes = translate => {
  const proDirPath = translate('new_url.routes.pro_directory')
  const proDirTradeResultsPath = translate('new_url.routes.pros_by_trade', {
    trade: ':trade',
  })
  const proDirTradeAndLocResultsPath = translate(
    'new_url.routes.pros_by_trade_and_address',
    {
      trade: ':trade',
      address: ':address*',
    },
  )
  const firmPath = translate('routes.firm', { firmId: ':firmId' })

  return [
    {
      path: proDirPath,
      component: DirectoryHomePage,
      exact: true,
    },
    { path: firmPath, component: FirmPage, exact: true },
    { path: '/contact-pro/:firmId', component: ContactProPage },
    {
      path: proDirTradeAndLocResultsPath,
      component: DirectorySearchResultPage,
    },
    {
      path: proDirTradeResultsPath,
      component: DirectorySearchResultPage,
      exact: true,
    },
    { component: NotFoundPage },
  ]
}

const getPartnerRoutes = () => [
  { path: '/', component: HomePage, exact: true },
  { component: NotFoundPage },
]

export const getAllRoutes = ({ translate, lang }) => ({
  [UNIVERSES.DIR]: getDirectoryRoutes(translate),
  [UNIVERSES.PARTNER]: getPartnerRoutes(),
  [UNIVERSES.PRO]: [
    ...getProRoutes(translate, lang),
    ...getHomeOwnerRoutes(translate),
    { component: NotFoundPage },
  ],
})

const getRoutes = ({ translate, lang }) => {
  const subDomain = getInfosFromHostname().subDomain

  switch (subDomain) {
    case UNIVERSES_SUB_DOMAIN[UNIVERSES.DIR]:
      return getAllRoutes({ translate, lang })[UNIVERSES.DIR]
    case UNIVERSES_SUB_DOMAIN[UNIVERSES.PARTNER]:
      return getAllRoutes({ translate, lang })[UNIVERSES.PARTNER]
    case UNIVERSES_SUB_DOMAIN[UNIVERSES.PRO]:
      return getAllRoutes({ translate, lang })[UNIVERSES.PRO]
    default:
      return [{ component: NotFoundPage }]
  }
}

export default (lang, origin) =>
  getRoutes({ translate: translateFactory(lang), lang, origin })
