import { all, put } from 'redux-saga/effects'
import { takeLatest } from 'utils/effects'
import geoCode from 'utils/geoCode'
import { GOOGLE_PLACE_COORDS, googlePlaceCoords } from './actions'

export function* handleGooglePlaceCoords({ localisation }) {
  const result = yield geoCode(localisation)
  const { location } = result.geometry
  const payload = { lat: location.lat(), lng: location.lng() }
  yield put(googlePlaceCoords.success(payload))
  return payload
}

export default function* () {
  yield all([takeLatest(GOOGLE_PLACE_COORDS.REQUEST, handleGooglePlaceCoords)])
}
