import { all, put, select } from 'redux-saga/effects'
import { takeLatest } from 'utils/effects'
import { push } from 'react-router-redux'

import { aroundRadius, aroundPrecision } from 'constants/geoSearch'
import { firmIndex } from 'services/algolia'
import {
  fromDirectory,
  fromRouting,
  fromTrades,
  fromContext,
} from 'store/selectors'
import { requireTrades } from 'store/trades/sagas'
import {
  formatPlaceAsPath,
  routeLocationFromProDirectoryUrl,
} from 'utils/geoComponents'
import { DIRECTORY_RESULTS_PER_PAGE } from 'constants/pro-directory'
import {
  firmList,
  setFirmQueryMatchingTrade,
  setCurrentSearchPlace,
  FIRM_LIST,
  TRIGGER_NEW_DIRECTORY_SEARCH,
} from './actions'

function* handleFirmListRequest({ seoFriendlyTradeName }) {
  yield* requireTrades()
  const trade = yield select(
    fromTrades.getTradeBySeoFriendlyName,
    seoFriendlyTradeName,
  )
  if (!trade) {
    yield put(firmList.failure())
    const currentLocation = yield select(fromRouting.getPathname)
    yield put(
      push({
        pathname: routeLocationFromProDirectoryUrl(currentLocation),
      }),
    )
  } else {
    const place = yield select(fromDirectory.getPlace)
    const nextPage = yield select(fromDirectory.getSearchResultsNextPage)
    const country = yield select(fromContext.getCountry)

    yield put(setFirmQueryMatchingTrade({ trade }))

    let payload = {
      tagFilters: ['activeInDirectory'],
      hitsPerPage: DIRECTORY_RESULTS_PER_PAGE,
      filters: `"${trade.name}" AND ${country}`,
      page: nextPage || 0,
    }

    if (place) {
      let placePayload = {}

      if (
        place.types.some(element =>
          element.match(/^administrative_area_level_\d+$/),
        )
      ) {
        const viewport = Object.values(place.geometry.viewport)
        const viewportExtract = []

        Object.values(viewport).forEach(data => {
          const dataExtract = Object.values(data)
          viewportExtract.push(dataExtract[0])
          viewportExtract.push(dataExtract[1])
        })

        placePayload = {
          insideBoundingBox: [
            viewportExtract[0],
            viewportExtract[2],
            viewportExtract[1],
            viewportExtract[3],
          ].join(', '),
        }
      } else {
        placePayload = {
          aroundLatLng: `${place.geometry.location.lat()}, ${place.geometry.location.lng()}`,
          aroundRadius,
          aroundPrecision,
        }
      }

      payload = {
        getRankingInfo: true,
        ...placePayload,
        ...payload,
      }
    }

    const { hits, nbHits, page, nbPages } = yield firmIndex().search(payload)
    yield put(
      firmList.success({
        hits,
        nbHits,
        nextPage: page < nbPages ? page + 1 : null,
      }),
    )
  }
}

function* triggerSearchByTradeAndLocation(payload) {
  const { trade, localisation } = payload
  const autoCompleteLocation = yield select(
    fromDirectory.getDirectoryPlaceAutoCompleteLocation,
  )

  let locationPath = ''

  if (localisation) {
    if (autoCompleteLocation && localisation === autoCompleteLocation.value) {
      const { value } = autoCompleteLocation
      if (localisation === value) {
        const { place } = autoCompleteLocation
        locationPath = formatPlaceAsPath(place)
      }

      yield put(setCurrentSearchPlace({ payload: autoCompleteLocation.place }))
    } else {
      const placeField = document.getElementById('placefield-id')
      placeField.blur()

      // It waits for 20ms so autocompletion data hides for a brief moment
      setTimeout(() => placeField.focus(), 20)
      return
    }
  }

  const currentLocation = yield select(fromRouting.getPathname)
  const newLocation = `/${trade}${locationPath}`
  if (newLocation !== currentLocation) {
    yield put(
      push({
        pathname: newLocation,
        noScroll: true,
      }),
    )
  }
}

export default function* () {
  yield all([
    takeLatest(FIRM_LIST.REQUEST, handleFirmListRequest),
    takeLatest(TRIGGER_NEW_DIRECTORY_SEARCH, triggerSearchByTradeAndLocation),
  ])
}
