import axios from 'axios'
import {
  api,
  documentsApi,
  billing,
  frontApi,
  proApi,
  survey,
  sylius,
  engine,
} from 'config'
import {
  DOCUMENTS,
  BILLING,
  ENGINE,
  FRONT_API,
  PRO_API,
  SURVEY,
  SYLIUS,
} from 'constants/services'
import { ACCESS_TOKEN, ID_TOKEN } from 'constants/auth'

const client = (clientName, noCache = false) => {
  let url
  switch (clientName) {
    case DOCUMENTS:
      url = documentsApi.url
      break
    case BILLING:
      url = billing.url
      break
    case FRONT_API:
      url = frontApi.url
      break
    case PRO_API:
      url = proApi.url
      break
    case SYLIUS:
      url = sylius.url
      break
    case SURVEY:
      url = survey.url
      break
    case ENGINE:
      url = engine.url
      break
  }

  const headers = {
    'Content-Type': 'application/ld+json',
    apikey: api.kongApiKey,
  }

  if (noCache) {
    headers['Cache-Control'] = 'no-cache'
    headers.Pragma = 'no-cache'
  }

  const axiosClient = axios.create({
    baseURL: url,
    headers,
  })
  const idToken = localStorage.getItem(ID_TOKEN)
  const accessToken = localStorage.getItem(ACCESS_TOKEN)

  if (idToken && accessToken) {
    axiosClient.defaults.headers.Identification = `Bearer ${idToken}`
    axiosClient.defaults.headers.Authorization = `Bearer ${accessToken}`
  }

  return axiosClient
}

export default client
