import get from 'lodash/get'

export const initialState = {
  firmProductSubscription: null,
  // TODO: call sylius to get availables departments
  departments: [
    { code: '75', name: 'Paris' },
    { code: '78', name: 'Yvelines' },
    { code: '92', name: 'Hauts de Seine' },
  ],
  packageList: [
    {
      title: "Installation d'un ballon d'eau chaude",
      packageCode: 'HOT_WATER_TANK_REPLACEMENT',
    },
  ],
  subscribePackageList: [],
}

export const getFirmProductSubscription = state =>
  get(state, 'firmProductSubscription')
export const getPackageList = state => get(state, 'packageList')
export const getSubscribePackageList = state =>
  get(state, 'subscribePackageList')
export const getDepartments = state => get(state, 'departments')
