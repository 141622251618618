import { cloudinary } from 'config'

export default (path, params, size) => {
  if (!params) {
    const isSvg = path.endsWith('.svg')
    const qualitySettings = isSvg ? '' : '/f_auto,q_auto'
    const paramsSize = !size ? '' : `/w_${size.width},h_${size.height},c_scale`
    return `${cloudinary.url}${qualitySettings}${paramsSize}/${cloudinary.app}${path}`
  }
  const cloudinaryParts = path.split('/')
  let cloudinaryParams = cloudinaryParts[6]
  const isSignature = part => /^s--.*--$/.test(part)
  if (isSignature(cloudinaryParams)) {
    cloudinaryParams = cloudinaryParts[7]
  }
  const arr = path.split(cloudinaryParams)

  return `${arr[0]}${params}${arr[1]}`
}
