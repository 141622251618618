import { google } from 'config'
import requireExternalLibrary from '../requireExternalLibrary'
import {
  getCountryFromLocale,
  getLangFromLocale,
  getLocaleFromHostname,
} from '../locale'

export default async () => {
  const locale = getLocaleFromHostname(window.location.hostname)
  const lang = getLangFromLocale(locale)
  const country = getCountryFromLocale(locale)
  const params = [
    `key=${google.mapsKey}`,
    `language=${lang}`,
    `region=${country}`,
    'libraries=places',
  ]
  return requireExternalLibrary(
    `https://maps.googleapis.com/maps/api/js?${params.join('&')}`,
  )
}
