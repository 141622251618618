/* eslint-disable no-param-reassign */
import axios from 'axios'
import { googleVision } from 'config'

const isImageSafe = image =>
  axios
    .post(
      `${googleVision.url}?key=${googleVision.apiKey}`,
      JSON.stringify({
        requests: [
          {
            image: {
              content: image,
            },
            features: [
              {
                type: 'SAFE_SEARCH_DETECTION',
              },
            ],
          },
        ],
      }),
      {
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization
            delete headers.Identification
            headers.post['Content-Type'] = 'application/json'

            return data
          },
        ],
      },
    )
    .then(response => {
      const { safeSearchAnnotation } = response.data.responses[0]
      return !Object.keys(safeSearchAnnotation)
        // Tests exceeding a value of the threshold will consider the image as offensive
        .some(
          index =>
            googleVision.likelihood[safeSearchAnnotation[index]] >=
            googleVision.threshold[index],
        )
    })
    .catch(error => Promise.reject(error))

export default isImageSafe
