import {
  generateSimpleAction,
  generateRequestActions,
  createRequestTypes,
} from 'utils/actionHelpers'

export const AUTH_LOGIN = createRequestTypes('AUTH_LOGIN')
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_SET_LOGGED_IN = 'AUTH_SET_LOGGED_IN'
export const AUTH_SET_APP_OPERATIONAL = 'AUTH_SET_APP_OPERATIONAL'
export const NO_JOB_REDIRECT = 'NO_JOB_REDIRECT'

export const authLogin = generateRequestActions(AUTH_LOGIN)

export const authLogout = generateSimpleAction(AUTH_LOGOUT)
export const setLoggedIn = generateSimpleAction(AUTH_SET_LOGGED_IN)
export const setAppOperational = generateSimpleAction(AUTH_SET_APP_OPERATIONAL)
export const noJobRedirect = generateSimpleAction(NO_JOB_REDIRECT)

export const SET_AUTH_TOKENS = 'SET_AUTH_TOKENS'
export const doSetAuthTokens = generateSimpleAction(SET_AUTH_TOKENS)

export const SET_REFRESH_TOKENS_AT = 'SET_REFRESH_TOKENS_AT'
export const doSetRefreshTokensAt = generateSimpleAction(SET_REFRESH_TOKENS_AT)

export const SET_REFRESH_TOKEN_REQUEST_LOCKED =
  'SET_REFRESH_TOKEN_REQUEST_LOCKED'
export const doSetRefreshTokenRequestLocked = generateSimpleAction(
  SET_REFRESH_TOKEN_REQUEST_LOCKED,
)

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const REFRESH_TOKEN_REQ = createRequestTypes(REFRESH_TOKEN)
export const getRefreshTokenReq = generateRequestActions(REFRESH_TOKEN_REQ)
