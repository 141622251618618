/* eslint-disable no-nested-ternary */
import { groupBy, pick, partition, findIndex } from 'lodash'
import cloudinary from 'utils/cloudinary'

import {
  JOB_PERCEIVED_STATUSES,
  STATUS_PENDING_REALIZATION,
  STATUS_PENDING_NEW_DATE,
  STATUS_PENDING_RESCHEDULING,
  STATUS_JOB_IN_PROGRESS,
  RECEIPT_SIGNATURE_STATE_BLOCKED,
  VIRTUAL_STATUS_BLOCKED,
} from 'constants/job'
import { createSelector } from 'reselect'

import lastIdFromIRI from 'utils/lastIdFromIRI'

const initJobsCountsReducer = (jobsCounts, statusName) => ({
  ...jobsCounts,
  [statusName]: 0,
})

export const initialState = {
  jobsByIri: null,
  allJobsIsLoading: false,
  jobsCounts: JOB_PERCEIVED_STATUSES.reduce(initJobsCountsReducer, {}),
  currentJobId: null,
  checkConditions: [],
  proPhoneNumber: null,
  cdpHelpCenterPosts: {
    byProductCode: {},
  },
}

export const getJobsByIri = state => state.jobsByIri
export const getJobsByStatus = (state, statuses) => {
  const list = pick(groupBy(state.jobsByIri, 'status'), statuses)
  const result =
    STATUS_PENDING_REALIZATION in list ||
    STATUS_PENDING_RESCHEDULING in list ||
    STATUS_PENDING_NEW_DATE in list
      ? {
          pending_realization: [
            ...(list[STATUS_PENDING_REALIZATION] ?? []),
            ...(list[STATUS_PENDING_RESCHEDULING] ?? []),
            ...(list[STATUS_PENDING_NEW_DATE] ?? []),
          ],
        }
      : list

  if (
    STATUS_JOB_IN_PROGRESS in result &&
    findIndex(result[STATUS_JOB_IN_PROGRESS], [
      'receiptSignatureStatus',
      RECEIPT_SIGNATURE_STATE_BLOCKED,
    ]) !== -1
  ) {
    ;[result[VIRTUAL_STATUS_BLOCKED], result[STATUS_JOB_IN_PROGRESS]] =
      partition(
        result[STATUS_JOB_IN_PROGRESS],
        job => job.receiptSignatureStatus === RECEIPT_SIGNATURE_STATE_BLOCKED,
      )
  }

  return result
}

export const getJob = (state, iri) =>
  state.jobsByIri && iri ? state.jobsByIri[iri] : null
export const getAllJobsIsLoading = state => state.allJobsIsLoading
export const getJobsCountByStatuses = (state, statuses) =>
  Object.keys(state.jobsCounts)
    .filter(key => statuses.includes(key))
    .reduce((output, key) => {
      // eslint-disable-next-line no-param-reassign
      output[key] = state.jobsCounts[key]
      return output
    }, {})
export const getCurrentJobId = state => state.currentJobId
export const getCurrentJob = state => getJob(state, getCurrentJobId(state))
export const getReceiptCurrentJob = state =>
  getCurrentJob(state) ? getCurrentJob(state).receipt : {}
export const getInvoiceCurrentJob = state =>
  getCurrentJob(state) ? getCurrentJob(state).invoice : {}
export const getReceiptSignatureCurrentJob = state =>
  getCurrentJob(state) ? getCurrentJob(state).receiptSignature : null
export const getPartnerCurrentJob = state =>
  getCurrentJob(state) ? getCurrentJob(state)?.worksite?.partner : null
export const getPartnerIdCurrentJob = state =>
  getPartnerCurrentJob(state) ? getPartnerCurrentJob(state)?.id : null
export const getPartnerLabelCurrentJob = state =>
  getPartnerCurrentJob(state) ? getPartnerCurrentJob(state)?.label : null
export const getPartnerIconCurrentJob = state => {
  const partnerId = getPartnerIdCurrentJob(state)

  if (!partnerId) {
    return null
  }

  // put the file name corresponding to the partnerId: pastille-1.svg = LM, pastille-2.svg = light online, pastille-3.svg = boulanger, pastille-3.svg = weldom
  return cloudinary(`/vitrine/partners/pastille-${partnerId}.svg`)
}

export const selectCdpHelpCenterPostsForJob = createSelector(
  (state, jobIri) => getJob(state, jobIri),
  state => state.cdpHelpCenterPosts.byProductCode,
  (job, cdpHelpCenterPosts) =>
    job?.products.reduce((acc, product) => {
      const productId = lastIdFromIRI(product['@id'])
      if (productId in cdpHelpCenterPosts) {
        return [
          ...acc,
          {
            shortName: product.shortName,
            link: cdpHelpCenterPosts[productId].link,
          },
        ]
      }
      return acc
    }, []) || [],
)
