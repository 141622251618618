import keyBy from 'lodash/keyBy'

import { VIRTUAL_STATUSES } from 'constants/job'

import { initialState } from './selectors'
import {
  JOBS_REQ,
  SET_ALL_JOBS_IS_LOADING,
  SET_CURRENT_JOB_ID,
  GET_JOB,
  UPDATE_JOB,
  GET_RECEIPT_JOB,
  VALIDATE_RECEIPT,
  GET_RECEIPT_SIGNATURE,
  GET_INVOICE_JOB,
  TRANSITION_INVOICE_JOB,
  SET_CURRENT_GEOLOC_PRO,
  ESTIMATED_HOUR_MAP,
  GET_CHECKING_CONDITION_LIST,
  GET_PRO_PHONE_NUMBER,
  RESET_ALL_JOBS,
  GET_COUNTER_JOB,
  GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE_REQ,
} from './actions'

const sumReducer = (sum, n) => sum + n

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB.SUCCESS: {
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [action.payload['@id']]: action.payload,
        },
      }
    }
    case VALIDATE_RECEIPT.SUCCESS:
    case UPDATE_JOB.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
        },
      }

    case JOBS_REQ.SUCCESS: {
      const jobsByIri = keyBy(action.payload['hydra:member'], '@id')

      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          ...jobsByIri,
        },
      }
    }
    case GET_COUNTER_JOB.SUCCESS: {
      return {
        ...state,
        jobsCounts: action.payload,
      }
    }
    case RESET_ALL_JOBS:
      return {
        ...state,
        jobsByIri: null,
      }
    case SET_ALL_JOBS_IS_LOADING:
      return {
        ...state,
        allJobsIsLoading: action.payload,
      }
    case SET_CURRENT_JOB_ID:
      return {
        ...state,
        currentJobId: action.payload,
      }
    case TRANSITION_INVOICE_JOB.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [state.currentJobId]: {
            ...state.jobsByIri[state.currentJobId],
            invoiceStatus: action.payload.state,
          },
        },
      }
    case GET_RECEIPT_JOB.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [state.currentJobId]: {
            ...state.jobsByIri[state.currentJobId],
            receipt: action.payload,
          },
        },
      }
    case GET_RECEIPT_SIGNATURE.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [state.currentJobId]: {
            ...state.jobsByIri[state.currentJobId],
            receiptSignatureStatus: action.payload.status,
            receiptSignature: action.payload,
          },
        },
      }
    case GET_INVOICE_JOB.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [state.currentJobId]: {
            ...state.jobsByIri[state.currentJobId],
            invoice: action.payload,
          },
        },
      }
    case SET_CURRENT_GEOLOC_PRO:
      return {
        ...state,
        geolocPro: { ...action.payload },
      }
    case ESTIMATED_HOUR_MAP:
      return {
        ...state,
        estimatedHourMap: action.payload,
      }
    case GET_CHECKING_CONDITION_LIST.SUCCESS:
      return {
        ...state,
        checkConditions: [...action.payload['hydra:member']],
      }
    case GET_PRO_PHONE_NUMBER.SUCCESS:
      return {
        ...state,
        proPhoneNumber: action.payload['hydra:member'][0].twilioPhoneNumber,
      }
    case GET_CDP_HELP_CENTER_POST_BY_PRODUCT_CODE_REQ.SUCCESS:
      return {
        ...state,
        cdpHelpCenterPosts: {
          ...state.cdpHelpCenterPosts,
          byProductCode: {
            ...state.cdpHelpCenterPosts.byProductCode,
            [action.actionParams.productCodeName]: {
              link: action.payload[0].link,
            },
          },
        },
      }
    default: {
      return state
    }
  }
}
