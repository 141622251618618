import {
  createRequestTypes,
  generateRequestActions,
  generateSimpleAction,
} from 'utils/actionHelpers'

export const OPEN_FILE = 'OPEN_FILE'
export const UPLOAD_DOCUMENT_FILE = createRequestTypes('UPLOAD_DOCUMENT_FILE')
export const SAVE_DOCUMENT_FILE = createRequestTypes('SAVE_DOCUMENT_FILE')
export const SAVE_DOCUMENT_FILE_ON_FIRM = createRequestTypes(
  'SAVE_DOCUMENT_FILE_ON_FIRM',
)
export const GET_FIRM_CERTIFICATES = createRequestTypes('GET_FIRM_CERTIFICATES')
export const SAVE_FIRM_CERTIFICATES = createRequestTypes(
  'SAVE_FIRM_CERTIFICATES',
)
export const uploadDocumentFile = generateRequestActions(UPLOAD_DOCUMENT_FILE)
export const saveDocumentFile = generateRequestActions(SAVE_DOCUMENT_FILE)
export const saveDocumentFileOnFirm = generateRequestActions(
  SAVE_DOCUMENT_FILE_ON_FIRM,
)
export const getFirmCertificates = generateRequestActions(GET_FIRM_CERTIFICATES)
export const saveFirmCertificates = generateRequestActions(
  SAVE_FIRM_CERTIFICATES,
)
export const openFile = generateSimpleAction(OPEN_FILE)
export const GET_FILE = createRequestTypes('GET_FILE')
export const getFile = generateRequestActions(GET_FILE)
