import { call } from 'redux-saga/effects'

const safeCall = (worker, errorCallback, ...args) =>
  call(function* () {
    try {
      const res = yield call(worker, ...args)
      return { res }
    } catch (err) {
      yield* errorCallback()
      return false
    }
  })

export default safeCall
