import styled, { css } from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { theme, ifThen, breakpoint } from 'utils/style'

export const styles = ({ button, large, center, secondary }) => css`
  text-decoration: none;
  color: ${theme('colors.primary')};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    text-decoration: none;
    color: ${theme('colors.hover.secondary')};
  }

  ${ifThen(
    button,
    css`
      display: block;
      margin: ${theme('spaces.m')} 0 0 0;
      padding: ${theme('spaces.m')};
      min-width: 20rem;
      width: 100%;
      font-weight: bold;
      font-size: ${theme('fonts.size.base')};
      letter-spacing: 0.05rem;
      text-align: center;
      background-color: ${theme('colors.secondary')};
      border: 0;
      color: ${theme('colors.black')};
      outline: 0;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: ${theme('colors.hover.primary')};
        color: ${theme('colors.black')};
      }

      ${breakpoint('m')`
      margin-bottom: ${theme('spaces.l')};
      margin-top: ${theme('spaces.l')};
      max-width: 32rem;
    `}

      ${ifThen(
        center,
        css`
          margin-left: auto;
          margin-right: auto;
        `,
      )};

      ${ifThen(
        secondary,
        css`
          background-color: ${theme('colors.button.secondary')};
          color: ${theme('colors.white')};

          &:hover {
            background-color: ${theme('colors.hover.secondary')};
            color: ${theme('colors.white')};
          }
        `,
      )};

      ${ifThen(
        large,
        css`
          ${breakpoint('m')`
          padding: ${theme('spaces.l')};
        `}
        `,
      )}
    `,
  )};
`

export const NoAnchor = styled.span`
  ${styles};
`

export const Anchor = styled.a`
  ${styles};
`

export const Hash = styled(HashLink)`
  ${styles};
`
