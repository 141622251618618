import { TRADES_LIST } from './actions'
import { initialState } from './selectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case TRADES_LIST.SUCCESS:
      return {
        ...state,
        list: action.payload['hydra:member'],
      }
    default: {
      return state
    }
  }
}
