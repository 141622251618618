import {
  generateRequestActions,
  createRequestTypes,
  generateSimpleAction,
} from 'utils/actionHelpers'

export const CLOUDINARY_UPLOAD = createRequestTypes('CLOUDINARY_UPLOAD')
export const CLOUDINARY_UPLOAD_START = 'CLOUDINARY_UPLOAD_START'
export const CLOUDINARY_CURRENT_ACTION_INFOS = 'CLOUDINARY_CURRENT_ACTION_INFOS'
export const CLOUDINARY_UPLOAD_MULTIPLE_FILE = 'CLOUDINARY_UPLOAD_MULTIPLE_FILE'
export const CLOUDINARY_CLEAN_STORE = 'CLOUDINARY_CLEAN_STORE'
export const CLOUDINARY_CHANGE_IS_UPLOADING = 'CLOUDINARY_CHANGE_IS_UPLOADING'

export const cloudinaryUploadStart = generateSimpleAction(
  CLOUDINARY_UPLOAD_START,
)
export const cloudinaryUpload = generateRequestActions(CLOUDINARY_UPLOAD)
export const cloudinaryCurrentActionInfos = generateSimpleAction(
  CLOUDINARY_CURRENT_ACTION_INFOS,
)
export const cloudinaryUploadMultipleFile = generateSimpleAction(
  CLOUDINARY_UPLOAD_MULTIPLE_FILE,
)
export const cloudinaryCleanStore = generateSimpleAction(CLOUDINARY_CLEAN_STORE)
export const cloudinaryChangeIsUploading = generateSimpleAction(
  CLOUDINARY_CHANGE_IS_UPLOADING,
)
