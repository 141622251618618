import { parse, format, isBefore, isAfter, isValid, parseISO } from 'date-fns'
import moment from 'moment-timezone'
import omit from 'lodash/omit'
import startCase from 'lodash/startCase'

import { DATE_FORMAT } from 'constants/dateFormat'
import { fr } from 'date-fns/locale'

/** These values are extracted from
 *  https://github.com/date-fns/date-fns/blob/v2.0.0-alpha.11/src/locale/es/_lib/localize/index.js
 *  We need to do that in order to fix issues on the locale es on the date-fns@v2.0.0-alpha.11
 *  See https://github.com/date-fns/date-fns/blob/v2.0.0-alpha.11/src/locale/es/index.js
 */
const weekdayValuesES = [
  'domingo',
  'lunes',
  'martes',
  'miércoles',
  'jueves',
  'viernes',
  'sábado',
]
const monthValuesES = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
]

const DATE_FNS_LOCALES = {
  fr,
  es: 'fakeDateFnsESLocale',
}

/**
 * @param language {string} 'fr'|'es'...
 * @returns {Object} { locale: dateFnsLocale }|{}
 */
export const getDateFnsLocale = language => {
  const dateFnsLocale = DATE_FNS_LOCALES[language]

  return (dateFnsLocale && { locale: dateFnsLocale }) || {}
}

/**
 * @param dateString {string}
 * @returns {Object} {new Date()}
 */
export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString)

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

/**
 * @param date {string} '05/04/2018'
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {date}
 */
export const createDate = (date, dateFormat = DATE_FORMAT.FR_LONG) =>
  parse(date, dateFormat, new Date())

/**
 * @param value {string} '05/04/2018'
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {boolean}
 */
export const isBeforeFormattedDate = (value, dateFormat) => {
  const date = dateFormat ? createDate(value, dateFormat) : value

  return isBefore(date, new Date())
}

/**
 * @param value {string} '05/04/2018'
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {boolean}
 */
export const isAfterFormattedDate = (value, dateFormat) => {
  const date = dateFormat ? createDate(value, dateFormat) : value

  return isAfter(date, new Date())
}

/**
 * @param value {string} 'Fri Feb 09 2018 01:00:00'
 * @param formatTo {string} 'dd/MM/yyyy'
 * @param formatFrom {string} 'yyyy-MM-dd'
 * @param formatOptions {Object} {locale: dateFnsLocale, ...}
 * @returns {string}
 */
export const transformDate = (
  value,
  formatTo = DATE_FORMAT.FR_LONG,
  formatFrom,
  formatOptions = {},
) => {
  const date = formatFrom ? createDate(value, formatFrom) : value

  const isValidDate = isValid(date)
  if (!isValidDate) {
    return false
  }

  if (formatOptions.startCase && formatOptions.locale) {
    let formatToFixed = formatTo
    let formatOptionsFixed = formatOptions
    if (formatOptions.locale === 'fakeDateFnsESLocale') {
      formatToFixed = formatTo
        .replace('EEEE', `'${weekdayValuesES[format(date, 'c') - 1]}'`)
        .replace('LLLL', `'${monthValuesES[format(date, 'L') - 1]}'`)

      formatOptionsFixed = omit(formatOptions, 'locale')
    }

    return startCase(format(date, formatToFixed, formatOptionsFixed))
  }

  return format(date, formatTo)
}

/**
 * @param value {string} example 16/12/2015
 * @param regex
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {boolean}
 */
export const isValidDate = (value, regex, dateFormat) => {
  if (!regex.test(value)) {
    return false
  }
  const date = dateFormat ? createDate(value) : value

  return isValid(date)
}

/**
 * @param isoDateString {string} example 2019-07-27T00:00:00+00:00
 * @returns {boolean}
 */
export const compareIsoDate = isoDateString => {
  const dateStart = new Date()
  const dateEnd = new Date(isoDateString)

  return dateStart.getTime() > dateEnd.getTime()
}

/**
 * @param isoDateString {string} example 2019-07-27T00:00:00+00:00
 * @returns {date}
 */
export const formatDateInStartCase = (date, language) => {
  const dateFnsLocale = getDateFnsLocale(language)

  const formattingOptions = {
    ...dateFnsLocale,
    startCase: true,
  }

  return transformDate(
    date,
    DATE_FORMAT.DAY_OF_WEEK_MONTH_YEAR,
    DATE_FORMAT.ISO_INITIAL_TIMEZONE,
    formattingOptions,
  )
}

/**
 * @param {string} date - must contain a substring with the 'YYYY-MM-DDTHH:mm:ss' format
 * @param {string} [countryCode='FR'] - @link the TIMEZONES'keys constant upper
 *
 * @returns {Object}
 */
export const getDateTz = (date, countryCode = 'FR') =>
  moment(`${date.split('+')[0]}Z`).tz(DATE_FORMAT.TIMEZONE[countryCode])

/**
 * @param date {string} example 2019-07-27T00:00:00+00:00
 * @param countryCode {string} example FR
 * @returns {date}
 */
export const getTimeFromDate = (date, countryCode = 'FR') =>
  getDateTz(date, countryCode).format(DATE_FORMAT.TIME_ONLY)

/**
 * @param date {string} example 2019-07-27T00:00:00+00:00
 * @param countryCode {string} example FR
 * @returns {boolean}
 */
export const isMorning = (date, countryCode = 'FR') =>
  getDateTz(date, countryCode).format('H') <= 12

/**
 * @param date {string} example 2019-07-27T00:00:00+00:00
 * @param countryCode {string} example FR
 * @returns {string}
 */
export const getHalfDayKey = (date, countryCode = 'FR') =>
  isMorning(date, countryCode) ? 'morning' : 'afternoon'

/**
 * @param start {string} example 2019-07-27T00:00:00+00:00
 * @param end {string} example 2019-07-27T00:00:00+00:00 or null
 * @returns {number}
 */
export const dateDiffInDays = (start, end = null) => {
  const startDate = new Date(start)
  const endDate = end ? new Date(end) : new Date()
  const diffTime = Math.abs(endDate - startDate)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export const dateFormatter = date =>
  typeof date === 'string' ? parseISO(date) : date

/**
 * @param rangeDates {object} example { startA: 2019-07-27T00:00:00+00:00 ...}
 * @returns {bool}
 */
export const isTwoRangesDatesOverlap = ({ startA, endA, startB, endB }) =>
  startA <= endB && startB <= endA

export const extractDateFromString = date => {
  let day
  let month
  let year
  let dateSplitted
  let aux

  let result = date.match('[0-9]{2}([-/ .])[0-9]{2}[-/ .][0-9]{4}')
  if (result !== null) {
    dateSplitted = result[0].split(result[1])
    day = dateSplitted[0]
    month = dateSplitted[1]
    year = dateSplitted[2]
  }

  result = date.match('[0-9]{4}([-/ .])[0-9]{2}[-/ .][0-9]{2}')
  if (result !== null) {
    dateSplitted = result[0].split(result[1])
    day = dateSplitted[2]
    month = dateSplitted[1]
    year = dateSplitted[0]
  }

  if (month > 12) {
    aux = day
    day = month
    month = aux
  }

  return `${day}/${month}/${year}`
}
