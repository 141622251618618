import proLegacyUrl from 'utils/proLegacyUrl'
import {
  contractOptionPush,
  contractOptionPick,
  contractOptionPackage,
} from 'constants/contractOptions'
import allProTabsCases from 'constants/allProTabsCases'
import { CANDIDATE_STATUS_PACKAGE } from 'constants/packageStatus'
import { URLS } from 'constants/urls'
import { PACKAGE_STATUS_CANDIDATE } from 'constants/firm'

const filterTabs = (
  allTabs,
  optionNames,
  packageStatus,
  featureFlagged = [],
) => {
  let allowedTabs = [...allProTabsCases.default]

  if (optionNames.includes(contractOptionPackage)) {
    allowedTabs = [...allowedTabs, ...allProTabsCases.contractOptionPackage]
  } else if (packageStatus === CANDIDATE_STATUS_PACKAGE) {
    allowedTabs = [...allowedTabs, ...allProTabsCases.packageStatusCandidate]
  }

  if (
    optionNames.includes(contractOptionPush) ||
    optionNames.includes(contractOptionPick)
  ) {
    allowedTabs = [...allowedTabs, ...allProTabsCases.contractOptionLegacy]
  }

  allowedTabs = allowedTabs.filter(title => title !== 'pro.nav.my_documents')

  const filteredTabs = Object.keys(allTabs)
    .filter(key => {
      const ff = featureFlagged.find(ff => ff.key === key)
      if (ff && !ff.value) return false
      return allowedTabs.includes(key)
    })
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: allTabs[key],
      }),
      {},
    )

  return filteredTabs
}

export const getUrlCandidatePackage = (
  contractSignatureStatus,
  isFirmConfigured,
  path = '',
) => {
  if (contractSignatureStatus === 'finished') {
    if (!isFirmConfigured) {
      return URLS.ROUTES.CANDIDATE_PACKAGE_CONFIGURATION
    }

    return URLS.ROUTES.CANDIDATE_PACKAGE_STUDY
  }

  return URLS.ROUTES.CANDIDATE_PACKAGE
}

const proTabs = (locale, firmDetails, language, featureFlagged = []) => {
  if (!firmDetails || !firmDetails.contractOptions) {
    return {}
  }

  const proUrl = path => ({
    to: proLegacyUrl(locale, path),
    forceRedirect: true,
  })

  const optionNames = firmDetails.contractOptions.map(option => option.name)

  const ratingTab = ['fr', 'es'].includes(language)
    ? { to: '/my-account/notation' }
    : proUrl('rating')

  const allTabs = {
    'pro.nav.my_page': {
      to: '/my-account/page',
    },
    'pro.nav.my_rating': ratingTab,
    'pro.nav.my_documents': {
      to: '/my-account/documents',
    },
    'pro.nav.my_options': proUrl('options'),
    'pro.nav.my_account': {
      to: '/my-account/account/informations',
    },
    'pro.nav.my_projects': proUrl('projects'),
    'pro.nav.my_packages': {
      to: '/my-account/packages',
    },
    'pro.nav.package_candidate': {
      to: URLS.ROUTES.CANDIDATE_PACKAGE,
    },
    'pro.nav.help_and_contact': proUrl('help'),
  }

  return filterTabs(
    allTabs,
    optionNames,
    firmDetails.packageStatus,
    featureFlagged,
  )
}

export default proTabs
