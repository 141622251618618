import get from 'lodash/get'

export const initialState = {
  '@id': null,
  email: null,
  facebookId: '',
  googleId: '',
  gender: '',
  firstName: '',
  lastName: '',
  address: '',
  postalCode: '',
  city: '',
  region: '',
  countryCode: '',
  mobilePhone: '',
  fixedPhone: '',
  birthday: '',
  imageUrl: '',
  notification: null,
  preferedLanguage: '',
  newsletterSubscriptionQuotatis: false,
  newsletterSubscriptionPartner: false,
  mobilePhoneVerified: null,
  emailVerified: null,
  contactPreference: null,
  contactComment: null,
}

export const getDetails = (state = initialState) => state
export const getId = (state = initialState) => get(state, '["@id"]')
export const getFirstName = (state = initialState) => get(state, 'firstName')
export const getEmail = (state = initialState) => get(state, 'email')
export const getMobilePhoneVerified = (state = initialState) =>
  get(state, 'mobilePhoneVerified')
export const getEmailVerified = (state = initialState) =>
  get(state, 'emailVerified')
export const getMobilePhone = (state = initialState) =>
  get(state, 'mobilePhone')
export const getLastName = (state = initialState) => get(state, 'lastName')
export const getGender = (state = initialState) => get(state, 'gender')
export const getContactPreference = (state = initialState) =>
  get(state, 'contactPreference')
export const getContactComment = (state = initialState) =>
  get(state, 'contactComment')
