import { all, put, select } from 'redux-saga/effects'
import { change } from 'redux-form'
import sha1 from 'js-sha1'
import { readAsDataURL } from 'promise-file-reader'

import { fromContext, fromFirm, fromPro, fromCloudinary } from 'store/selectors'
import { takeLatest, takeEvery } from 'utils/effects'
import cloudinaryClient from 'services/httpClient/cloudinaryClient'
import isImageSafe from 'services/googleVision'
import { isImgValidByConfig } from 'services/validation'
import handleRequest from 'sagas/handleRequest'
import { cloudinary as cloudinaryConfig } from 'config'
import { IMG_FIRM_PICTURE_CFG } from 'constants/cloudinary'
import notify from 'sagas/notify'
import safeCall from 'utils/safeCall'
import { getConfigSizeImg } from 'constants/pictures'
import {
  CLOUDINARY_UPLOAD,
  CLOUDINARY_UPLOAD_START,
  cloudinaryUpload,
  cloudinaryCurrentActionInfos,
  CLOUDINARY_UPLOAD_MULTIPLE_FILE,
  cloudinaryChangeIsUploading,
} from './actions'

const buildUploadParamsAsString = paramsAsObj => {
  let params = ''
  Object.keys(paramsAsObj)
    .sort()
    .forEach(paramName => {
      params += `&${paramName}=${paramsAsObj[paramName]}`
    })

  return params.substr(1)
}

const createSignature = (paramsAsString, apiSecret) => {
  const hash = sha1.create()
  hash.update(paramsAsString + apiSecret)
  return hash.hex()
}

const createFormData = (paramsAsObj, file) => {
  const formData = new FormData()

  Object.keys(paramsAsObj).forEach(paramName => {
    formData.append(paramName, paramsAsObj[paramName])
  })

  formData.append('api_key', cloudinaryConfig.apiKey)
  formData.append('file', file)

  return formData
}

const generateUrl = (public_id, format) =>
  `${cloudinaryConfig.url}/q_auto/${public_id}.${format}`

export function* handleUploadFileSuccess({ payload, actionParams }) {
  if (actionParams.fieldName && actionParams.formName) {
    yield put(cloudinaryCurrentActionInfos('cloudinary.upload.success.message'))
    yield put(
      change(
        actionParams.formName,
        actionParams.fieldName,
        generateUrl(payload.public_id, payload.format),
      ),
    )
  }
}

export function* handleUploadFileFailure({ error, actionParams }) {
  if (actionParams.uploadConfig !== IMG_FIRM_PICTURE_CFG) {
    const errorMessage = error || 'cloudinary.upload.failure.message'
    yield put(cloudinaryCurrentActionInfos(errorMessage))
  }
}

export function* handleUploadFileRequest({
  file,
  uploadConfig,
  ...otherProps
}) {
  if (uploadConfig !== IMG_FIRM_PICTURE_CFG) {
    yield put(
      cloudinaryCurrentActionInfos('cloudinary.upload.in_progress.message'),
    )
  }
  const countryCode = yield select(fromContext.getCountry)
  const firmProId = yield select(fromPro.getFirmId)
  const firmDetails = yield select(fromFirm.getDetails, firmProId)
  const firmLegacyId = yield select(fromFirm.getLegacyId, firmProId)
  const folder = `${countryCode}/FirmImages/${firmLegacyId || firmDetails.id}/`

  const paramsAsObj = {
    timestamp: Math.round(new Date().getTime() / 1000),
    folder,
  }

  const formData = createFormData(paramsAsObj, file)
  const paramsAsString = buildUploadParamsAsString(paramsAsObj)
  const signature = createSignature(paramsAsString, cloudinaryConfig.apiSecret)

  formData.append('signature', signature)

  if (uploadConfig === IMG_FIRM_PICTURE_CFG) {
    yield* handleRequest({
      requestActions: cloudinaryUpload,
      promise: cloudinaryClient.post('/auto/upload', formData),
      actionParams: {
        ...otherProps,
        uploadConfig,
        fileName: otherProps.prevImgName,
      },
      checkTokens: false,
    })
  } else {
    yield* handleRequest({
      requestActions: cloudinaryUpload,
      promise: cloudinaryClient.post('/auto/upload', formData),
      actionParams: {
        ...otherProps,
        uploadConfig,
      },
      checkTokens: false,
    })
  }
}

const URLHelper = window.URL || window.webkitURL

const getImgFromFile = file =>
  new Promise(resolve => {
    const img = new Image()
    img.src = URLHelper.createObjectURL(file)

    img.onload = () => resolve(img)
  })

const validateFileImg = (file, fieldname) =>
  getImgFromFile(file).then(img => isImgValidByConfig(img, file, fieldname))

export function* handleUploadFile({ file, firmSlug, ...otherProps }) {
  yield put(
    cloudinaryCurrentActionInfos(
      'firm.form.pictures.popin.validation_in_progress',
    ),
  )

  const fieldname = otherProps.fieldName
  const isImgValid = yield validateFileImg(file, fieldname)

  if (!isImgValid) {
    const configSizeImg = getConfigSizeImg(fieldname)

    yield put(cloudinaryUpload.failure(configSizeImg.errorMessage, otherProps))
    return
  }

  const result = yield readAsDataURL(file)
  const base64result = result.split(',')[1]
  try {
    const response = yield isImageSafe(base64result)
    if (!response) {
      yield put(
        cloudinaryUpload.failure(
          'cloudinary.upload.moderation.failed',
          otherProps,
        ),
      )
      return
    }
  } catch (e) {
    yield put(cloudinaryUpload.failure(null, otherProps))
    return
  }

  yield handleUploadFileRequest({ file, ...otherProps })
}

export function* handleUploadMultipleFile({
  filesToUpload,
  uploadConfig,
  onSuccessfulUpload,
  ...otherProps
}) {
  if (uploadConfig === IMG_FIRM_PICTURE_CFG) {
    yield put(cloudinaryChangeIsUploading(true))
    yield put(
      cloudinaryCurrentActionInfos(
        'firm.form.pictures.popin.upload_in_progress',
      ),
    )
    yield all([
      ...filesToUpload.map(popInFirmPicture => {
        const { file } = popInFirmPicture

        return safeCall(
          handleUploadFileRequest,
          () =>
            notify(
              '',
              'pro.image.not_uploaded',
              'error',
              {},
              { imageName: file.name },
            ),
          {
            file,
            uploadConfig,
            prevImgName: file.name,
            position: popInFirmPicture.position,
            description: popInFirmPicture.description,
            ...otherProps,
          },
        )
      }),
    ])
    const uploadedFiles = yield select(
      fromCloudinary.getUploadedFiles,
      IMG_FIRM_PICTURE_CFG,
    )

    const regexEscapedCloudinaryUrl = cloudinaryConfig.url.replace(/\//g, '\\/')
    const regexCloudinaryUrl = new RegExp(`(${regexEscapedCloudinaryUrl})`)

    if (uploadedFiles) {
      Object.keys(uploadedFiles).forEach(fileName => {
        const data = {
          position: uploadedFiles[fileName].others.position,
          url: uploadedFiles[fileName].url.replace(
            regexCloudinaryUrl,
            '$1/q_auto',
          ),
        }
        if (uploadedFiles[fileName].others.description) {
          data.description = uploadedFiles[fileName].others.description
        }

        onSuccessfulUpload(data)
      })
    }

    yield put(cloudinaryChangeIsUploading(false))
  }
}

export default function* () {
  yield all([
    takeEvery(CLOUDINARY_UPLOAD.SUCCESS, handleUploadFileSuccess),
    takeEvery(CLOUDINARY_UPLOAD.FAILURE, handleUploadFileFailure),
    takeLatest(CLOUDINARY_UPLOAD_START, handleUploadFile),
    takeLatest(CLOUDINARY_UPLOAD_MULTIPLE_FILE, handleUploadMultipleFile),
  ])
}
