import notify from 'sagas/notify'
import { takeLatest } from 'utils/effects'
import handleRequest from 'sagas/handleRequest'
import client from 'services/httpClient/commonClient'
import { all, call } from 'redux-saga/effects'

import {
  HOME_PAGE_CONTACT_SUBMIT_REQ,
  homePageContactSubmit,
} from 'store/actions'
import { ENGINE } from 'constants/services'

export function* handleHomePageContact(data) {
  const phone = data.phone || null
  try {
    yield handleRequest({
      requestActions: homePageContactSubmit,
      promise: call(client(ENGINE).post, `/engine/contacts`, {
        type: data.contactType,
        firstName: data.firstName,
        lastName: data.lastName,
        companyName:
          data.contactType === 'interested_partner' ? data.companyName : null,
        function:
          data.contactType === 'interested_partner' ? data.function : null,
        phone,
        email: data.email,
        message: data.message,
        captcha: data.captcha,
      }),
    })
  } catch (e) {
    yield notify('', 'server_error', 'error')
  }
}

export default function* () {
  yield all([
    takeLatest(HOME_PAGE_CONTACT_SUBMIT_REQ.REQUEST, handleHomePageContact),
  ])
}
