import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getSpnFromCookie } from 'utils/spn'
import { ROLES } from 'constants/roles'

export const initialState = {
  redirecting: false,
  proFormRegistrationStep: 1,
  auth: {
    isLoggedIn: false,
    firmId: '',
    nickname: '',
    roles: [],
  },
  spn: getSpnFromCookie(),
  tabs: {},
}

export const isRedirecting = state => get(state, 'redirecting')
export const getSpn = state => get(state, 'spn')
export const getTabs = state => get(state, 'tabs')
export const getProFormRegistrationStep = state =>
  get(state, 'proFormRegistrationStep')
export const getRegistrationNumberData = state =>
  get(state, 'registrationNumberData')

const getAuth = state => get(state, 'auth')
const getRoles = createSelector(getAuth, auth => get(auth, 'roles'))
export const isLoggedIn = createSelector(getAuth, auth =>
  get(auth, 'isLoggedIn'),
)
export const getFirmId = createSelector(getAuth, auth => get(auth, 'firmId'))
export const getNickname = createSelector(getAuth, auth =>
  get(auth, 'nickname'),
)
export const getEmail = createSelector(getAuth, auth => get(auth, 'email'))
export const hasRoleAdmin = createSelector(getRoles, roles =>
  roles.some(role => role === ROLES.ROLE_ADMIN),
)

export const getPrefilledRegistration = state =>
  get(state, 'prefilledRegistration')

export const getPrefilledRegistrationCivility = state =>
  get(state, 'prefilledRegistration.civility')

export const getPrefilledRegistrationLastName = state =>
  get(state, 'prefilledRegistration.lastName')

export const getPrefilledRegistrationFirstName = state =>
  get(state, 'prefilledRegistration.firstName')

export const getPrefilledRegistrationCity = state =>
  get(state, 'prefilledRegistration.city')

export const getPrefilledRegistrationPostalCode = state =>
  get(state, 'prefilledRegistration.postalCode')

export const getPrefilledRegistrationLocalisation = state =>
  `${get(state, 'prefilledRegistration.postalCode')} ${get(
    state,
    'prefilledRegistration.city',
  )}`

export const getPrefilledRegistrationEmail = state =>
  get(state, 'prefilledRegistration.email')

export const getPrefilledRegistrationPassword = state =>
  get(state, 'prefilledRegistration.password')

export const getPrefilledRegistrationPhone = state =>
  get(state, 'prefilledRegistration.mobilePhoneNumber')

export const getPrefilledRegistrationRegistrationNumber = state =>
  get(state, 'prefilledRegistration.registrationNumber')
export const getPrefilledRegistrationFirmName = state =>
  get(state, 'prefilledRegistration.name')
