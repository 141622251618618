import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import loadIntercomMessenger from 'utils/loadIntercomMessenger'
import { intercom } from 'config'
import { fromPro } from 'store/selectors'

const IntercomService = ({ email = '' }) => {
  const intercomSettings = {
    app_id: intercom.appId,
  }

  useEffect(() => {
    const fetchData = async () => {
      await loadIntercomMessenger()
      const ic = window.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', { intercomSettings })

        if (email) {
          ic('boot', { ...intercomSettings, email })
        }
      }
    }

    fetchData().catch(console.error)
  }, [email])

  return null
}

IntercomService.propTypes = {
  email: PropTypes.string,
}

const mapStateToProps = state => ({
  email: fromPro.getEmail(state),
})

export default connect(mapStateToProps)(IntercomService)
