export const ADMINISTRATIVE_AREA_LEVEL_1 = {
  name: 'ADMINISTRATIVE_AREA_LEVEL_1',
  variants: ['administrative_area_level_1'],
}

export const ADMINISTRATIVE_AREA_LEVEL_2 = {
  name: 'ADMINISTRATIVE_AREA_LEVEL_2',
  variants: ['administrative_area_level_2'],
}

export const CITY = { name: 'LOCALITY', variants: ['locality', 'postal_town'] }

export const POSTAL_CODE = { name: 'POSTAL_CODE', variants: ['postal_code'] }

export const ROUTE = { name: 'ROUTE', variants: ['route'] }

export const STREET_NUMBER = {
  name: 'STREET_NUMBER',
  variants: ['street_number'],
}

export const GOOGLE_MAPS_COMPONENT_TYPES = [
  ADMINISTRATIVE_AREA_LEVEL_1,
  ADMINISTRATIVE_AREA_LEVEL_2,
  CITY,
  POSTAL_CODE,
  ROUTE,
  STREET_NUMBER,
]
