import get from 'lodash/get'
import { PACKAGES_INDEX_NAME, PRO_FORM_INDEX_NAME } from 'services/algolia'
import { CATEGORY_SEARCH } from 'constants/search'

export const initialState = {
  category: null,
  results: {
    [PACKAGES_INDEX_NAME]: { hits: [], nbHits: 0, searched: false },
    [PRO_FORM_INDEX_NAME]: { hits: [], nbHits: 0, searched: false },
  },
  query: '',
}

export const getPackagesResults = (state = initialState) =>
  get(state, `results.${PACKAGES_INDEX_NAME}`)
export const getProFormResults = (state = initialState) =>
  get(state, `results.${PRO_FORM_INDEX_NAME}`)

export const getAllResults = (state = initialState) => {
  const packages = getPackagesResults(state)
  const forms = getProFormResults(state)
  return {
    hits: [...packages.hits, ...forms.hits],
    nbHits: packages.nbHits + forms.nbHits,
    searched: packages.searched || forms.searched,
  }
}
export const getSearchCategory = (state = initialState) => state.category

export const categorySelector = (state = initialState) =>
  get(getProFormResults(state), 'category')

export const getQuery = (state = initialState) => state.query

export const isResultFromCategoryClickSelector = (state = initialState) =>
  get(getProFormResults(state), `resultFrom`) === CATEGORY_SEARCH
