import get from 'lodash/get'
import { createSelector } from 'reselect'
import { convertArrayToObject } from 'utils/convert'
import { contractOptionDirectory } from 'constants/contractOptions'
import {
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  FIRM_CONTRACT_OPTIONS,
  PACKAGE_STATUS_CANDIDATE,
  DEPARTMENTS_LIST,
} from 'constants/firm'
import { NO_SCORE } from 'constants/onboarding'
import { FIRM_STATUS_CAN_UPDATE_DOCUMENTS } from 'constants/documents'
import { PRO_FIELD_LAT, PRO_FIELD_LNG } from 'constants/pro'

export const initialState = {
  reviews: {},
  hashids: {},
  autoCompleteLocation: {},
  currentFirmId: null,
  firmValidationEmailStatus: null,
  firmFiles: {},
}

const firmContractOptions = convertArrayToObject(FIRM_CONTRACT_OPTIONS)

export const getDetails = (state, id) => get(state, id)

const getReviews = state => get(state, 'reviews')
const firmOfPro = state => get(state, 'firmOfPro')
export const getFirmFiles = state => get(state, 'firmFiles')
export const getFirmId = createSelector(getDetails, details =>
  get(details, '[@id]'),
)
export const getFirmFileDetails = createSelector(getDetails, details =>
  get(details, 'firmFiles'),
)
export const getLegacyId = createSelector(getDetails, details =>
  get(details, 'legacyId'),
)
export const getId = (state, hashid) => get(state, `hashids[${hashid}]`)
export const getFirmPlaceAutoCompleteLocation = state =>
  get(state, 'autoCompleteLocation')
export const getIsLoginRequest = state => get(state, 'isLoginRequest')
export const getFirmValidationEmailStatus = state =>
  get(state, 'firmValidationEmailStatus')
const getFirmContractOption = createSelector(getDetails, details =>
  get(details, 'contractOptions'),
)
export const getUsers = createSelector(getDetails, details =>
  get(details, 'users'),
)
export const getCountryCode = createSelector(getDetails, details =>
  get(details, 'countryCode'),
)
export const getUrl = createSelector(getDetails, details => get(details, 'url'))
export const getFirmLegalData = createSelector(getDetails, details =>
  get(details, 'firmLegalData'),
)
export const getFirmDocuments = createSelector(getDetails, details =>
  get(details, 'documents'),
)
export const getReviewById = createSelector(
  getReviews,
  (_, props) => props,
  (reviews, firmId) => get(reviews, `[${firmId}/reviews]`),
)
export const getFirmContractOptionList = createSelector(
  getFirmContractOption,
  contractOptions =>
    (contractOptions || []).length > 0
      ? contractOptions
          .map(option => firmContractOptions?.[option?.name]?.name)
          .join(' / ')
      : firmContractOptions?.NO_CONTRACT?.name,
)
export const getPackageStatusV2 = createSelector(getDetails, details =>
  get(details, 'packageStatusV2'),
)
export const canFirmUpdateDocuments = createSelector(
  getPackageStatusV2,
  packageV2 => {
    const hasStatusToUpdate = (FIRM_STATUS_CAN_UPDATE_DOCUMENTS || []).find(
      status => status === packageV2,
    )
    return !!hasStatusToUpdate
  },
)
export const getHasContractOption = createSelector(
  getFirmContractOption,
  contractOptions =>
    (contractOptions || []).some(
      contract => contract.name === contractOptionDirectory,
    ),
)
export const getAccountingNumber = createSelector(getDetails, details =>
  get(details, 'accountingNumber'),
)
export const getFirmTradesOfPro = createSelector(firmOfPro, proFirm =>
  get(proFirm, 'firmTrades'),
)
export const getGlobalRating = createSelector(firmOfPro, proFirm =>
  get(proFirm, 'globalRating'),
)
export const getGlobalRatingCount = createSelector(firmOfPro, proFirm =>
  get(proFirm, 'globalRatingCount'),
)
export const getAcceptedProProfilePath = createSelector(firmOfPro, proFirm =>
  get(proFirm, 'url'),
)
export const getAcceptedProEmail = createSelector(firmOfPro, proFirm =>
  get(proFirm, 'email'),
)
const getFirmUserByEmail = createSelector(
  getUsers,
  (_, ...props) => props,
  (users, email) => (users || []).find(user => user.email === email[1]),
)
export const getFirmGenderOwner = createSelector(
  getFirmUserByEmail,
  getUsers,
  (userByEmail, users) => {
    const authGender = get(userByEmail, 'gender')
    return authGender || users[0].gender
  },
)
export const getOwnerPosition = createSelector(
  getUsers,
  (_, ...props) => props,
  (users, emailAuth) =>
    users.indexOf(users.find(user => user.email === emailAuth[1])) || 0,
)
export const getFirmFirstNameOwner = createSelector(
  getFirmUserByEmail,
  getUsers,
  (userByEmail, users) => {
    const authFirstName = get(userByEmail, 'firstName')
    const firstUserFirstName =
      users && users.length > 0 ? users[0].firstName : ''
    return authFirstName || firstUserFirstName
  },
)
export const getFirmLastNameOwner = createSelector(
  getFirmUserByEmail,
  getUsers,
  (userByEmail, users) => {
    const authLastName = get(userByEmail, 'lastName')
    return authLastName || users[0].lastName
  },
)
export const getFirstNameLastNameByEmail = createSelector(
  getFirmFirstNameOwner,
  getFirmLastNameOwner,
  (firstName, lastName) => `${firstName} ${lastName}`,
)
export const getFirmEmailOwner = createSelector(
  getFirmUserByEmail,
  getUsers,
  (userByEmail, users) => {
    const authEmail = get(userByEmail, 'email')
    return authEmail || users[0].email
  },
)
export const getFirmFixedPhoneOwner = createSelector(
  getFirmUserByEmail,
  getUsers,
  (userByEmail, users) => {
    const authFixedPhone = get(userByEmail, 'fixedPhone')
    return authFixedPhone || users[0].fixedPhone
  },
)
export const getFirmMobilePhoneOwner = createSelector(
  getFirmUserByEmail,
  getUsers,
  (userByEmail, users) => {
    const authMobilePhone = get(userByEmail, 'mobilePhone')
    return authMobilePhone || users[0].mobilePhone
  },
)
export const getFirmFirstName = createSelector(
  getUsers,
  (_, ...props) => props,
  (users, userNumber) => get(users[userNumber], 'firstName'),
)
export const getFirmTrades = createSelector(getDetails, details =>
  get(details, 'firmTrades'),
)
export const getIsEmailValidated = createSelector(getDetails, details =>
  get(details, 'isEmailValidated'),
)
export const getFirmName = createSelector(
  getDetails,
  details => details && get(details, 'name'),
)
export const getContractSignatureStatus = createSelector(getDetails, details =>
  get(details, 'contractSignatureStatus'),
)
export const isContractSigned = createSelector(
  [getContractSignatureStatus, (state, firmId, type = 'package') => type],
  (contractSignatureStatus, type) => {
    if (type === 'package') {
      return contractSignatureStatus === 'finished'
    }
  },
)
export const getIBAN = createSelector(getFirmLegalData, legalData =>
  get(legalData, 'internationalBankAccountNumber'),
)
export const getVAT = createSelector(getFirmLegalData, legalData =>
  get(legalData, 'vatNumber'),
)
export const getSwiftCode = createSelector(getFirmLegalData, legalData =>
  get(legalData, 'swiftCode'),
)
export const getSubjectToVat = createSelector(getFirmLegalData, legalData =>
  get(legalData, 'subjectToVat'),
)
export const getIsFirmConfigured = createSelector(
  getIBAN,
  getSwiftCode,
  getVAT,
  (iban, swiftCode, vat) => iban && swiftCode && vat,
)
export const getHeadQuartersAddress = createSelector(
  getFirmLegalData,
  legalData => get(legalData, 'headQuartersAddress'),
)
export const getHeadQuartersPostalCode = createSelector(
  getFirmLegalData,
  legalData => get(legalData, 'headQuartersPostalCode'),
)
export const getHeadQuartersCity = createSelector(getFirmLegalData, legalData =>
  get(legalData, 'headQuartersCity'),
)
export const getLegalRepresentativeFirstName = createSelector(
  getFirmLegalData,
  legalData => get(legalData, 'legalRepresentativeFirstName'),
)
export const getLegalRepresentativeLastName = createSelector(
  getFirmLegalData,
  legalData => get(legalData, 'legalRepresentativeLastName'),
)
export const getLegalStatus = createSelector(getFirmLegalData, legalData =>
  get(legalData, 'legalStatus'),
)
export const getFirmType = createSelector(getFirmLegalData, legalData =>
  get(legalData, 'firmType'),
)
export const getCapitalAmount = createSelector(getFirmLegalData, legalData => {
  const capitalAmount = get(legalData, 'capitalAmount')
  return capitalAmount ? capitalAmount.toString() : undefined
})
export const getRegistrationCity = createSelector(getFirmLegalData, legalData =>
  get(legalData, 'registrationCity'),
)
export const getRegistrationNumber = createSelector(getDetails, details =>
  get(details, 'registrationNumber'),
)
export const getNewsletterPartnerOptin = createSelector(getDetails, details =>
  get(details, 'newsletterSubscriptionPartners'),
)
export const getNewsletterQuotatisOptin = createSelector(getDetails, details =>
  get(details, 'newsletterSubscriptionQuotatis'),
)
export const getPackageStatus = createSelector(getDetails, details =>
  get(details, 'packageStatus'),
)
export const isFirmInProgressTestJobSelector = createSelector(
  getPackageStatusV2,
  packageV2 => PACKAGE_STATUS_IN_PROGRESS_TEST_JOB === packageV2,
)
export const isFirmPendingTestJobSelector = createSelector(
  getPackageStatusV2,
  packageV2 => PACKAGE_STATUS_PENDING_TEST_JOB === packageV2,
)

export const getFirmFilesByType = createSelector(
  getFirmFileDetails,
  getFirmFiles,
  (_, ...props) => props,
  (firmFileDetails, firmFiles, type) =>
    (firmFileDetails || [])
      .map(firmFileId => get(firmFiles, firmFileId) || firmFileId)
      .filter(firmFile => firmFile.type === type[1]),
)

export const getDisplayedInProDirectory = createSelector(getDetails, details =>
  get(details, 'displayedInProDirectory'),
)
const getOnboardingScore = createSelector(getDetails, details =>
  get(details, 'onboardingScore'),
)
export const getMandatoryDocumentsStatus = createSelector(getDetails, details =>
  get(details, 'mandatoryDocumentsStatus'),
)
export const getIsQuizzValidated = createSelector(
  getOnboardingScore,
  onboardingScore => NO_SCORE !== onboardingScore,
)

export const getFirmPoint = createSelector(getDetails, details => {
  const firmPoint = get(details, 'point')
  const points = {}
  if (firmPoint) {
    Object.keys(firmPoint).forEach(key => {
      if ([PRO_FIELD_LAT, PRO_FIELD_LNG].includes(key)) {
        points[key] = firmPoint[key]
      }
    })
  }
  return points
})
export const getFirmProductSubscriptionId = createSelector(
  getDetails,
  details => get(details, 'firmProductSubscription["@id"]'),
)
export const getFirmProductSubscriptionMatchingAddress = createSelector(
  getDetails,
  details => get(details, 'firmProductSubscription["matchingAddress"]'),
)
export const getFirmProductSubscriptionMatchingOption = createSelector(
  getDetails,
  details => get(details, 'firmProductSubscription["matchingOption"]'),
)
export const getFirmProductSubscriptionMatchingPoint = createSelector(
  getDetails,
  details => {
    const matchingPoint = get(
      details,
      'firmProductSubscription["matchingPoint"]',
    )
    const points = {}
    if (matchingPoint) {
      Object.keys(matchingPoint).forEach(key => {
        if ([PRO_FIELD_LAT, PRO_FIELD_LNG].includes(key)) {
          points[key] = matchingPoint[key]
        }
      })
    }
    return points
  },
)
export const getFirmProductSubscriptionMatchingRadius = createSelector(
  getDetails,
  details => get(details, 'firmProductSubscription["matchingRadius"]'),
)
export const getFirmProductSubscriptionPostcodesNumber = createSelector(
  getDetails,
  details => get(details, 'firmProductSubscription["postcodes"]'),
)
export const getFirmProductSubscriptionPostcodes = createSelector(
  getDetails,
  details => {
    const departments = get(details, 'firmProductSubscription["postcodes"]')
    return departments
      ? departments.map(department => DEPARTMENTS_LIST[department])
      : null
  },
)
export const getProductSubscription = createSelector(getDetails, details =>
  get(details, 'firmProductSubscription["productSubscriptions"]'),
)
export const getFirmLegalDataId = createSelector(getDetails, details =>
  get(details, 'firmLegalData["@id"]'),
)
export const countDocumentByType = createSelector(
  getDetails,
  (_, ...props) => props,
  (details, type) => {
    const documents = get(details, 'documents')
    return (documents || []).filter(doc => doc.type === type[1]).length
  },
)

export const isFirmPackageCandidateInState = createSelector(
  getDetails,
  details => PACKAGE_STATUS_CANDIDATE === get(details, 'packageStatusV2'),
)

export const getAllFirmCertificates = createSelector(getDetails, details =>
  get(details, 'firmCertificates'),
)
export const getFirmCertificates = createSelector(
  getAllFirmCertificates,
  (_, ...props) => props,
  (certificates, certificateType) =>
    (certificates || []).find(
      single => single.certificate.type === certificateType[1],
    ),
)
export const getCategoriesCertificates = createSelector(
  getFirmCertificates,
  certificates => {
    if (!certificates) return null

    const filteredCertificatesCategories = (
      certificates.certificateCategories || []
    ).map(single => single.name)

    return filteredCertificatesCategories
  },
)

export const getIsFirmAvailable = createSelector(getDetails, details =>
  get(details, 'available'),
)

export const getFirmUnavailabilityPeriods = createSelector(
  getDetails,
  details => get(details, 'firmUnavailabilityPeriods'),
)
