import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { fromAuth, fromPro } from 'store/selectors'
import { ROLES } from 'constants/roles'

const getRedirectPathname = location =>
  location && location.state && location.state.redirectPathname
    ? location.state.redirectPathname
    : null

const anonymousOnly = (AnonymousComponent, role) => {
  const Anonymous = props => {
    const { isLoggedInHo, isLoggedInPro, location } = props
    const redirectPathname = getRedirectPathname(location)
    const newProps = { ...props, redirectPathname }
    const home = role === ROLES.PRO ? '/my-account/dashboard' : '/'
    const to = redirectPathname || home
    let isRedirected = false

    // test if you have the rights to see this page
    if (
      (role === ROLES.PRO && isLoggedInPro) ||
      (role === ROLES.HO && isLoggedInHo)
    ) {
      isRedirected = true
    }

    return !isRedirected ? (
      <AnonymousComponent {...newProps} />
    ) : (
      <Redirect push to={to} />
    )
  }

  Anonymous.propTypes = {
    isLoggedInHo: PropTypes.bool,
    isLoggedInPro: PropTypes.bool,
    location: PropTypes.shape({
      state: PropTypes.shape({
        redirectPathname: PropTypes.string,
      }),
    }),
  }

  const mapStateToProps = state => ({
    isLoggedInHo: fromAuth.isLoggedIn(state),
    isLoggedInPro: fromPro.isLoggedIn(state),
  })

  return connect(mapStateToProps)(Anonymous)
}

export default anonymousOnly
