import get from 'lodash/get'
import { createSelector } from 'reselect'
import qs from 'query-string'
import { fr } from 'i18n/config'
import { stripTrailingSlash } from 'utils/job'
import { getInfosFromHostname } from 'utils/url'
import { UNIVERSES, UNIVERSES_SUB_DOMAIN } from 'constants/routes'

export const initialState = {
  location: {},
}

const defaultState = {}
const directoryRoutes = [fr].map(config => config.routes.directory)

const routeMatcher = (route, exact) => {
  const pathRegexp = route === '/' ? '(.*)?' : '(/.*)?'

  return new RegExp(`^(/beta)?${route}${exact ? '' : pathRegexp}$`)
}

export const matchesDirectoryRoute = (pathname, { exact = false } = {}) =>
  directoryRoutes.some(
    route => pathname && pathname.match(routeMatcher(route, exact)),
  )

const getLocationBeforeTransitions = state =>
  get(state, 'locationBeforeTransitions')
const getLocation = createSelector(
  getLocationBeforeTransitions,
  locationBeforeTransitions => get(locationBeforeTransitions, 'location'),
)
export const getPathname = createSelector(getLocation, location =>
  get(location, 'pathname'),
)
export const getBaseUrl = state => get(state, 'baseUrl')
export const getLastEndpoint = state =>
  stripTrailingSlash(getPathname(state)).split('/').pop()
export const getSearch = createSelector(getLocation, location =>
  get(location, 'search'),
)
export const getQuery = createSelector(getLocation, location =>
  qs.parse(get(location, 'search')),
)
export const hasHash = createSelector(
  getLocation,
  location => get(location, 'hash') !== '',
)
const getReferrer = state => get(state, 'referrer')
export const getReferrerPathname = createSelector(getReferrer, referrer =>
  get(referrer, 'pathname'),
)

export const isHomepage = createSelector(
  getPathname,
  pathname => pathname === '/',
)
export const isProHomepage = createSelector(
  getPathname,
  pathname =>
    /^\/?$/.test(pathname) &&
    getInfosFromHostname().subDomain === UNIVERSES_SUB_DOMAIN[UNIVERSES.PRO],
)
export const isDirectoryHomepage = state =>
  directoryRoutes.some(route => route === getPathname(state))
export const isDirectoryRoute = state =>
  matchesDirectoryRoute(getPathname(state))
export const getState = createSelector(
  getLocation,
  location => location || defaultState,
)
export const getRedirectPathname = createSelector(getState, state =>
  get(state, 'redirectPathname'),
)
export const isLandingPage = createSelector(
  isHomepage,
  isProHomepage,
  isDirectoryHomepage,
  (homePage, proHomePage, directoryHomePage) =>
    homePage || proHomePage || directoryHomePage,
)
