import {
  createRequestTypes,
  generateRequestActions,
  generateSimpleAction,
} from 'utils/actionHelpers'

export const REMOVE_TMP_FILE = 'REMOVE_TMP_FILE'
export const UPLOAD_TMP_FILE = createRequestTypes('UPLOAD_TMP_FILE')
export const SAVE_UPLOAD_FILE = createRequestTypes('SAVE_UPLOAD_FILE')
export const uploadTmpFile = generateRequestActions(UPLOAD_TMP_FILE)
export const removeTmpFile = generateSimpleAction(REMOVE_TMP_FILE)
export const saveUploadFile = generateRequestActions(SAVE_UPLOAD_FILE)
