import loadGoogleMapsAPI from 'utils/loadGoogleMapsAPI'

const geoCode = async (locationPath, bounds) => {
  await loadGoogleMapsAPI()
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ address: locationPath, bounds }, (results, status) => {
      if (status === 'OK') {
        resolve(results[0])
      } else {
        reject(
          new Error(
            `Geocode was not successful for the following reason: ${status}`,
          ),
        )
      }
    })
  })
}

export default geoCode
