import get from 'lodash/get'
import { createSelector } from 'reselect'

export const initialState = {
  onboardingQuestions: [],
}

export const getOnboardingQuestions = state => get(state, 'onboardingQuestions')
const getPackagesByCategory = state => get(state, 'packagesByCategory')

export const getPackageListByCategory = createSelector(
  getPackagesByCategory,
  (_, props) => props,
  (packagesByCategory, category) => get(packagesByCategory, category),
)

export const getDepartments = state => get(state, 'departments')

export const isValidatedFor = (state, type) =>
  get(
    state,
    'hasPackageSubscription',
  )
