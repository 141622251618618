import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import DecoratedDefaultPageMetas from 'components/DefaultPageMetas/DefaultPageMetasContainer'
import { ConnectedRouter } from 'connected-react-router'
import IntlProvider from 'components/IntlProvider/IntlProvider'
import initializeLocale from 'initializers/initializeLocale'
import { GlobalStyled } from 'initializers/initializeStyle'
import initializeAxios from 'initializers/initializeAxios'
import sagas from 'store/sagas'
import { clientInitiated } from 'store/actions'
import AxeptioManager from 'components/AxeptioManager/AxeptioManagerContainer'
import defaultTheme from 'theme/default'
import client from 'services/httpClient/commonClient'
import { initializeFeatureFlags } from 'feature-flag-consumer-js-lib'
import { CONSUMER_PRO_DESKTOP } from 'constants/features'
import { history } from 'store/index'

import { FRONT_API } from 'constants/services'
import getRoutes from 'routes'
import IntercomService from 'services/intercom/IntercomService'
import { QUERY_TRACKING } from 'constants/routes'

// Polyfill
// https://www.npmjs.com/package/intl
if (!global.Intl) require('intl')

const initFeatureFlags = async store => {
  try {
    await initializeFeatureFlags(
      store,
      CONSUMER_PRO_DESKTOP,
      client(FRONT_API, true),
    )
  } catch (e) {
    console.error(e)
  }
}

const initializeAxeptio = async search => {
  const query = new URLSearchParams(search)
  const trackingParams = query.get(QUERY_TRACKING)

  if (trackingParams === 'false') {
    localStorage.setItem(QUERY_TRACKING, trackingParams)
  }
}

const App = ({ store }) => {
  const [routes, setRoutes] = useState([])
  const { search } = useLocation()

  useEffect(() => {
    initializeLocale()
    initializeAxeptio(search)
    initializeAxios(store)
    initFeatureFlags(store)

    store.runSaga(sagas)
    store.dispatch(clientInitiated())

    const { lang } = store.getState().context
    setRoutes(getRoutes(lang, window.location.origin))
  }, [store])

  return (
    <IntlProvider hasRoutes={routes.length}>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyled />
        {routes.length > 0 && <DecoratedDefaultPageMetas />}
        <ConnectedRouter history={history}>
          <IntercomService />
          <AxeptioManager />
          <React.Suspense fallback="">
            <Switch>
              {(routes || []).map(route => (
                <Route key={route.path || 'not-found'} {...route} />
              ))}
            </Switch>
          </React.Suspense>
        </ConnectedRouter>
      </ThemeProvider>
    </IntlProvider>
  )
}

App.propTypes = {
  store: PropTypes.shape({
    runSaga: PropTypes.func,
    dispatch: PropTypes.func,
    getState: PropTypes.func,
  }),
}

export default App
