import { UPLOAD_DOCUMENT_FILE, GET_FIRM_CERTIFICATES } from './actions'

export default (state = { documents: [], certificates: [] }, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT_FILE.SUCCESS:
      return {
        ...state,
        documents: [...state.documents, action.payload[0]],
      }
    case GET_FIRM_CERTIFICATES.SUCCESS:
      return {
        ...state,
        certificates: [
          ...state.certificates,
          ...action.payload['hydra:member'],
        ],
      }
    default: {
      return state
    }
  }
}
