import { GET_DEPARTMENTS } from './actions'
import { initialState } from './selectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS.SUCCESS:
      return {
        ...state,
        list: action.payload,
      }
    default: {
      return state
    }
  }
}
