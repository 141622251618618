import { select, all, call } from 'redux-saga/effects'
import { takeLatest } from 'utils/effects'
import { hasDepartmentsSelector } from './selectors'
import { GET_DEPARTMENTS, getDepartments } from 'store/actions'
import client from 'services/httpClient/commonClient'
import handleRequest from 'sagas/handleRequest'
import { SYLIUS } from 'constants/services'

function* handleReadDepartmentListRequest() {
  const hasRecords = yield select(hasDepartmentsSelector)

  if (!hasRecords) {
    try {
      yield* handleRequest({
        requestActions: getDepartments,
        promise: call(
          client(SYLIUS, true).get,
          '/shop-api/departments?sorting[code]=asc&criteria[enabled]=true',
        ),
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export default function* () {
  yield all([
    takeLatest(GET_DEPARTMENTS.REQUEST, handleReadDepartmentListRequest),
  ])
}
