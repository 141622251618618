const importer = {
  url: url =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.addEventListener('load', () => resolve(script), false)
      script.addEventListener('error', () => reject(script), false)
      document.body.appendChild(script)
    }),
  style: url => {
    const link = document.createElement('link')
    link.setAttribute('type', 'text/css')
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('href', url)
    document.head.appendChild(link)
  },
  urls: urls => Promise.all(urls.map(importer.url)),
}

export default importer
