import client from 'services/httpClient/commonClient'
import { takeLatest } from 'utils/effects'
import notify from 'sagas/notify'
import { all, call, put, select } from 'redux-saga/effects'
import { change, getFormValues } from 'redux-form'
import get from 'lodash/get'
import { format } from 'date-fns'
import frLocale from 'date-fns/locale/fr'

import handleRequest from 'sagas/handleRequest'
import {
  uploadTmpFile,
  saveUploadFile,
  UPLOAD_TMP_FILE,
  SAVE_UPLOAD_FILE,
  resetStatus,
  firmGetSingleFile,
  firmSetFirmFiles,
  FIRM_GET_SINGLE_FILE,
  firmDetails,
} from 'store/actions'
import { fromPro } from 'store/selectors'
import { DOCUMENT_FORM } from 'constants/form'
import { DOCUMENTS, PRO_API } from 'constants/services'

const uploadTmpFileRequest = function* ({ data }) {
  if (Array.from(data.files).length === 0) {
    return
  }

  data.files.append('documentsType', JSON.stringify(data.documentTypes))

  yield* handleRequest({
    requestActions: uploadTmpFile,
    promise: call(client(DOCUMENTS, true).post, '/upload', data.files),
    actionParams: {
      formPart: data.formPart,
    },
  })
}

const uploadTmpFileFailed = function* () {
  yield* notify(
    '',
    'pro.package_candidate_document.uploadTmpFileFailed',
    'error',
  )
}

const saveFileIdToReduxForm = function* ({ payload, actionParams }) {
  const formData = yield select(getFormValues(DOCUMENT_FORM))

  const data = payload.reduce((accumulator, single) => {
    const name = Object.keys(single)[0]
    const id = Object.values(single)[0]

    return [...accumulator, { name, id }]
  }, [])

  const newValue =
    formData && formData[`${actionParams.formPart}Files`]
      ? [...data, ...formData[`${actionParams.formPart}Files`]]
      : [...data]

  yield put(change(DOCUMENT_FORM, `${actionParams.formPart}Files`, newValue))
  yield put(resetStatus({ statusType: 'SAVE_UPLOAD_FILE' }))
}

const saveUploadFileRequest = function* ({ payload }) {
  const firmId = yield select(fromPro.getFirmId)

  const currentDateFormated = format(Date.now(), 'dd/MM/yyyy', {
    locale: frLocale,
  })

  const savedFiles = payload
    ? (Object.entries(payload) || []).reduce(
        (acc, [filesArrayKey, filesArrayVal]) => [
          ...acc,
          ...filesArrayVal.map(a => ({
            fileId: a.id,
            fileName: a.name,
            fileDate: currentDateFormated,
            fileType: filesArrayKey.replace('Files', ''),
          })),
        ],
        [],
      )
    : []

  yield* handleRequest({
    requestActions: saveUploadFile,
    promise: call(client(PRO_API, true).put, `${firmId}/files`, {
      files: savedFiles,
    }),
    actionParams: { savedFiles, firmId },
  })
}

const saveUploadFileSuccess = function* ({
  actionParams: { savedFiles, firmId },
}) {
  yield all(
    savedFiles.map(file =>
      handleRequest({
        requestActions: firmGetSingleFile,
        promise: call(
          client(PRO_API, true).get,
          `/api/firm_files/${file.fileId}`,
        ),
        actionParams: { savedFile: file },
      }),
    ),
  )

  yield put(firmDetails.request({ id: firmId }))

  yield* notify(
    '',
    'pro.package_candidate_document.saveUploadFileSuccess',
    'success',
  )
}

const saveUploadFileFailed = function* () {
  yield* notify(
    '',
    'pro.package_candidate_document.saveUploadFileFailed',
    'error',
  )
}

const getSingleFileSuccess = function* (requestResponse) {
  if (!requestResponse.actionParams) {
    return
  }

  yield put(
    firmSetFirmFiles({
      payload: {
        type: get(requestResponse, 'actionParams.savedFile.fileType'),
        name: get(requestResponse, 'actionParams.savedFile.fileName'),
        id: requestResponse.payload['@id'],
      },
    }),
  )
}

export default function* () {
  yield all([
    takeLatest(UPLOAD_TMP_FILE.REQUEST, uploadTmpFileRequest),
    takeLatest(SAVE_UPLOAD_FILE.REQUEST, saveUploadFileRequest),
    takeLatest(SAVE_UPLOAD_FILE.SUCCESS, saveUploadFileSuccess),
    takeLatest(SAVE_UPLOAD_FILE.FAILURE, saveUploadFileFailed),
    takeLatest(UPLOAD_TMP_FILE.SUCCESS, saveFileIdToReduxForm),
    takeLatest(UPLOAD_TMP_FILE.FAILURE, uploadTmpFileFailed),
    takeLatest(FIRM_GET_SINGLE_FILE.SUCCESS, getSingleFileSuccess),
  ])
}
