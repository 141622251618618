import { LOCATION_CHANGE } from 'react-router-redux'
import splitActionType from 'utils/actions'
import { RESET_STATUS } from 'store/actions'

import { initialState, getLoading, getError, getRequested } from './selectors'

export default (state = initialState, action) => {
  if (action.type == null) return state

  const { prefix, suffix } = splitActionType(action.type)

  if (action.type === LOCATION_CHANGE) {
    return {
      loading: {
        ...getLoading(state),
      },
      error: {
        ...getError(state),
      },
      requested: {},
    }
  }

  if (action.type === RESET_STATUS) {
    return {
      loading: {
        ...getLoading(state),
        [action.statusType]: false,
      },
      error: {
        ...getError(state),
        [action.statusType]: false,
      },
      requested: {
        ...getRequested(state),
        [action.statusType]: false,
      },
    }
  }

  switch (suffix) {
    case 'REQUEST':
      return {
        loading: {
          ...getLoading(state),
          [prefix]: true,
        },
        error: {
          ...getError(state),
          [prefix]: false,
        },
        requested: {
          ...getRequested(state),
          [prefix]: false,
        },
      }
    case 'SUCCESS':
      return {
        loading: {
          ...getLoading(state),
          [prefix]: false,
        },
        error: {
          ...getError(state),
          [prefix]: false,
        },
        requested: {
          ...getRequested(state),
          [prefix]: true,
        },
      }
    case 'FAILURE':
      return {
        loading: {
          ...getLoading(state),
          [prefix]: false,
        },
        error: {
          ...getError(state),
          [prefix]: true,
        },
        requested: {
          ...getRequested(state),
          [prefix]: true,
        },
      }
    default:
      return state
  }
}
