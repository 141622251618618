export const DATE_FORMAT = {
  FR_LONG: 'dd/MM/yyyy',
  ISO: "yyyy-MM-dd'T'HH:mm:ss",
  ISO_INITIAL_TIMEZONE: "yyyy-MM-dd'T'HH:mm:ss+00:00",
  ISO_TIMEZONE: "yyyy-MM-dd'T'HH:mm:ss.000+02:00",
  ISO_TIMEZONE_WINTER: "yyyy-MM-dd'T'HH:mm:ss.000+01:00",
  DAY_OF_WEEK_MONTH_YEAR: 'EEEE dd LLLL yyyy', // e.g.: vendredi 16 novembre 2018
  DAY_MONTH_YEAR: 'dd LLLL yyyy', // e.g.: vendredi 16 novembre 2018
  ISO_SHORT: 'yyyy-MM-dd',
  SHORT_FORMAT: 'MM/yy',
  SHORT_FORMAT_CREDIT_CARD: 'MMyy',
  TIME_ONLY: 'HH[h]mm',
  TIME_ONLY_FORMATTED: "hh'h'mm",
  SHORT_FORMAT_REGEXP: /^[0-9]{2}\/[0-9]{2}$/,
  LONG_FORMAT_REGEXP: /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
  TIMEZONE: {
    FR: 'Europe/Paris',
    ES: 'Europe/Madrid',
  },
  DATE_FORMAT_TIMEZONE: 'dd/MM/yyyy HH:mm zzz',
  TIMESLOTS: {
    MORNING: '08:00:00.000-13:30:00.000',
    AFTERNOON: '13:30:00.000-18:00:00.000',
  },
}
