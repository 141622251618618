import { generateRequestActions, createRequestTypes } from 'utils/actionHelpers'

export const GET_ONBOARDING_QUESTIONS = createRequestTypes(
  'GET_ONBOARDING_QUESTIONS',
)
export const SUBMIT_ONBOARDING_FORM = createRequestTypes(
  'SUBMIT_ONBOARDING_FORM',
)
export const GET_PACKAGES_LIST = createRequestTypes('GET_PACKAGES_LIST')
export const GET_PACKAGE_SUBSCRIPTIONS = createRequestTypes(
  'GET_PACKAGE_SUBSCRIPTIONS',
)
export const GET_DEPARTMENTS_LIST = createRequestTypes('GET_DEPARTMENTS_LIST')
export const SET_CONFIGURATION = createRequestTypes('SET_CONFIGURATION')

export const getOnboardingQuestions = generateRequestActions(
  GET_ONBOARDING_QUESTIONS,
)
export const submitOnboardingForm = generateRequestActions(
  SUBMIT_ONBOARDING_FORM,
)
export const getPackagesList = generateRequestActions(GET_PACKAGES_LIST)
export const getHasPackageSubscription = generateRequestActions(
  GET_PACKAGE_SUBSCRIPTIONS,
)
export const getDepartmentsList = generateRequestActions(GET_DEPARTMENTS_LIST)
export const setConfiguration = generateRequestActions(SET_CONFIGURATION)
