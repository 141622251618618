import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { fromPro, fromRouting } from 'store/selectors'
import { URLS } from 'constants/urls'

const requiresProAuth = AuthenticatedComponent => {
  const Authentication = ({ isLoggedIn, redirectPathname, ...rest }) =>
    isLoggedIn ? (
      <AuthenticatedComponent {...rest} />
    ) : (
      <Redirect
        push
        to={{
          pathname: URLS.ROUTES.PRO_LOGIN,
          state: { redirectPathname },
        }}
      />
    )

  Authentication.propTypes = {
    redirectPathname: PropTypes.string,
    isLoggedIn: PropTypes.bool,
  }

  const mapStateToProps = state => ({
    isLoggedIn: fromPro.isLoggedIn(state),
    redirectPathname:
      fromRouting.getPathname(state) + fromRouting.getSearch(state),
  })

  return connect(mapStateToProps)(Authentication)
}

export default requiresProAuth
