import { intercom } from 'config'
import requireExternalLibrary from '../requireExternalLibrary'

export default async () => {
  const isDefer = false
  const isHead = true
  return requireExternalLibrary(
    `https://widget.intercom.io/widget/${intercom.appId}`,
    isDefer,
    isHead,
  )
}
