import { fork, all } from 'redux-saga/effects'

import auth from './auth/sagas'
import context from './context/sagas'
import firm from './firm/sagas'
import trades from './trades/sagas'
import directory from './directory/sagas'
import pro from './pro/sagas'
import google from './google/sagas'
import cloudinary from './cloudinary/sagas'
import productSubscription from './productSubscription/sagas'
import job from './job/sagas'
import documents from './documents/sagas'
import onboarding from './onboarding/sagas'
import survey from './survey/sagas'
import proDocuments from './pro-documents/sagas'
import departments from './departments/sagas'
import contact from './contact/sagas'

export * from './auth/sagas'
export * from './context/sagas'
export * from './firm/sagas'
export * from './google/sagas'
export * from './cloudinary/sagas'

const sagas = [
  auth,
  context,
  firm,
  trades,
  directory,
  pro,
  google,
  cloudinary,
  productSubscription,
  job,
  documents,
  onboarding,
  survey,
  proDocuments,
  departments,
  contact,
]

export default function* () {
  yield all(sagas.map(fork))
}
