import get from 'lodash/get'
import { createSelector } from 'reselect'

export const initialState = {
  isLoading: {},
  error: {},
}

export const getLoading = (state = initialState) => get(state, 'loading')
export const getError = (state = initialState) => get(state, 'error')
export const getRequested = (state = initialState) => get(state, 'requested')

// These following selectors are to be used in a reducer as they take an actionTypes object as input
export const isLoading = createSelector(
  getLoading,
  (_, props) => props,
  (isLoading, actionType) => !!get(isLoading, `[${actionType}]`),
)
export const hasError = createSelector(
  getError,
  (_, props) => props,
  (error, actionType) => !!get(error, `[${actionType}]`),
)
export const hasBeenRequested = createSelector(
  getRequested,
  (_, props) => props,
  (requested, actionType) => get(requested, `[${actionType}]`),
)
export const requestHasBeenSuccessful = createSelector(
  hasBeenRequested,
  hasError,
  (beenReq, error) => beenReq && !error,
)
export const requestHasFailed = createSelector(
  hasBeenRequested,
  hasError,
  (beenReq, error) => beenReq && error,
)
