import { connect } from 'react-redux'
import { fromContext } from 'store/selectors'
import { compose } from 'redux'
import injectTranslate from 'i18n/hoc/injectTranslate'

import UniverseLink from 'components/UniverseLink/UniverseLink'

export default compose(
  injectTranslate,
  connect(state => ({
    lang: fromContext.getLang(state),
  })),
)(UniverseLink)
