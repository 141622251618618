import { initialState } from './selectors'
import {
  AUTH_LOGOUT,
  AUTH_SET_LOGGED_IN,
  AUTH_SET_APP_OPERATIONAL,
  SET_AUTH_TOKENS,
  SET_REFRESH_TOKENS_AT,
  SET_REFRESH_TOKEN_REQUEST_LOCKED,
} from './actions'
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  REFRESH_TOKEN,
  REFRESH_TOKEN_REQUEST_LOCKED,
  REFRESH_TOKENS_AT,
} from 'constants/auth'

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_LOGGED_IN: {
      return {
        ...state,
        loggedIn: action.payload,
      }
    }
    case AUTH_SET_APP_OPERATIONAL: {
      return {
        ...state,
        appOperational: action.payload,
      }
    }
    case AUTH_LOGOUT: {
      return {
        ...initialState,
      }
    }
    case SET_AUTH_TOKENS: {
      return {
        ...state,
        [ID_TOKEN]: action.payload[ID_TOKEN],
        [ACCESS_TOKEN]: action.payload[ACCESS_TOKEN],
        [REFRESH_TOKEN]:
          action.payload[REFRESH_TOKEN] ?? initialState[REFRESH_TOKEN],
      }
    }
    case SET_REFRESH_TOKENS_AT:
      return {
        ...state,
        [REFRESH_TOKENS_AT]: action.payload,
      }
    case SET_REFRESH_TOKEN_REQUEST_LOCKED:
      return {
        ...state,
        [REFRESH_TOKEN_REQUEST_LOCKED]: action.payload,
      }
    default: {
      return state
    }
  }
}
