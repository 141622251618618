import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import { reducer as featureFlagsReducers } from 'feature-flag-consumer-js-lib'
import auth from './auth/reducer'
import context from './context/reducer'
import firm from './firm/reducer'
import routing from './routing/reducer'
import trades from './trades/reducer'
import status from './status/reducer'
import user from './user/reducer'
import directory from './directory/reducer'
import pro from './pro/reducer'
import proDocuments from './pro-documents/reducer'
import google from './google/reducer'
import cloudinary from './cloudinary/reducer'
import productSubscription from './productSubscription/reducer'
import job from './job/reducer'
import document from './documents/reducer'
import onboarding from './onboarding/reducer'
import survey from './survey/reducer'
import notifications from './notifications/reducer'
import departments from './departments/reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    form: form.plugin(document),
    featureFlags: featureFlagsReducers,
    auth,
    context,
    firm,
    routing,
    trades,
    status,
    user,
    directory,
    pro,
    proDocuments,
    google,
    cloudinary,
    productSubscription,
    job,
    onboarding,
    survey,
    departments,
  })
