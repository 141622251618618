// List all possible tabs for all possible cases
// No logic in this. Logic should be applied in src/utils/pro/proTabs.js:filterTabs()
// The tabs' order is set in proTabs.js:allTabs
export default {
  // Tabs specific to Legacy contract firms
  contractOptionLegacy: [
    'pro.nav.my_rating',
    'pro.nav.my_options',
    'pro.nav.my_projects',
  ],
  // Tabs specific to Packages firms
  contractOptionPackage: ['pro.nav.my_rating', 'pro.nav.my_packages'],
  // Tabs specific to Package candidate firms
  packageStatusCandidate: ['pro.nav.package_candidate'],
  // Tabs available in all cases
  default: [
    'pro.nav.my_page',
    'pro.nav.my_documents',
    'pro.nav.my_account',
    'pro.nav.help_and_contact',
  ],
}
