import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Link from 'components/Link/Link'
import { breakpointMax } from 'utils/style'
import injectTranslate from 'i18n/hoc/injectTranslate'
import { locales } from 'config'
import { fromContext } from 'store/selectors'

const Wrapper = styled.div`
  background-color: #3333fe;
  margin: 0;
  padding: 0;

  h1,
  h2 {
    margin: 0;
    color: white;
    font-family: 'montserrat';
    font-weight: normal;
  }
  h1 {
    font-size: 2.8em;
    margin-bottom: 0.8em;
  }
  h2 {
    font-size: 1.5em;
    margin-bottom: 1.8em;
  }

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${breakpointMax('l')`
    padding: 0 1em:
    h1 { font-size : 2.5em; margin-bottom: 0.8em; text-align: center; }
    h2 { font-size: 1.4em; margin-bottom: 1.8em; text-align: center; }
  `}

  ${breakpointMax('s')`
    h1 { font-size : 1.4em; margin-bottom: 0.8em; text-align: center; }
    h2 { font-size: 0.9em; margin-bottom: 1.8em; text-align: center; }
  `}
`

const StyledLink = styled(props => <Link {...props} />)`
  ${breakpointMax('l')`
    padding: 1.4rem;
    font-size: 0.75em;
    text-align: center;
  `}
  ${breakpointMax('s')`
    padding: 0.7rem;
    font-size: 0.75em;
  `}
`

const MaintenancePage = ({ translate, locale }) => (
  <Wrapper>
    <h1>{translate('maintenance.title')}</h1>
    <h2>{translate('maintenance.subtitle')}</h2>
    <StyledLink button to={locales[locale].contentSiteUrl}>
      {translate('maintenance.button')}
    </StyledLink>
  </Wrapper>
)

MaintenancePage.propTypes = {
  translate: PropTypes.func,
  locale: PropTypes.string,
}

export default compose(
  injectTranslate,
  connect(state => ({ locale: fromContext.getLocale(state) })),
)(MaintenancePage)
