import { css } from 'styled-components'

const scaffolding = theme => css`
  @font-face {
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: ${theme.fonts.size.reset};
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    font-size: ${theme.fonts.size.base};
    line-height: 2.1rem;
    color: ${theme.colors.grayscale.darker};

    &::-webkit-scrollbar-track {
      border-radius: 6rem;
      background-color: ${theme.colors.grayscale.lightest};
    }

    &::-webkit-scrollbar {
      height: ${theme.spaces.xs};
      width: ${theme.spaces.s};
      background-color: ${theme.colors.grayscale.lightest};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6rem;
      background-color: ${theme.colors.grayscale.light};
    }
  }

  html[no-scroll] {
    &,
    body {
      overflow: hidden;
    }
  }

  abbr[title],
  abbr[data-original-title] {
    cursor: help;
  }

  [hidden] {
    display: none !important;
  }

  [tabindex='-1']:focus {
    outline: none !important;
  }

  button {
    border: 0;
    outline: 0;
  }

  div.__react_component_tooltip.show {
    opacity: 1;
  }

  :-webkit-any(article, aside, nav, section) h1 {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
  }
`

export default scaffolding
