import client from 'services/httpClient/commonClient'
import { auth0Pro } from 'services/auth'
import { all, put, select, delay, call } from 'redux-saga/effects'
import { takeEvery, takeLatest } from 'utils/effects'
import { stopSubmit, reset } from 'redux-form'
import { LOCATION_CHANGE, push } from 'react-router-redux'
import geoCode from 'utils/geoCode'

import {
  fromTrades,
  fromRouting,
  fromPro,
  fromContext,
  fromAuth,
} from 'store/selectors'
import pushGtmEvent from 'utils/gtm'
import getFormErrors from 'utils/formErrors'
import parseLocationSearch from 'utils/parseLocationSearch'
import { saveSpnInCookie } from 'utils/spn'
import { saveTokens, removeTokens, bootstrapAuthFromLs } from 'utils/token'
import wait from 'utils/wait'
import handleRequest from 'sagas/handleRequest'
import notify from 'sagas/notify'
import { URLS } from 'constants/urls'
import {
  PRO_REGISTRATION_STEP_ONE,
  PRO_SIGNUP_QP_API_FORM_MAPPING,
  PRO_SIGNUP_API_FORM_MAPPING,
  PRO_CONTACT_API_FORM_MAPPING,
  PRO_REGISTRATION_FORM_NAME,
  PREFIX_CATEGORIES,
  NEW_FIRM_USER,
  CONFIRMATION_PATHS_FROM_PREFIX,
} from 'constants/pro'
import { PRO_API } from 'constants/services'

import {
  setProRoles,
  setProLoggedIn,
  firmCurrent,
  closeAll,
  setRedirectPathname,
  proCreate,
  proContact,
  proAuthLogin,
  proSetSpn,
  firmDetails,
  setProFirmId,
  setNickname,
  setEmail,
  proForgotPassword,
  proResetPassword,
  proRegistration,
  setProFormRegistrationStep,
  registrationNumberData,
  setRegistrationNumberData,
  setPathNameOrigin,
  getPrefilledRegistration,
  CLIENT_INITIATED,
  PRO_CREATE,
  PRO_CONTACT,
  PRO_AUTH_LOGIN,
  PRO_AUTH_LOGOUT,
  PRO_UPDATE_PASSWORD,
  PRO_FORGOT_PASSWORD,
  PRO_REGISTRATION,
  REGISTRATION_NUMBER_DATA,
  GET_PREFILLED_REGISTRATION,
} from 'store/actions'
import { getFreeniumSignUpConfirmationPath } from 'routes'

import { requireTrades } from '../trades/sagas'
import { ACCESS_TOKEN, ID_TOKEN, REFRESH_TOKEN } from 'constants/auth'
import { getGtmEvtLink } from 'utils/url'

const urlPrefix = '/api'

const fromAPI = (apiViolations, apiFormMappings) =>
  apiViolations.map(apiViolation => {
    const { propertyPath: apiPath, message: apiMessage } = apiViolation
    const apiField = apiPath.replace(/errors\.(.*)/, '$1')
    const field = apiFormMappings.find(f => f.apiField === apiField).formField
    const message = apiMessage
      .replace(apiField, field)
      .replace('.pro.', '.user.')
    return {
      propertyPath: field,
      message,
    }
  })

const toAPI = (data, apiFormMappings) =>
  apiFormMappings.reduce(
    (payload, { apiField, formField }) => ({
      ...payload,
      [apiField]: data[formField],
    }),
    {},
  )

function* handleProCreateRequest({ data }) {
  try {
    yield* requireTrades()
    const trade = yield select(fromTrades.getTradeBySeoFriendlyName, data.trade)
    const payload = toAPI(
      { ...data, trade: trade.name },
      PRO_SIGNUP_QP_API_FORM_MAPPING,
    )
    const spn = yield select(fromPro.getSpn)
    if (spn) {
      payload.spn = spn
    }
    const response = yield* handleRequest({
      requestActions: proCreate,
      promise: call(client(PRO_API, true).post, `${urlPrefix}/pros`, payload),
    })
    if (response.data.url) {
      window.location.href = response.data.url
    }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      yield put(
        stopSubmit(
          'SignUpQProForm',
          getFormErrors(
            fromAPI(e.response.data.violations, PRO_SIGNUP_QP_API_FORM_MAPPING),
          ),
        ),
      )
    } else {
      throw e
    }
  }
}

function* handleProContactRequest({ payload: { firmIri, ...data } }) {
  try {
    yield* handleRequest({
      requestActions: proContact,
      promise: call(client(PRO_API, true).post, `${urlPrefix}/contact_forms`, {
        ...toAPI(data, PRO_CONTACT_API_FORM_MAPPING),
        firm: firmIri,
      }),
    })
    yield put(firmCurrent.request(firmIri))
  } catch (e) {
    if (e.response && e.response.status === 400) {
      yield put(
        stopSubmit(
          'ContactProForm',
          getFormErrors(
            fromAPI(e.response.data.violations, PRO_CONTACT_API_FORM_MAPPING),
          ),
        ),
      )
    } else {
      throw e
    }
  }
}

function* handleProContactSuccess() {
  const pathName = yield select(fromRouting.getPathname)
  yield pushGtmEvent({
    event: 'ContactProFormSent',
    category: 'Directory',
    link: getGtmEvtLink(pathName),
  })
}

function* handleProLoginRequest({
  formName,
  email,
  password,
  redirectPathname,
  noRedirect,
}) {
  try {
    const pathName = yield select(fromRouting.getPathname)

    const res = yield* handleRequest({
      requestActions: proAuthLogin,
      promise: auth0Pro.login({
        username: email,
        password,
        realm: 'pro-legacy',
      }),
      actionParams: {
        noRedirect,
        redirectPathname: redirectPathname || URLS.ROUTES.PRO_DASHBOARD,
        pathNameOrigin: pathName,
      },
      checkTokens: false,
    })

    if (res && res[ID_TOKEN] && res[ACCESS_TOKEN]) {
      yield put(closeAll())

      yield saveTokens({
        [ACCESS_TOKEN]: res[ACCESS_TOKEN],
        [ID_TOKEN]: res[ID_TOKEN],
        [REFRESH_TOKEN]: res[REFRESH_TOKEN],
      })

      yield put(reset(formName))
    }
  } catch (e) {
    yield put(stopSubmit(formName, { _error: e.error_description }))
  }
}

function* decodeJwtToken(decodedToken, isLoginRequest = false) {
  if (decodedToken) {
    if (
      decodedToken[URLS.QUOTATIS.APP_METADATA] &&
      decodedToken[URLS.QUOTATIS.APP_METADATA].firm
    ) {
      const firmId = decodedToken[URLS.QUOTATIS.APP_METADATA].firm
      yield put(firmDetails.request({ id: firmId, isLoginRequest }))
      yield put(setProFirmId(firmId))
    }
    if (decodedToken.nickname) {
      yield put(setNickname(decodedToken.nickname))
    }
    if (decodedToken.email) {
      yield put(setEmail(decodedToken.email))
    }
    yield put(setProRoles(decodedToken[URLS.QUOTATIS.USER_AUTHORIZATION].roles))
  }
}

function* handleProLoginSuccess(action) {
  yield put(closeAll())
  yield put(setProLoggedIn(true))
  yield* notify('', 'user.sign_in.success')

  const decodedToken = yield select(fromAuth.selectIdTokenDecoded)
  yield decodeJwtToken(decodedToken, true)
  yield put(setPathNameOrigin(action.actionParams.pathNameOrigin))

  if (
    action.actionParams.redirectPathname.startsWith('/espace-pro/') ||
    action.actionParams.redirectPathname.startsWith('/espacio-pro/')
  ) {
    window.location.href = action.actionParams.redirectPathname
  } else {
    yield put(push(action.actionParams.redirectPathname))
  }
  yield put(setRedirectPathname(null))
}

function* handleProAuthLogout() {
  yield put(closeAll())
  yield put(setProLoggedIn(false))
  yield put(setProFirmId(''))
  yield put(setNickname(''))
  yield put(setEmail(''))
  removeTokens()

  yield put(push('/'))
  yield put(setRedirectPathname(null))
}

function* handleClientInitiated() {
  const queryParams = parseLocationSearch(window.location.search)
  if ('spn' in queryParams) {
    const { spn } = queryParams
    yield put(proSetSpn(spn))
    saveSpnInCookie(spn)
  }

  const isLogged = yield bootstrapAuthFromLs()
  yield put(setProLoggedIn(isLogged))

  if (isLogged) {
    const decodedToken = yield select(fromAuth.selectIdTokenDecoded)
    yield* decodeJwtToken(decodedToken)
    const pathName = yield select(fromRouting.getPathname)
    yield put(setPathNameOrigin(pathName))
  }
}

function* handleNewPasswordRequest({ email }) {
  try {
    yield* handleRequest({
      requestActions: proForgotPassword,
      promise: call(client(PRO_API, true).post, '/forgot-password/', { email }),
    })
    yield put(push('/login'))
    yield* notify('pro.thank_you', 'pro.reset_password_email')
    yield delay(3000)
    yield put(push('/login'))
  } catch (e) {
    if (e.response.status === 400) {
      // eslint-disable-next-line no-underscore-dangle
      yield put(
        stopSubmit('ProForgotPasswordForm', {
          _error: e.response.data.message,
        }),
      )
    }
  }
}

function* handleUpdatePasswordFailure() {
  yield* notify('', 'firm.save.failure.message', 'error')
}

function* handleUpdatePasswordSuccess({ actionParams }) {
  yield put(push(actionParams.redirectURL))
  yield* notify('pro.thank_you', 'pro.reset_password_success')
}

function* handleUpdatePasswordRequest({ token = null, password, formType }) {
  let updatePasswordURL = '/change-password-user'
  let redirectURL =
    formType === NEW_FIRM_USER ? '/reset-password-confirmation' : '/login'

  if (token === null) {
    redirectURL = '/my-account/account/change-password'
  } else {
    updatePasswordURL += `/${token}`
  }

  yield* handleRequest({
    requestActions: proResetPassword,
    promise: call(client(PRO_API, true).post, updatePasswordURL, { password }),
    actionParams: {
      redirectURL,
    },
  })
}

function* handleProRegistrationRequest({
  cguDataPolicy,
  qualityCharter,
  ...formData
}) {
  let dataRegistration = formData
  if (!cguDataPolicy || !qualityCharter) {
    yield put(proRegistration.failure())
    return
  }

  if (!formData.lng || !formData.lat) {
    const place = yield geoCode(formData.localisation)
    const lng = place?.geometry?.location?.lng().toString()
    const lat = place?.geometry?.location?.lat().toString()

    dataRegistration = {
      ...formData,
      lng,
      lat,
    }
  }

  const countryCode = yield select(fromContext.getCountry)
  const url = `${urlPrefix}/firms/signup`

  const userData = {
    email: dataRegistration.email,
    firstName: dataRegistration.firstname,
    lastName: dataRegistration.lastname,
    gender: dataRegistration.gender,
    password: dataRegistration.password,
    mobilePhone: dataRegistration.phone,
  }

  const queryParams = yield select(fromContext.getInitialQueryParams)

  const data = {
    firm: {
      countryCode,
      name: dataRegistration.name,
      city: dataRegistration.city,
      postalCode: dataRegistration.postalCode,
      registrationNumber: dataRegistration.siret,
      point: {
        lng: dataRegistration.lng,
        lat: dataRegistration.lat,
      },
      trades: dataRegistration.trade,
      origin: queryParams.origin,
    },
    user: userData,
    prefix: dataRegistration.prefix,
  }

  const spn = yield select(fromPro.getSpn)
  if (spn) {
    data.spn = spn
  }

  yield* handleRequest({
    requestActions: proRegistration,
    promise: call(client(PRO_API, true).post, url, data),
    actionParams: {
      email: dataRegistration.email,
      password: dataRegistration.password,
      prefix: dataRegistration.prefix,
    },
  })
}

function* handleProRegistrationSuccess({ actionParams }) {
  const language = yield select(fromContext.getLang)
  const confirmationPath =
    CONFIRMATION_PATHS_FROM_PREFIX[actionParams.prefix] ||
    getFreeniumSignUpConfirmationPath

  yield wait(500)
  yield put(
    proAuthLogin.request({
      redirectPathname: confirmationPath(language),
      ...actionParams,
    }),
  )
}

function* handleProRegistrationFailure({ error }) {
  if (error.response.status < 400 || error.response.status >= 500) {
    yield put(setProFormRegistrationStep(1))

    return yield* notify('', 'firm.save.failure.message', 'error')
  }

  const pathName = yield select(fromRouting.getPathname)

  if (pathName === URLS.ROUTES.PRO_PARTNER_REGISTRATION) {
    return yield* notify('', error.response.data.violations[0].message, 'error')
  }

  const errors = getFormErrors(
    fromAPI(error.response.data.violations, PRO_SIGNUP_API_FORM_MAPPING),
  )
  let isError = false
  PRO_REGISTRATION_STEP_ONE.forEach(field => {
    if (field in errors) {
      isError = true
    }
  })

  // change step to see error message
  if (isError) {
    yield put(setProFormRegistrationStep(1))
  }

  yield put(stopSubmit(PRO_REGISTRATION_FORM_NAME, errors))
}

function* handleGetRegistrationNumberDataRequest({ searchRequest }, prefix) {
  if (!searchRequest) {
    return yield put(
      registrationNumberData.success({
        payload: [],
      }),
    )
  }

  const locale = yield select(fromContext.getLocale)
  yield pushGtmEvent({
    event: 'siret_search',
    category: PREFIX_CATEGORIES[prefix],
  })

  return yield* handleRequest({
    requestActions: registrationNumberData,
    promise: call(
      client(PRO_API, true).get,
      `/api/registration-number-data/${locale}/${searchRequest}?limit=10`,
    ),
  })
}

function* handleGetRegistrationNumberDataSuccess({ payload }) {
  return yield put(setRegistrationNumberData(payload))
}

function* handleGetPrefilledRegistration({ partner, uuid }) {
  yield* handleRequest({
    requestActions: getPrefilledRegistration,
    promise: call(
      client(PRO_API, true).get,
      `api/prefilled_registrations/${uuid}`,
    ),
  })
}

function* redirectPartnerRegistration() {
  yield pushGtmEvent({
    event: 'redirect_partner_registration',
    category: 'Directory',
  })
  yield put(push(URLS.ROUTES.PRO_REGISTRATION))
}

function* handleLocationChange({
  payload: {
    location: { pathname },
  },
}) {
  if (pathname !== URLS.ROUTES.PRO_PARTNER_REGISTRATION) {
    return
  }

  const searchQuery = yield select(fromRouting.getQuery)

  if (!searchQuery || !searchQuery.uuid) {
    yield* redirectPartnerRegistration()
  }
}

function* handleGetPrefilledRegistrationFailure() {
  yield* redirectPartnerRegistration()
}

export default function* () {
  yield all([
    takeLatest(CLIENT_INITIATED, handleClientInitiated),
    takeLatest(LOCATION_CHANGE, handleLocationChange),
    takeEvery(PRO_CREATE.REQUEST, handleProCreateRequest),
    takeLatest(PRO_CONTACT.SUCCESS, handleProContactSuccess),
    takeEvery(PRO_CONTACT.REQUEST, handleProContactRequest),
    takeLatest(PRO_AUTH_LOGIN.REQUEST, handleProLoginRequest),
    takeLatest(PRO_AUTH_LOGIN.SUCCESS, handleProLoginSuccess),
    takeLatest(PRO_AUTH_LOGOUT, handleProAuthLogout),
    takeLatest(PRO_UPDATE_PASSWORD.REQUEST, handleUpdatePasswordRequest),
    takeLatest(PRO_UPDATE_PASSWORD.FAILURE, handleUpdatePasswordFailure),
    takeLatest(PRO_UPDATE_PASSWORD.SUCCESS, handleUpdatePasswordSuccess),
    takeLatest(PRO_FORGOT_PASSWORD.REQUEST, handleNewPasswordRequest),
    takeLatest(
      REGISTRATION_NUMBER_DATA.REQUEST,
      handleGetRegistrationNumberDataRequest,
    ),
    takeLatest(
      REGISTRATION_NUMBER_DATA.SUCCESS,
      handleGetRegistrationNumberDataSuccess,
    ),
    takeLatest(PRO_REGISTRATION.REQUEST, handleProRegistrationRequest),
    takeLatest(PRO_REGISTRATION.SUCCESS, handleProRegistrationSuccess),
    takeLatest(PRO_REGISTRATION.FAILURE, handleProRegistrationFailure),
    takeLatest(
      GET_PREFILLED_REGISTRATION.REQUEST,
      handleGetPrefilledRegistration,
    ),
    takeLatest(
      GET_PREFILLED_REGISTRATION.FAILURE,
      handleGetPrefilledRegistrationFailure,
    ),
  ])
}
