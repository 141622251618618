import {
  GET_SUBSCRIPTION_BY_FIRM,
  SUBSCRIBE_TO_PACKAGE_OFFER,
  SUBSCRIBE_TO_PACKAGE,
} from './actions'
import { initialState } from './selectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIBE_TO_PACKAGE_OFFER.SUCCESS: {
      return {
        ...state,
        firmProductSubscription: action.payload,
      }
    }
    case SUBSCRIBE_TO_PACKAGE.SUCCESS: {
      const subscribePackageList = [
        ...state.subscribePackageList,
        action.payload.packageCode,
      ]
      return {
        ...state,
        subscribePackageList,
      }
    }
    case GET_SUBSCRIPTION_BY_FIRM.SUCCESS: {
      const packageSubscriptions =
        action.payload && action.payload.packageSubscriptions
          ? action.payload.packageSubscriptions
          : []
      const subscribePackageList = packageSubscriptions.map(
        packageItem => packageItem.packageCode,
      )
      return {
        ...state,
        firmProductSubscription: action.payload,
        subscribePackageList,
      }
    }
    default: {
      return state
    }
  }
}
