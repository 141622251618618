import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import data from './data/packages'

const mocks = [
  {
    request: {
      query: 'abri',
      page: 0,
      hitsPerPage: 3,
    },
    response: data,
  },
]

const match = params =>
  get(
    mocks.find(({ request }) => isEqual(params, request)),
    'response',
  ) || null

const mockedIndex = {
  search: params => match(params),
}

export default mockedIndex
