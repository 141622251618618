export const getDisplayableQuestions = (questions, formValues) =>
  questions.filter(question => {
    if (!question.displayConditions) {
      return true
    }

    if (formValues === undefined) {
      return false
    }

    if (
      formValues[question.displayConditions[0].question] &&
      formValues[question.displayConditions[0].question] !==
        question.displayConditions[0].answer
    ) {
      return false
    }

    return true
  })

export const formatVerifyConditionsData = data => [
  {
    code: 'product_available',
    checked: data.product_available,
    comment: data.product_available_comment
      ? data.product_available_comment
      : null,
  },
  {
    code: 'product_condition',
    checked: data.product_condition,
    comment: data.product_condition_comment
      ? data.product_condition_comment
      : null,
  },
  {
    code: 'pictures_sent',
    checked: data.pictures_sent,
  },
  {
    code: 'job_location_accessibility',
    checked: data.job_location_accessibility,
  },
  {
    code: 'ho_presence_on_job_day',
    checked: data.ho_presence_on_job_day,
  },
]
