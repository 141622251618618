import { compose } from 'redux'
import { connect } from 'react-redux'
import injectTranslate from 'i18n/hoc/injectTranslate'
import { fromContext } from 'store/selectors'
import DefaultPageMetas from './DefaultPageMetas'

export default compose(
  injectTranslate,
  connect(state => ({
    lang: fromContext.getLang(state),
    pathContext: fromContext.getPathContext(state),
  })),
)(DefaultPageMetas)
