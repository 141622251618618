import seoFriendlyName from 'utils/seoFriendlyName'

export const initialState = {
  list: null,
}

export const getList = state => state.list
export const getTradeBySeoFriendlyName = (state, seoFriendlyTradeName) =>
  state.list &&
  state.list.find(({ name }) => seoFriendlyName(name) === seoFriendlyTradeName)
export const getTrade = (state, id) =>
  state.list && state.list.find(e => e['@id'] === id)
export const getStandardJob = (state, tradeId, standardJobId) => {
  const trade = getTrade(state, tradeId)

  return trade && trade.standardJobs.find(e => e['@id'] === standardJobId)
}
