import React from 'react'
import PropTypes from 'prop-types'
import UniverseLink from 'components/UniverseLink/UniverseLinkContainer'
import omit from 'lodash/omit'
import styled from 'styled-components'

import { styles, NoAnchor, Anchor, Hash } from './LinkStyle'

const styleProps = ['kind', 'button', 'secondary', 'large', 'theme']

const removeStyledProps = Component => props =>
  <Component {...omit(props, ...styleProps)} />

export const StyledLink = styled(removeStyledProps(UniverseLink))`
  ${styles}
`
const Link = ({ to, forceReload, isHash, ...props }) => {
  if (!to) {
    return <NoAnchor {...props} role="link" />
  }

  if (isHash) {
    return <Hash {...props} to={to} />
  }

  // Checks if URL is absolute
  if (
    (typeof to === 'string' && to.match(/^(https?|mailto|tel):/)) ||
    forceReload ||
    isHash
  ) {
    return <Anchor {...props} href={to || ''} />
  }

  return <StyledLink {...{ ...props, to }} />
}

Link.propTypes = {
  light: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  button: PropTypes.bool,
  isHash: PropTypes.bool,
  large: PropTypes.bool,
  center: PropTypes.bool,
  forceReload: PropTypes.bool,
  secondary: PropTypes.bool,
}

export default Link
