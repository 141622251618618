import get from 'lodash/get'
import { createSelector } from 'reselect'
import jwtDecode from 'jwt-decode'
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  REFRESH_TOKEN,
  REFRESH_TOKEN_REQUEST_LOCKED,
  REFRESH_TOKENS_AT,
} from 'constants/auth'

export const initialState = {
  loggedIn: null,
  appOperational: true,
  [ID_TOKEN]: null,
  [ACCESS_TOKEN]: null,
  [REFRESH_TOKEN]: null,
  [REFRESH_TOKENS_AT]: null,
  [REFRESH_TOKEN_REQUEST_LOCKED]: false,
}

export const isAppOperational = (state = initialState) =>
  get(state, 'appOperational')
export const isLoggedIn = (state = initialState) => get(state, 'loggedIn')

const selectIdToken = (state = initialState) => state[ID_TOKEN]
const selectAccessToken = (state = initialState) => state[ACCESS_TOKEN]

export const selectIdTokenDecoded = createSelector(selectIdToken, idToken =>
  idToken ? jwtDecode(idToken) : null,
)
const selectAccessTokenDecoded = createSelector(
  selectAccessToken,
  accessToken => (accessToken ? jwtDecode(accessToken) : null),
)

export const selectIdTokenExpAt = (state = initialState) =>
  selectIdTokenDecoded(state)?.exp

export const selectRefreshTokensAt = (state = initialState) =>
  state[REFRESH_TOKENS_AT] ?? initialState[REFRESH_TOKENS_AT]

export const selectRefreshTokenRequestLocked = (state = initialState) =>
  state[REFRESH_TOKEN_REQUEST_LOCKED]

export const getRefreshToken = (state = initialState) => state[REFRESH_TOKEN]
