import { connect } from 'react-redux'
import { fromContext, fromRouting } from 'store/selectors'

import GoogleTagManager from './GoogleTagManager'

export default connect(state => ({
  country: fromContext.getCountry(state),
  locale: fromContext.getLocale(state),
  baseUrl: fromRouting.getBaseUrl(state),
}))(GoogleTagManager)
