import React from 'react'
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { fromPro } from 'store/selectors'

import requiresProAuth from './requiresProAuth'

const adminOnly = AdminComponent => {
  const Admin = props =>
    props.hasRoleAdmin ? <AdminComponent {...props} /> : <Redirect to="/" />

  Admin.propTypes = {
    hasRoleAdmin: PropTypes.bool.isRequired,
  }

  return compose(
    connect(state => ({
      hasRoleAdmin: fromPro.hasRoleAdmin(state),
    })),
    requiresProAuth,
  )(Admin)
}

export default adminOnly
