import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import translations from 'i18n'
import { fromContext } from 'store/selectors'

const IntlProvider = props =>
  props.hasRoutes ? (
    <ReactIntlProvider {...props}>{props.children}</ReactIntlProvider>
  ) : (
    props.children
  )

IntlProvider.propTypes = {
  children: PropTypes.any,
}

const mapStateToProps = state => ({
  locale: fromContext.getLang(state),
  messages: translations[fromContext.getLang(state)],
})

export default connect(mapStateToProps)(IntlProvider)
