import get from 'lodash/get'
import { createSelector } from 'reselect'

export const initialState = {
  search: {},
}

const getSearch = state => get(state, 'search')
export const getDirectoryPlaceAutoCompleteLocation = state =>
  get(state, 'autoCompleteLocation')
export const getCurrentSearchPlace = state => get(state, 'currentSearchPlace')

export const getSearchResults = createSelector(getSearch, search =>
  get(search, 'results'),
)
export const getQueryMatchingTrade = createSelector(getSearch, search =>
  get(search, 'matchingTrade'),
)
export const getNextPage = createSelector(getSearchResults, results =>
  get(results, 'nextPage'),
)
const getQuery = createSelector(getSearchResults, results =>
  get(results, 'query'),
)

export const getSearchResultsNextPage = createSelector(
  getSearchResults,
  getNextPage,
  (results, nextPage) => results && nextPage,
)

export const getSeoFriendlyTradeName = createSelector(
  getSearch,
  getQuery,
  (search, query) =>
    search && query ? get(query, 'seoFriendlyTradeName') : null,
)

export const getPlace = createSelector(
  getDirectoryPlaceAutoCompleteLocation,
  autoCompleteLocation =>
    autoCompleteLocation && get(autoCompleteLocation, 'place'),
)

export const getPlaceFormattedAddress = createSelector(getPlace, place =>
  place ? get(place, 'formatted_address') : null,
)
