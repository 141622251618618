import React from 'react'
import PropTypes from 'prop-types'
import { mocking, google } from 'config'

import GoogleTagManagerView from './GoogleTagManagerView'

const GoogleTagManager = ({ country, locale, baseUrl }) => {
  const gtmId = google.tagManagerId[country]

  if (mocking) {
    window.dataLayer = []
  }

  return (
    <div suppressHydrationWarning>
      {!mocking && (
        <GoogleTagManagerView locale={locale} baseUrl={baseUrl} gtmId={gtmId} />
      )}
    </div>
  )
}

GoogleTagManager.propTypes = {
  country: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
}

export default GoogleTagManager
