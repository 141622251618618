import algoliaSearch from 'algoliasearch'
import { algolia, mocking } from 'config'
import mockedPackagesIndex from './mocks/packages'
import mockedProFormIndex from './mocks/proForm'
import mockedFirmIndex from './mocks/firm'

const client = algoliaSearch(algolia.search.appId, algolia.search.apiKey)

export const PACKAGES_INDEX_NAME = 'packages'
export const PRO_FORM_INDEX_NAME = 'proForm'

export const packagesIndex = country =>
  mocking
    ? mockedPackagesIndex
    : client.initIndex(algolia.packagesIndex[country])
export const proFormIndex = country =>
  mocking ? mockedProFormIndex : client.initIndex(algolia.proFormIndex[country])
export const firmIndex = () =>
  mocking ? mockedFirmIndex : client.initIndex(algolia.firmIndex)

export const addFacetFilter = (field, value, array) =>
  array.push(`${field}:${value}`)

export const buildFacetFilters = facetFilters =>
  Object.keys(facetFilters).reduce((array, key) => {
    addFacetFilter(key, facetFilters[key], array)
    return array
  }, [])

export default client
