import { getLocaleFromLang } from 'utils/locale'

const SIRET_SIZE = 14
const SIREN_SIZE = 9
const FR = 'fr'

const verify = (number, size, locale) => {
  if (locale !== getLocaleFromLang(FR)) {
    return true
  }

  if (isNaN(number) || number.length !== size) return false
  let bal = 0
  let total = 0

  for (let i = size - 1; i >= 0; i -= 1) {
    const step = (number.charCodeAt(i) - 48) * (bal + 1)
    total += step > 9 ? step - 9 : step
    bal = 1 - bal
  }

  return total % 10 === 0
}

export const isSIRET = (siret, locale) => verify(siret, SIRET_SIZE, locale)
export const isSIREN = (siren, locale) => verify(siren, SIREN_SIZE, locale)
