export const firmsByTradePlombierPage1 = {
  hitsPerPage: 30,
  nbHits: 148,
  nbPages: 30,
  page: 0,
  hits: [
    {
      objectID: '056291bd-2220-48f4-88ad-80665b349454',
      logoUrl: null,
      coverUrl: null,
      name: 'I FUNTANARI',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      postalCode: '75000',
      city: 'Paris',
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '38d4ea80-8c6a-4fa4-b2e8-25366ef354b0',
      logoUrl: null,
      coverUrl: null,
      name: 'CMS SARL',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '440a14ec-2935-4f4a-aec2-89d9913e1d64',
      logoUrl: null,
      coverUrl: null,
      name: 'PLOMBERIE CHAUFFAGE CONFORT',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '4b65dc85-b402-4a36-a2ee-5e2f16f32848',
      logoUrl: null,
      coverUrl: null,
      name: 'PRO SERVICES',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '43a4859c-9c2c-4e42-85c1-829abaf4231e',
      logoUrl: null,
      coverUrl: null,
      name: 'EURL CPM',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '2c57f3e9-f434-4b82-a259-8f8670d65923',
      logoUrl: null,
      coverUrl: null,
      name: 'BP PRO',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
  ],
}

export const firmsByTradePlombierPage2 = {
  hitsPerPage: 30,
  nbHits: 148,
  nbPages: 30,
  page: 1,
  hits: [
    {
      objectID: '0942748c-e42e-401f-b6e8-8d3767b623a1',
      logoUrl: null,
      coverUrl: null,
      name: 'EVA',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '3a9dd035-5f13-4fd4-a1c2-8d6a241f730a',
      logoUrl: null,
      coverUrl: null,
      name: 'CULLIGAN S.T.E.I.D.',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '3985c910-331e-4fd9-8ea6-4284dd0b6faa',
      logoUrl: null,
      coverUrl: null,
      name: 'SDB CONCEPT',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '097d34c8-1569-4fa3-82da-5cd19e0f8ab5',
      logoUrl: null,
      coverUrl: null,
      name: 'MARCEL FEVE FABRICE SIMON',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '293532c7-baac-46d0-abe0-569436a1354a',
      logoUrl: null,
      coverUrl: null,
      name: 'STC',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '43df0201-6ebd-4f03-84aa-7e410a5b9b68',
      logoUrl: null,
      coverUrl: null,
      name: 'SANI TECH PLOMBERIE',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['plombier-installateur-sanitaire'],
      _tags: ['plombier-installateur-sanitaire'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
  ],
}

export const firmsByTradeAntenniste = {
  hitsPerPage: 30,
  nbHits: 10,
  nbPages: 2,
  page: 0,
  hits: [
    {
      objectID: 'f148898f-bea0-4a60-9ade-d66e895f8828',
      logoUrl: null,
      coverUrl: null,
      name: 'SMART-I',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['antenniste'],
      _tags: ['antenniste'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '17dacded-aef0-4a40-8e0e-41c647e1f4a8',
      logoUrl: null,
      coverUrl: null,
      name: 'ANDRE VIEIRA',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['antenniste'],
      _tags: ['antenniste'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '89bc29bf-1f12-4e27-ab1f-16f53dea4640',
      logoUrl: null,
      coverUrl: null,
      name: 'ELECTRO ANTENNE DELAVAQUERIE XAVIER',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['antenniste'],
      _tags: ['antenniste'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: 'bc242eb1-811d-4348-955e-5a9969849e6b',
      logoUrl: null,
      coverUrl: null,
      name: 'JS ANTENNE',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['antenniste'],
      _tags: ['antenniste'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '2f8a5ed7-dd8b-44a2-839d-de84cf91a61c',
      logoUrl: null,
      coverUrl: null,
      name: 'MKS MEDIAS',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['antenniste'],
      _tags: ['antenniste'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '45aba0e9-ead4-4d49-a299-9521802aa9fe',
      logoUrl: null,
      coverUrl: null,
      name: 'CHANTRON THOMAS',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['antenniste'],
      _tags: ['antenniste'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
  ],
}

export const firmsByTradeArchitecte = {
  hitsPerPage: 30,
  nbHits: 42,
  nbPages: 7,
  page: 0,
  hits: [
    {
      objectID: '975a2fe6-c49a-401b-909f-4cea35fe3783',
      logoUrl: null,
      coverUrl: null,
      name: "IN'ARCHY",
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['architecture'],
      _tags: ['architecture'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: '8bd35071-6b99-41ed-b1cc-b65cc3d558ca',
      logoUrl: null,
      coverUrl: null,
      name: 'BERNARD ODIER',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['architecture'],
      _tags: ['architecture'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: 'b886acbd-d6a3-48aa-9569-8bd41062d4fb',
      logoUrl: null,
      coverUrl: null,
      name: 'ABED',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['architecture'],
      _tags: ['architecture'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: 'eae687d3-0432-4862-901d-8a338a9f03ed',
      logoUrl: null,
      coverUrl: null,
      name: 'FERTE',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['architecture'],
      _tags: ['architecture'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: 'd158e39a-2f19-4c01-b93a-6d5d9a93fa12',
      logoUrl: null,
      coverUrl: null,
      name: 'DALCO SYSTEM',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['architecture'],
      _tags: ['architecture'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
    {
      objectID: 'ff2dc14f-59e5-4bd3-9962-6d0df73800eb',
      logoUrl: null,
      coverUrl: null,
      name: 'SYMPHONIA',
      globalRating: 3.67,
      globalRatingCount: 3,
      url: 'mock-name-HASHID',
      firmCertificates: [],
      firmPictures: [],
      trades: ['architecture'],
      _tags: ['architecture'],
      _geoloc: {
        lng: 116.396,
        lat: 36.6438,
      },
    },
  ],
}

export const noFirms = {
  hitsPerPage: 30,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  hits: [],
}
