export const contractOptionDirectory = 'DIRECTORY'
export const contractOptionPush = 'PUSH'
export const contractOptionPick = 'PICK'
export const contractOptionPackage = 'PACKAGE'

export const paidContractOptions = [
  contractOptionPush,
  contractOptionPick,
  contractOptionPackage,
]

export const packageDirectoryOptions = [
  contractOptionDirectory,
  contractOptionPackage,
]
