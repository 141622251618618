export const CONTRACT_PATH = '/contract_yousign'
export const TRAILING_SLASH = /\/+$/
export const QUERY_TRACKING = 'tracking'
export const UNIVERSES = {
  PRO: 'PRO',
  PARTNER: 'PARTNER',
  DIR: 'DIR',
}
export const UNIVERSES_SUB_DOMAIN = {
  [UNIVERSES.PRO]: 'pro',
  [UNIVERSES.PARTNER]: 'partenaire',
  [UNIVERSES.DIR]: 'annuaire',
}
