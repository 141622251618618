import {
  GET_ONBOARDING_QUESTIONS,
  SUBMIT_ONBOARDING_FORM,
  GET_PACKAGES_LIST,
  GET_DEPARTMENTS_LIST,
  GET_PACKAGE_SUBSCRIPTIONS,
} from './actions'
import { initialState } from './selectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ONBOARDING_QUESTIONS.SUCCESS: {
      return {
        ...state,
        onboardingQuestions: action.payload['hydra:member'] || [],
      }
    }
    case GET_PACKAGES_LIST.SUCCESS: {
      return {
        ...state,
        packagesByCategory: action.packagesByCategory || [],
      }
    }
    case GET_PACKAGE_SUBSCRIPTIONS.SUCCESS: {
      return {
        ...state,
        hasPackageSubscription: action.payload.hasSubscriptions,
      }
    }
    case GET_DEPARTMENTS_LIST.SUCCESS: {
      return {
        ...state,
        departments: action.payload['hydra:member'] || [],
      }
    }
    case SUBMIT_ONBOARDING_FORM.SUCCESS: {
      return {
        ...state,
      }
    }
    case SUBMIT_ONBOARDING_FORM.ERROR: {
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}
