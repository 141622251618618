import {
  VIRTUAL_STATUSES,
  STATUS_PENDING_ANSWER,
  STATUS_PENDING_PAYMENT,
  STATUS_PENDING_REALIZATION,
  STATUS_JOB_IN_PROGRESS,
  STATUS_JOB_DONE,
  VIRTUAL_STATUS_HISTORICAL,
  VIRTUAL_STATUS_IN_PROGRESS,
  VIRTUAL_STATUS_MISSED,
  STATUS_PENDING_NEW_DATE,
  STATUS_PENDING_START,
  STATUS_PENDING_RESCHEDULING,
} from 'constants/job'

import { TRAILING_SLASH } from 'constants/routes'

export const getChildStatuses = status => VIRTUAL_STATUSES[status] || [status]

export const getIdFromJob = job => {
  const strID = job['@id'].split('/')
  return strID[strID.length - 1]
}
export const getIdFromJobIri = jobIri => {
  const strID = jobIri.split('/')
  return strID[strID.length - 1]
}

export const convertCentsToEuros = priceInCents => priceInCents / 100

export const stripTrailingSlash = str => str.replace(TRAILING_SLASH, '')

export const getStatusUrls = baseUrl => {
  const jobConsideredInProgessUrlStart = `${baseUrl}/${VIRTUAL_STATUS_IN_PROGRESS}`
  const jobInHistoricalUrlStart = `${baseUrl}/${VIRTUAL_STATUS_HISTORICAL}`
  return [
    {
      urlStart: baseUrl,
      url: baseUrl,
      status: STATUS_PENDING_ANSWER,
    },
    {
      urlStart: jobConsideredInProgessUrlStart,
      url: `${baseUrl}/${VIRTUAL_STATUS_IN_PROGRESS}`,
      status: STATUS_PENDING_REALIZATION,
    },
    {
      urlStart: jobConsideredInProgessUrlStart,
      url: `${baseUrl}/${VIRTUAL_STATUS_IN_PROGRESS}`,
      status: STATUS_JOB_IN_PROGRESS,
    },
    {
      urlStart: jobConsideredInProgessUrlStart,
      url: `${baseUrl}/${VIRTUAL_STATUS_IN_PROGRESS}`,
      status: STATUS_PENDING_PAYMENT,
    },
    {
      urlStart: baseUrl,
      status: STATUS_JOB_DONE,
      url: `${baseUrl}/${VIRTUAL_STATUS_HISTORICAL}`,
      child: [
        {
          urlStart: jobInHistoricalUrlStart,
          url: `${baseUrl}/${VIRTUAL_STATUS_HISTORICAL}`,
          status: STATUS_JOB_DONE,
        },
        {
          urlStart: jobInHistoricalUrlStart,
          url: `${baseUrl}/${VIRTUAL_STATUS_HISTORICAL}`,
          status: VIRTUAL_STATUS_MISSED,
        },
      ],
    },
  ]
}

export const getParentStatusByStatus = status => {
  let parentStatus = status
  if (status === STATUS_PENDING_START) {
    parentStatus = STATUS_JOB_IN_PROGRESS
  }
  if (status === VIRTUAL_STATUS_HISTORICAL) {
    parentStatus = STATUS_JOB_DONE
  }
  if ([STATUS_PENDING_NEW_DATE, STATUS_PENDING_RESCHEDULING].includes(status)) {
    parentStatus = STATUS_PENDING_REALIZATION
  }
  return parentStatus
}

export const areAllPastTimeslots = job => {
  const { timeslots } = job

  const now = new Date()
  now.setHours(0, 0, 0, 0)
  let result = true

  timeslots.forEach(timeslot => {
    const dateStartTime = new Date(timeslot.startTime)
    dateStartTime.setHours(0, 0, 0, 0)

    if (dateStartTime >= now) {
      result = false
    }
  })

  return result
}
