import get from 'lodash/get'
import { createSelector } from 'reselect'

export const initialState = {
  survey: {
    token: null,
  },
  surveyQuestions: [],
}

export const getSurvey = state => get(state, 'survey')
export const getSurveyToken = createSelector(getSurvey, survey =>
  get(survey, 'token'),
)
export const getSurveyQuestions = state => get(state, 'surveyQuestions')
