import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const DefaultPageMetas = ({ translate = () => {}, lang, pathContext }) => (
  <Helmet>
    <html lang={lang} />
    <title>{translate(`pages.${pathContext}.title`)}</title>
    <meta
      name="description"
      content={translate('pages.default.meta.description')}
    />
  </Helmet>
)

DefaultPageMetas.propTypes = {
  translate: PropTypes.func,
  lang: PropTypes.string.isRequired,
  pathContext: PropTypes.string.isRequired,
}

export default DefaultPageMetas
