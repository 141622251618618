import client from 'services/httpClient/commonClient'
import handleRequest from 'sagas/handleRequest'
import { takeLatest, waitForRequestCompletion } from 'utils/effects'
import { all, call, select } from 'redux-saga/effects'
import { fromContext, fromTrades, fromStatus } from 'store/selectors'
import { getTradesList, TRADES_LIST } from 'store/actions'
import { PRO_API } from 'constants/services'

function* fetchTrades({ withFirm }) {
  const country = yield select(fromContext.getCountry)
  yield* handleRequest({
    requestActions: getTradesList,
    promise: call(
      client(PRO_API, true).get,
      `/api/trades${withFirm ? '?withFirm' : ''}`,
      {
        params: {
          countryCode: country,
        },
      },
    ),
  })
}

export const requireTrades = function* () {
  const trades = yield select(fromTrades.getList)
  if (!trades) {
    const isLoading = yield select(fromStatus.isLoading, TRADES_LIST.prefix)
    if (isLoading) {
      yield waitForRequestCompletion(TRADES_LIST.prefix)
    } else {
      yield* fetchTrades({ withFirm: true })
    }
  }
}

export default function* () {
  yield all([takeLatest(TRADES_LIST.REQUEST, fetchTrades)])
}
