import { coinDesProsApi as coinDesProsApiConfig } from 'config'
import axios from 'axios'
import { applyCache } from 'axios-cache-interceptor'
import { SessionCacheStorage } from 'axios-cache-interceptor/dist/storage/web'

class CoinDesProsClient {
  static instance

  #axiosInstance

  constructor() {
    this.#axiosInstance = applyCache(
      axios.create({
        timeout: coinDesProsApiConfig.timeout,
        baseURL: coinDesProsApiConfig.baseURL,
      }),
      {
        storage: new SessionCacheStorage(),
      },
    )
  }

  static getInstance() {
    if (typeof CoinDesProsClient.instance === 'undefined') {
      CoinDesProsClient.instance = new CoinDesProsClient()
    }

    return CoinDesProsClient.instance
  }

  async get(url, config) {
    return this.#axiosInstance.get(url, {
      ...config,
      cache: {
        ttl: coinDesProsApiConfig.ttlCache,
      },
    })
  }

  async getProductsCode(config) {
    return this.get('product_code', config)
  }

  async getProductCodeBySlug(slug) {
    const productCodes = await this.getProductsCode({
      params: { slug },
    })

    if (productCodes.data.length === 0) {
      throw new Error('No result')
    }

    if (productCodes.data.length > 1) {
      throw new Error('More than one result')
    }

    return productCodes
  }

  async getHelpCenterPosts(config) {
    return this.get('help_center_post', {
      ...config,
      params: {
        ...config?.params,
        // eslint-disable-next-line camelcase
        help_tax: coinDesProsApiConfig.helpCenterPostCategoryId,
      },
    })
  }

  async getHelpCenterPostByProductCode(productCodeId) {
    const helpCenterPosts = await this.getHelpCenterPosts({
      // eslint-disable-next-line camelcase
      params: { product_code: productCodeId },
    })

    if (helpCenterPosts.data.length === 0) {
      throw new Error('No result')
    }

    if (helpCenterPosts.data.length > 1) {
      throw new Error('More than one result')
    }

    return helpCenterPosts
  }
}

export default CoinDesProsClient.getInstance()
