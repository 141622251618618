import {
  generateRequestActions,
  generateSimpleAction,
  createRequestTypes,
} from 'utils/actionHelpers'

export const USER_CREATE = createRequestTypes('USER_CREATE')
export const USER_DETAILS = createRequestTypes('USER_DETAILS')
export const USER_UPDATE = createRequestTypes('USER_UPDATE')
export const USER_FORGOT_PASSWORD = createRequestTypes('USER_FORGOT_PASSWORD')
export const USER_UPDATE_PASSWORD = createRequestTypes('USER_UPDATE_PASSWORD')
export const USER_VALIDATE_PHONE = createRequestTypes('USER_VALIDATE_PHONE')
export const USER_VERIFY_EMAIL = createRequestTypes('USER_VERIFY_EMAIL')
export const USER_RESEND_EMAIL = createRequestTypes('USER_RESEND_EMAIL')
export const USER_VALIDATE_PHONE_CODE = createRequestTypes(
  'USER_VALIDATE_PHONE_CODE',
)
export const USER_RESET = 'USER_RESET'

export const userCreate = generateRequestActions(USER_CREATE)
export const userDetails = generateRequestActions(USER_DETAILS)
export const userUpdate = generateRequestActions(USER_UPDATE)
export const forgotPassword = generateRequestActions(USER_FORGOT_PASSWORD)
export const resetPassword = generateRequestActions(USER_UPDATE_PASSWORD)
export const validatePhone = generateRequestActions(USER_VALIDATE_PHONE)
export const validatePhoneCode = generateRequestActions(
  USER_VALIDATE_PHONE_CODE,
)
export const verifyEmail = generateRequestActions(USER_VERIFY_EMAIL)
export const resendEmail = generateRequestActions(USER_RESEND_EMAIL)
export const resetUser = generateSimpleAction(USER_RESET)
