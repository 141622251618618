import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import importer from 'utils/importer'
import { axeptio as axeptioSettings } from 'config'
import GoogleTagManager from '../GoogleTagManager/GoogleTagManagerContainer'

const AxeptioManagerView = ({ setCookiesConsent, cookies }) => {
  const [axeptioIsLoaded, setAxeptioIsLoaded] = useState(null)
  window.axeptioSettings = axeptioSettings

  useEffect(() => {
    async function importAxeptio() {
      await importer.url(`//static.axept.io/sdk.js`)
      setAxeptioIsLoaded(true)
    }
    importAxeptio()
    setCookiesConsent()
  }, [setCookiesConsent, setAxeptioIsLoaded])

  useEffect(() => {
    if (
      axeptioIsLoaded &&
      typeof window !== 'undefined' &&
      typeof window.axeptioSDK !== 'undefined' &&
      typeof window.axeptioSDK.on === 'function'
    ) {
      window.axeptioSDK.on('cookies:complete', () => {
        setCookiesConsent()
      })
    }
  }, [axeptioIsLoaded])

  const startGTM = () => cookies !== undefined && cookies.analytics

  return startGTM() ? <GoogleTagManager /> : ''
}

AxeptioManagerView.propTypes = {
  setCookiesConsent: PropTypes.func.isRequired,
  cookies: PropTypes.object,
}

export default AxeptioManagerView
