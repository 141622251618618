import { initialState } from './selectors'
import {
  CLOUDINARY_UPLOAD,
  CLOUDINARY_CURRENT_ACTION_INFOS,
  CLOUDINARY_UPLOAD_START,
  CLOUDINARY_CLEAN_STORE,
  CLOUDINARY_CHANGE_IS_UPLOADING,
} from './actions'

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOUDINARY_UPLOAD_START:
      return {
        ...state,
        fileToUpload: {
          file: action.file,
          uploadConfig: action.uploadConfig,
        },
      }
    case CLOUDINARY_UPLOAD.SUCCESS:
      return {
        ...state,
        [action.actionParams.uploadConfig]: {
          ...state[action.actionParams.uploadConfig],
          [action.actionParams.fileName]: {
            state: 'success',
            url: action.payload.secure_url,
            others: action.actionParams,
          },
        },
      }
    case CLOUDINARY_CURRENT_ACTION_INFOS:
      return {
        ...state,
        currentActionInfos: action.payload,
      }
    case CLOUDINARY_CLEAN_STORE:
      return {
        ...state,
        [action.uploadConfig]: {},
      }
    case CLOUDINARY_CHANGE_IS_UPLOADING:
      return {
        ...state,
        isUploading: action.payload,
      }
    default: {
      return state
    }
  }
}
