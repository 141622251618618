import { put, select } from 'redux-saga/effects'
import { doSetAuthTokens, doSetRefreshTokensAt } from 'store/auth/actions'
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  REFRESH_TOKEN,
  REFRESH_TOKENS_AT,
} from 'constants/auth'
import { isMocks } from 'services/isMocks'

export const removeTokens = () => {
  localStorage.removeItem(ID_TOKEN)
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(REFRESH_TOKEN)
  localStorage.removeItem(REFRESH_TOKENS_AT)
}

export const saveTokens = function* ({ accessToken, refreshToken, idToken }) {
  yield put(
    doSetAuthTokens({
      payload: {
        idToken,
        accessToken,
        refreshToken,
      },
    }),
  )
}

export const bootstrapAuthFromLs = function* () {
  const idToken = localStorage.getItem(ID_TOKEN)
  const accessToken = localStorage.getItem(ACCESS_TOKEN)
  const refreshToken = localStorage.getItem(REFRESH_TOKEN)

  yield put(
    doSetAuthTokens({
      payload: {
        idToken,
        accessToken,
        refreshToken,
      },
    }),
  )

  const isLoggedIn = !!idToken && !!accessToken && (isMocks || !!refreshToken)

  if (isLoggedIn) {
    const lSRefreshTokensAt = parseInt(
      localStorage.getItem(REFRESH_TOKENS_AT),
      10,
    )
    yield put(
      doSetRefreshTokensAt({
        payload: isNaN(lSRefreshTokensAt) ? null : lSRefreshTokensAt,
      }),
    )
  }

  return isLoggedIn
}
