import { put, select, all, delay } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'
import { takeLatest } from 'utils/effects'
import { fromContext, fromFirm, fromRouting, fromPro } from 'store/selectors'
import cookie from 'react-cookie'
import {
  CLIENT_INITIATED,
  closeAll,
  showCookiesBanner,
  setPathNameContext,
  CONTEXT_CLOSE_ALL,
  CONTEXT_TOGGLE_ACCOUNT_NAVIGATION,
  CONTEXT_TOGGLE_MAIN_NAVIGATION,
  CONTEXT_TOGGLE_DOCUMENT_DETAILS_POPIN,
  CONTEXT_TOGGLE_SEARCH_MODAL,
  CONTEXT_TOGGLE_SIGN_IN_POPIN,
  CONTEXT_TOGGLE_FIRM_PICTURES_EDIT_POPIN,
  CONTEXT_TOGGLE_POPIN_DISPLAY,
  setInitialQueryParams,
  setPartner,
  CONTEXT_SET_INITIAL_QUERY_PARAMS,
  firmDetails,
  CONTEXT_SET_COOKIES_CONSENT,
  setCookiesConsent,
} from 'store/actions'
import parseLocationSearch from 'utils/parseLocationSearch'
import preventDocumentScrolling from 'utils/preventDocumentScrolling'

import { URLS } from 'constants/urls'

let firstLocationChange = true

export function* handleLocationChange(action) {
  yield put(setPathNameContext('default'))
  if (firstLocationChange) {
    firstLocationChange = false
  } else {
    const cookiesBannerIsOpen = yield select(fromContext.cookiesBannerIsOpen)
    if (cookiesBannerIsOpen && !action.payload.state.redirectPathname) {
      yield put(showCookiesBanner(false))
    }
    yield put(closeAll())
  }
}

export function* handlePopinChange() {
  if (typeof document === 'undefined') {
    return
  }

  const isPopin = yield select(fromContext.isPopin)
  preventDocumentScrolling(isPopin)
}

function* handleClientInitiated() {
  yield put(setPathNameContext('default'))

  // Initialize query params
  const queryParams = parseLocationSearch(window.location.search)
  yield put(setInitialQueryParams({ payload: queryParams }))
  yield put(setPartner({ payload: queryParams.partner ?? 'quotatis' }))
}

// request again firmDetails if contract signature is false after yousign redirection
function* handleInitialContext() {
  yield delay(5000)
  const pathName = yield select(fromRouting.getPathname)

  if (![URLS.ROUTES.CANDIDATE_PACKAGE_CONFIGURATION].includes(pathName)) {
    return
  }

  const firmId = yield select(fromPro.getFirmId)

  yield put(firmDetails.request({ id: firmId }))
}

function* handleCookiesConsent() {
  const cookies = cookie.load('axeptio_cookies')
  yield put(setCookiesConsent({ cookies }))
}

export default function* () {
  yield all([
    takeLatest(CLIENT_INITIATED, handleClientInitiated),
    takeLatest(CONTEXT_CLOSE_ALL, handlePopinChange),
    takeLatest(CONTEXT_TOGGLE_ACCOUNT_NAVIGATION, handlePopinChange),
    takeLatest(CONTEXT_TOGGLE_MAIN_NAVIGATION, handlePopinChange),
    takeLatest(CONTEXT_TOGGLE_DOCUMENT_DETAILS_POPIN, handlePopinChange),
    takeLatest(CONTEXT_TOGGLE_SEARCH_MODAL, handlePopinChange),
    takeLatest(CONTEXT_TOGGLE_SIGN_IN_POPIN, handlePopinChange),
    takeLatest(CONTEXT_TOGGLE_FIRM_PICTURES_EDIT_POPIN, handlePopinChange),
    takeLatest(CONTEXT_TOGGLE_POPIN_DISPLAY, handlePopinChange),
    takeLatest(LOCATION_CHANGE, handleLocationChange),
    takeLatest(CONTEXT_SET_COOKIES_CONSENT, handleCookiesConsent),
    takeLatest(CONTEXT_SET_INITIAL_QUERY_PARAMS, handleInitialContext),
  ])
}
