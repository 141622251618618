import {
  createRequestTypes,
  generateSimpleAction,
  generateRequestActions,
} from 'utils/actionHelpers'

export const FIRM_LIST = createRequestTypes('FIRM_LIST')
export const SET_FIRM_QUERY_MATCHING_TRADE = 'SET_FIRM_QUERY_MATCHING_TRADE'
export const DIRECTORY_PLACE_AUTO_COMPLETE_LOCATION =
  'DIRECTORY_PLACE_AUTO_COMPLETE_LOCATION'
export const DIRECTORY_PLACE_CLEAR_AUTO_COMPLETE_LOCATION =
  'DIRECTORY_CLEAR_PLACE_AUTO_COMPLETE_LOCATION'
export const TRIGGER_NEW_DIRECTORY_SEARCH = 'DIRECTORY_SEARCH'
export const SET_CURRENT_SEARCH_PLACE = 'SET_CURRENT_SEARCH_PLACE'
export const CLEAR_CURRENT_SEARCH_PLACE = 'CLEAR_CURRENT_SEARCH_PLACE'

export const firmList = generateRequestActions(FIRM_LIST)
export const setDirectoryPlaceAutoCompleteLocation = generateSimpleAction(
  DIRECTORY_PLACE_AUTO_COMPLETE_LOCATION,
)
export const clearDirectoryPlaceAutoCompleteLocation = generateSimpleAction(
  DIRECTORY_PLACE_CLEAR_AUTO_COMPLETE_LOCATION,
)
export const setFirmQueryMatchingTrade = generateSimpleAction(
  SET_FIRM_QUERY_MATCHING_TRADE,
)
export const triggerNewDirectorySearch = generateSimpleAction(
  TRIGGER_NEW_DIRECTORY_SEARCH,
)
export const setCurrentSearchPlace = generateSimpleAction(
  SET_CURRENT_SEARCH_PLACE,
)
export const clearCurrentSearchPlace = generateSimpleAction(
  CLEAR_CURRENT_SEARCH_PLACE,
)
