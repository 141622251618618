import { FIRM_DETAILS } from 'store/firm/actions'
import { REMOVE_TMP_FILE } from './actions'

const documentPlugin = {
  DocumentForm: (state, action) => {
    const formValues =
      state && state.values ? state.values[action.documentType] : []
    const index = (formValues || []).findIndex(
      formValue => formValue.id === action.idFile,
    )

    switch (action.type) {
      case REMOVE_TMP_FILE:
        if (index > -1) {
          formValues.splice(index, 1)
        }
        return {
          ...state,
          values: {
            ...state.values,
            [action.documentType]: formValues,
          },
        }
      case FIRM_DETAILS.SUCCESS:
        return {
          ...state,
          values: {},
        }

      default: {
        return state
      }
    }
  },
}

export default documentPlugin
