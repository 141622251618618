const configSizeImg = {
  logoUrl: {
    minHeight: 100,
    minWidth: 100,
    errorMessage: 'firm.form.pictures.popin.bad_sizeLogo_img',
  },
  default: {
    minHeight: 300,
    minWidth: 300,
    errorMessage: 'firm.form.pictures.popin.bad_size_img',
  },
}

export const getConfigSizeImg = fieldname => {
  if (Object.prototype.hasOwnProperty.call(configSizeImg, fieldname)) {
    return configSizeImg[fieldname]
  }

  return configSizeImg.default
}
