import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import {
  firmsByTradePlombierPage1,
  firmsByTradePlombierPage2,
  firmsByTradeAntenniste,
  firmsByTradeArchitecte,
  noFirms,
} from './data/firm'

const mocks = [
  {
    request: {
      tagFilters: ['activeInDirectory'],
      hitsPerPage: 30,
      filters: '"Plombier - Installateur sanitaire" AND FR',
      page: 0,
    },
    response: firmsByTradePlombierPage1,
  },
  {
    request: {
      tagFilters: ['activeInDirectory'],
      hitsPerPage: 30,
      filters: '"Plombier - Installateur sanitaire" AND FR',
      page: 1,
    },
    response: firmsByTradePlombierPage2,
  },
  {
    request: {
      tagFilters: ['activeInDirectory'],
      hitsPerPage: 30,
      filters: '"Antenniste" AND FR',
      page: 0,
    },
    response: firmsByTradeAntenniste,
  },
  {
    request: {
      tagFilters: ['activeInDirectory'],
      hitsPerPage: 30,
      filters: '"Électricien" AND FR',
      page: 0,
    },
    response: noFirms,
  },
  {
    request: {
      tagFilters: ['activeInDirectory'],
      aroundLatLng: '48.84764699999999, 2.208115',
      aroundRadius: 50000,
      aroundPrecision: 15000,
      getRankingInfo: true,
      hitsPerPage: 30,
      filters: '"Antenniste" AND FR',
      page: 0,
    },
    response: firmsByTradeAntenniste,
  },
  {
    request: {
      tagFilters: ['activeInDirectory'],
      hitsPerPage: 30,
      filters: '"Architecte" AND FR',
      page: 0,
    },
    response: firmsByTradeArchitecte,
  },
]

const match = async params => {
  const mockMatching = mocks.find(({ request }) => isEqual(params, request))
  if (!mockMatching) {
    console.error(`Mock missing : ${JSON.stringify(params)}`)
    return []
  }

  return get(mockMatching, 'response') || null
}

const mockedIndex = {
  search: params => match(params),
}

export default mockedIndex
