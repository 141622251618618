export * from './auth/actions'
export * from './context/actions'
export * from './firm/actions'
export * from './routing/actions'
export * from './searchEngine/actions'
export * from './trades/actions'
export * from './user/actions'
export * from './directory/actions'
export * from './pro/actions'
export * from './google/actions'
export * from './hoauth/actions'
export * from './cloudinary/actions'
export * from './productSubscription/actions'
export * from './job/actions'
export * from './documents/actions'
export * from './onboarding/actions'
export * from './status/actions'
export * from './survey/actions'
export * from './pro-documents/actions'
export * from './notifications/actions'
export * from './departments/actions'
export * from './contact/actions'

export const CLIENT_INITIATED = 'CLIENT_INITIATED'

export const clientInitiated = () => ({
  type: CLIENT_INITIATED,
})
